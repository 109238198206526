const getFileSizeFromUrl = async (url: string): Promise<string | null> => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open("HEAD", url, true);

    xhr.onreadystatechange = function () {
      if (this.readyState === this.DONE) {
        resolve(xhr.getResponseHeader("Content-Length"));
      }
    };
    xhr.send();
  });
};

const getFileNameFromUrl = (url: string): string | undefined => {
  return url.split("/").pop();
};

export { getFileNameFromUrl, getFileSizeFromUrl };
