import { getExportFileName } from "@intentsify/utils";
import { usePalette } from "components";
import {
  cloneElement,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { createPortal } from "react-dom";
// @ts-ignore
import { saveSvgAsPng } from "save-svg-as-png";
import { useTrackChartDownloaded } from "tracking/useTrackChartDownloaded";

type UseExportableChartParams = {
  chart: ReactNode;
  title: string;
  campaignId: number;
  dates?: {
    start: string;
    end: string;
  };
  dimensions?: {
    width: number;
    height: number;
  };
};

type ExpandableChart = {
  downloadAsPng: () => void;
  title: string;
  exportComponent: JSX.Element;
  component: JSX.Element;
};

export const EXPORT_RENDER_DELAY = 1000;

/**
 * visx charts are rendered as SVGs, so saving them as PNG is straightforward.
 * The trick is to render them at the desired size, not the size that user sees them on their screen.
 * To achieve that, when export is requested, the chart is rendered off screen using a Portal. It's still
 * rendered so it can be exported, even though it's not visible to the user.
 *
 * EXPORT_RENDER_DELAY delay is used to wait a little bit, just to make sure it fully rendered.
 *
 * Additional props are passed to the chart that is being exported to let it know:
 * - title needs to be rendered as svg text
 * - legend needs to be rendered as svg text (visx legend is built with html)
 * - ID is passed so the svg can be queried from the DOM
 */
export const useExportableChart = ({
  chart,
  campaignId,
  title,
  dates,
  dimensions = { width: 1000, height: 600 },
}: UseExportableChartParams): ExpandableChart => {
  const chartExportPortalContainer = document.getElementById(
    "chart-export-portal"
  );
  const palette = usePalette(true, "white");

  const [downloadTrigger, setDownloadTrigger] = useState<undefined | true>(
    undefined
  );

  const id = Math.random().toString();
  const trackChartDownloaded = useTrackChartDownloaded();

  const fileName = getExportFileName({
    prefix: `${title.toLocaleLowerCase().replace(/ /g, "_")}`,
    data: {
      id: campaignId,
    },
    dates,
    extension: ".png",
  });

  useEffect(() => {
    if (downloadTrigger) {
      if (chartExportPortalContainer) {
        chartExportPortalContainer.style.height = `${dimensions.height}px`;
        chartExportPortalContainer.style.width = `${dimensions.width}px`;
      }

      setTimeout(() => {
        const backgroundColor = palette.bg;

        saveSvgAsPng(document.getElementById(id), fileName, {
          encoderOptions: 1,
          backgroundColor,
        }).then(() => {
          trackChartDownloaded({ title, campaignId });
        });

        setDownloadTrigger(undefined);
      }, EXPORT_RENDER_DELAY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadTrigger]);

  return {
    downloadAsPng: () => {
      setDownloadTrigger(true);
    },
    title,
    exportComponent: (
      <>
        {chartExportPortalContainer && downloadTrigger && (
          <>
            {createPortal(
              cloneElement(chart as ReactElement<any>, {
                id,
                exportMode: true,
                title,
              }),
              chartExportPortalContainer
            )}
          </>
        )}
      </>
    ),
    component: <>{chart}</>,
  };
};
