import { HasAccess } from "@intentsify/authorization/dist/react";
import * as Sentry from "@sentry/react";
import { AgreementScreenDefinition, LoginScreenDefinition } from "auth";
import { Layout, RequireAuth } from "components";
import { LayoutSkeleton } from "components/Layout/LayoutSkeleton";
import { PublicLayout } from "components/Layout/PublicLayout";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router";
import { Navigate } from "react-router-dom";
import {
  AdminRouter,
  CampaignsRouter,
  IntentAnalysisRouter,
  MediaActivationRouter,
} from "routes";
import {
  AdminRootDefinition,
  CampaignsRootDefinition,
  DownloadReport,
  IntentAnalysisRootDefinition,
  MediaActivationRootDefinition,
  NotFound,
  YourCampaignsScreenDefinition,
} from "screens";
import { ContentLibrary } from "screens/ContentLibrary/ContentLibrary";
import { DownloadContentLibraryAsset } from "screens/DownloadContentLibraryAsset";
import { DownloadContentLibraryAssetDefinition } from "screens/DownloadContentLibraryAsset/DownloadContentLibraryAsset.definition";
import { ImpersonationTransitionDefinition } from "screens/ImpersonationTransition/ImpersonationTransition.definition";
import { OptOut } from "screens/OptOut/OptOut";
import { OptOutScreenDefinition } from "screens/OptOut/OptOut.definition";
import { OptOutDelete } from "screens/OptOut/OptOutDelete";
import { OptOutDeleteDefinition } from "screens/OptOut/OptOutDelete.definition";
import { OptOutDisplay } from "screens/OptOut/OptOutDisplay";
import { OptOutDisplayScreenDefinition } from "screens/OptOut/OptOutDisplay.definition";
import { OptOutEdit } from "screens/OptOut/OptOutEdit";
import { OptOutEditScreenDefinition } from "screens/OptOut/OptOutEditdefinition";
import { OptOutOfProcessing } from "screens/OptOut/OptOutOfProcessing";
import { OptOutOfProcessingScreenDefinition } from "screens/OptOut/OptOutOfProcessing.definition";
import { OptOutOfSaleOrSharing } from "screens/OptOut/OptOutOfSaleOrSharing";
import { OptOutOfSaleOrSharingScreenDefinition } from "screens/OptOut/OptOutOfSaleOrSharing.definition";
import { SettingsRootDefinition } from "screens/Settings/Settings.definition";
import { TargetPersonas } from "screens/TargetPersonas/TargetPersonas";
import { TargetPersonasDefinition } from "screens/TargetPersonas/TargetPersonas.definition";
import { useUser } from "store/store.hooks";
import { DownloadReportDefinition } from "../screens/DownloadReport/DownloadReport.definition";
import { DownloadTalTools } from "../screens/DownloadTalTools";
import { DownloadTalToolsDefinition } from "../screens/DownloadTalTools/DownloadTalTools.definition";
import {
  TalPreparationTools,
  TalPreparationToolsDefinition,
} from "../screens/Tools";
import { SettingsRouter } from "./routes/SettingsRouter";

const RootRouter = () => {
  const user = useUser();
  const location = useLocation();

  useEffect(() => {
    Sentry.setUser({
      email: user?.email,
      username: user?.displayName,
      id: String(user?.userId),
    });
  }, [user]);

  if (
    (!user || typeof user.isActive === "undefined") &&
    !location.pathname.includes("/opt-out")
  ) {
    return <Navigate to={LoginScreenDefinition.navigate()} />;
  }

  if (!user?.isActive && !location.pathname.includes("/opt-out")) {
    return <Navigate to={AgreementScreenDefinition.navigate()} />;
  }

  return (
    <>
      <Routes>
        <Route
          path={ImpersonationTransitionDefinition.routePath[0]}
          element={<LayoutSkeleton />}
        />

        <Route
          path={OptOutScreenDefinition.routePath[0]}
          element={
            <PublicLayout>
              <OptOut />
            </PublicLayout>
          }
        />

        <Route
          path={OptOutDisplayScreenDefinition.routePath[0]}
          element={
            <PublicLayout>
              <OptOutDisplay />
            </PublicLayout>
          }
        />

        <Route
          path={OptOutEditScreenDefinition.routePath[0]}
          element={
            <PublicLayout>
              <OptOutEdit />
            </PublicLayout>
          }
        />

        <Route
          path={OptOutOfSaleOrSharingScreenDefinition.routePath[0]}
          element={
            <PublicLayout>
              <OptOutOfSaleOrSharing />
            </PublicLayout>
          }
        />

        <Route
          path={OptOutOfProcessingScreenDefinition.routePath[0]}
          element={
            <PublicLayout>
              <OptOutOfProcessing />
            </PublicLayout>
          }
        />

        <Route
          path={OptOutDeleteDefinition.routePath[0]}
          element={
            <PublicLayout>
              <OptOutDelete />
            </PublicLayout>
          }
        />

        <Route
          element={
            <HasAccess to="contentLibrary">
              <Layout omitTopBar>
                <RequireAuth />
              </Layout>
            </HasAccess>
          }
        >
          <Route
            path="/content-library"
            element={
              <Navigate to="/content-library/completed" replace={true} />
            }
          />
          <Route path={"/content-library/*"} element={<ContentLibrary />} />
        </Route>

        <Route
          element={
            <Layout>
              <RequireAuth />
            </Layout>
          }
        >
          {IntentAnalysisRootDefinition.routePath.map((path) => (
            <Route key={path} path={path} element={<IntentAnalysisRouter />} />
          ))}
          {CampaignsRootDefinition.routePath.map((path) => (
            <Route key={path} path={path} element={<CampaignsRouter />} />
          ))}

          {AdminRootDefinition.routePath.map((path) => (
            <Route key={path} path={path} element={<AdminRouter />} />
          ))}
          {MediaActivationRootDefinition.routePath.map((path) => (
            <Route key={path} path={path} element={<MediaActivationRouter />} />
          ))}
          {SettingsRootDefinition.routePath.map((path) => (
            <Route key={path} path={path} element={<SettingsRouter />} />
          ))}
          {DownloadReportDefinition.routePath.map((path) => (
            <Route key={path} path={path} element={<DownloadReport />} />
          ))}
          <Route
            path={TargetPersonasDefinition.routePath[0]}
            element={<TargetPersonas />}
          />
          <Route
            path={TalPreparationToolsDefinition.routePath[0]}
            element={<TalPreparationTools />}
          />
          <Route
            path={DownloadTalToolsDefinition.routePath[0]}
            element={<DownloadTalTools />}
          />
          <Route
            path={DownloadContentLibraryAssetDefinition.routePath[0]}
            element={<DownloadContentLibraryAsset />}
          />
        </Route>

        <Route path="/404" element={<NotFound />} />
        <Route
          path="/"
          element={<Navigate to={YourCampaignsScreenDefinition.navigate()} />}
        />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </>
  );
};

export { RootRouter };
