import { Box, Flex, useToast } from "@chakra-ui/react";
import { CampaignDefinition, FileUploadStatus } from "@intentsify/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Loader, SimpleTable, Upload } from "components";
import { partition } from "lodash";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import { handleApiError } from "utils";
import { isMutatingAutodiscoveryFilesAtom } from "../../../../../KeywordsAndTopicsStep.state";
import {
  deleteAutodiscoveryUrl,
  uploadAutodiscoveryFiles,
} from "../../Autodiscovery.requests";
import { useColumns } from "./AutodiscoveryFiles.hooks";
import { AutodiscoveryFile } from "./AutodiscoveryFiles.types";
import { IncompleteUploadWarning } from "./components";

type Props = {
  campaignId: string;
  files: AutodiscoveryFile[];
};

const AutodiscoveryFiles = (props: Props) => {
  const { campaignId, files } = props;
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [isMutatingAutodiscoveryFiles, setIsMutatingAutodiscoveryFiles] =
    useRecoilState(isMutatingAutodiscoveryFilesAtom);

  const { mutate: uploadFiles } = useMutation(
    uploadAutodiscoveryFiles(props.campaignId),
    {
      onSuccess: (data) => {
        const previousCampaignState =
          queryClient.getQueryData<CampaignDefinition>([
            "campaign",
            Number(campaignId),
          ]);

        const [successfullyUploadedFiles, failedToUploadFiles] = partition(
          data,
          (file) => file.status === FileUploadStatus.OK
        );

        if (previousCampaignState) {
          queryClient.setQueryData(["campaign", Number(campaignId)], {
            ...previousCampaignState,
            autodiscoveryFiles: [
              ...previousCampaignState.autodiscoveryFiles,
              ...successfullyUploadedFiles.map((file) => ({
                id: file.id,
                url: file.url,
                status: null,
              })),
            ],
          });
        }

        if (failedToUploadFiles.length === 0) {
          toast({
            title: `${
              props.files.length > 1 ? "Files" : "File"
            } uploaded successfully.`,
            status: "success",
          });
        }

        if (failedToUploadFiles.length) {
          toast({
            status: "warning",
            description: (
              <IncompleteUploadWarning
                successfullyUploadedFiles={successfullyUploadedFiles}
                failedToUploadFiles={failedToUploadFiles}
              />
            ),
            duration: 15000,
            isClosable: true,
          });
        }
        setIsMutatingAutodiscoveryFiles(false);
      },
      onError: (err) => {
        setIsMutatingAutodiscoveryFiles(false);
        handleApiError(err, navigate, toast);
      },
    }
  );

  const { mutate: handleDelete } = useMutation(
    deleteAutodiscoveryUrl(campaignId),
    {
      onSuccess: (_, variables) => {
        const previousCampaignState =
          queryClient.getQueryData<CampaignDefinition>([
            "campaign",
            Number(campaignId),
          ]);

        if (previousCampaignState) {
          queryClient.setQueryData(["campaign", Number(campaignId)], {
            ...previousCampaignState,
            autodiscoveryFiles: previousCampaignState.autodiscoveryFiles.filter(
              ({ id }) => id !== Number(variables)
            ),
          });
        }
        toast({
          title: "File deleted succesfully.",
          status: "success",
        });
        setIsMutatingAutodiscoveryFiles(false);
      },
      onError: (err) => {
        handleApiError(err, navigate, toast);
      },
    }
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 5,
    disabled: isMutatingAutodiscoveryFiles,
    onDropAccepted: (files: File[]) => {
      setIsMutatingAutodiscoveryFiles(true);
      uploadFiles(files);
    },
  });

  const columns = useColumns(props.campaignId, (id) => {
    setIsMutatingAutodiscoveryFiles(true);
    handleDelete(id);
  });

  return (
    <Flex p={4} gap={10}>
      <Box flex={1}>
        <Upload
          getInputProps={getInputProps}
          getRootProps={getRootProps}
          subHeader={"PDF up to 10MB"}
        />
      </Box>
      <Box flex={1}>
        {isMutatingAutodiscoveryFiles ? (
          <Loader minHeight={"140px"} label="" />
        ) : (
          <SimpleTable data={files} columns={columns} />
        )}
      </Box>
    </Flex>
  );
};

export { AutodiscoveryFiles };
