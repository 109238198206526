import { Flex, MenuButton, useDisclosure } from "@chakra-ui/react";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import { CampaignForList } from "@intentsify/types";
import { getExportFileName } from "@intentsify/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import { AxiosResponse } from "axios";
import { Button, Menu } from "components";
import { FiChevronDown, FiDownload, FiEye } from "react-icons/fi";
import { useTrackCampaignFileDownloaded } from "tracking/useTrackCampaignFileDownloaded";
import { useDownloadFile, valueToQueryURL } from "utils";
import { BulkEdit } from "./BulkEdit";

type BulkActionsProps = {
  selectedRows: CampaignForList[];
};

const BulkActions = ({ selectedRows }: BulkActionsProps) => {
  const queryClient = useQueryClient();
  const { download } = useDownloadFile();
  const {
    isOpen: isBulkEditOpen,
    onClose: onBulkEditClose,
    onOpen: onBulkEditOpen,
  } = useDisclosure();

  const hasAccessToDownload = useHasAccessTo("campaign.download");
  const hasAccessToDownloadAccountsMapping = useHasAccessTo(
    "campaign.downloadAccountMapping"
  );
  const hasAccessToUpdateVisibility = useHasAccessTo("campaign.edit");

  const {
    mutate: updateCampaignVisibilityMutation,
    isLoading: isChangingVisibilityInProgress,
  } = useMutation<
    AxiosResponse<CampaignForList>,
    void,
    Array<{ campaignId: number; isHidden: boolean }>
  >((params) => apiService.put(`/campaigns/update-visibility`, params), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["campaignsList"] });
    },
  });

  const handleSetToHidden = () => {
    updateCampaignVisibilityMutation(
      selectedRows.map((r) => ({ campaignId: r.campaignId, isHidden: true }))
    );
  };

  const handleSetToVisible = () => {
    updateCampaignVisibilityMutation(
      selectedRows.map((r) => ({ campaignId: r.campaignId, isHidden: false }))
    );
  };

  const trackCampaignFileDownloaded = useTrackCampaignFileDownloaded();

  const handleDownload = async (campaignIds: number[]) => {
    const fileName =
      campaignIds.length === 1
        ? getExportFileName({
            prefix: `intent_model`,
            extension: false,
            data: {
              id: campaignIds[0],
            },
          })
        : "intent_models";

    const params = {
      url: Endpoints.Campaigns.Get.DownloadMultipleCampaignData(),
      fileName,
      fileExtension: "zip",
      params: {
        campaignId: valueToQueryURL(campaignIds),
      },
    };

    await download(params);
    trackCampaignFileDownloaded({ type: "campaign", campaignIds });
  };

  const handleMetadataDownload = async (campaignIds: number[]) => {
    const fileName =
      campaignIds.length === 1
        ? getExportFileName({
            prefix: `intent_model_metadata`,
            extension: false,

            data: {
              id: campaignIds[0],
            },
          })
        : "intent_models_metadata";

    const params = {
      fileName,
      fileExtension: "zip",
      url: Endpoints.Campaigns.Get.DownloadMultipleCampaignMetadata(),
      params: {
        campaignId: valueToQueryURL(campaignIds),
      },
    };

    await download(params);
    trackCampaignFileDownloaded({ type: "campaign metadata", campaignIds });
  };

  const handleAccountMappingDownload = async (campaignIds: number[]) => {
    const fileName =
      campaignIds.length === 1
        ? getExportFileName({
            prefix: `intent_model_accounts_mapping`,
            extension: false,
            data: {
              id: campaignIds[0],
            },
          })
        : "intent_models_accounts_mapping";

    const params = {
      url: Endpoints.Campaigns.Get.DownloadMultipleCampaignAccountMappings(),
      fileName,
      fileExtension: "zip",
      params: {
        campaignId: valueToQueryURL(campaignIds),
      },
    };

    await download(params);
    trackCampaignFileDownloaded({
      type: "campaign account mapping",
      campaignIds,
    });
  };

  return (
    <>
      <Flex>
        {selectedRows.length > 0 && (
          <Flex mr={2} width="full" gap={2}>
            <Menu
              variant="corporate"
              menuButton={
                <MenuButton
                  as={Button}
                  size="sm"
                  fontWeight="md"
                  fontSize="sm"
                  variant="outline-teal"
                  leftIcon={<FiDownload />}
                  rightIcon={<FiChevronDown />}
                  isDisabled={selectedRows.length === 0}
                >
                  Download
                </MenuButton>
              }
              menuItems={[
                {
                  label: "Intent Model",
                  isDisabled: !hasAccessToDownload,
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick: () =>
                    handleDownload(selectedRows.map((row) => row.campaignId)),
                },
                {
                  label: "Intent Model's Metadata",
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick: () =>
                    handleMetadataDownload(
                      selectedRows.map((row) => row.campaignId)
                    ),
                },
                {
                  label: "Intent Model Account Mapping",
                  isDisabled: !hasAccessToDownloadAccountsMapping,
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick: () =>
                    handleAccountMappingDownload(
                      selectedRows.map((row) => row.campaignId)
                    ),
                },
              ]}
            />
            <Menu
              variant="corporate"
              menuButton={
                <MenuButton
                  as={Button}
                  size="sm"
                  fontWeight="md"
                  fontSize="sm"
                  variant="outline-teal"
                  leftIcon={<FiEye />}
                  rightIcon={<FiChevronDown />}
                  isDisabled={
                    selectedRows.length === 0 || !hasAccessToUpdateVisibility
                  }
                  isLoading={isChangingVisibilityInProgress}
                >
                  Visibility
                </MenuButton>
              }
              menuItems={[
                {
                  label: "Change visibility to hidden",
                  onClick: handleSetToHidden,
                },
                {
                  label: "Change visibility to visible",
                  onClick: handleSetToVisible,
                },
              ]}
            />

            <Button onClick={onBulkEditOpen} variant="outline-teal">
              Bulk Edit
            </Button>
          </Flex>
        )}
      </Flex>

      <BulkEdit
        isOpen={isBulkEditOpen}
        onClose={onBulkEditClose}
        selectedCampaigns={selectedRows}
      />
    </>
  );
};

export { BulkActions };
