import { Text } from "@chakra-ui/layout";
import {
  SerializableTalDomainValidationResults,
  TalValidationErrorMessage,
} from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";

export const fixedColumns = [
  {
    header: "Row #",
    size: 75,
    cell: ({
      row: {
        original: [rowIndex],
      },
    }) => <Text fontWeight="semibold">{rowIndex + 1}</Text>,
  },
  {
    header: "Cell Content",
    cell: ({
      row: {
        original: [, value],
      },
    }) => (
      <Text color="red" fontWeight="semibold">
        {value}
      </Text>
    ),
  },
  {
    header: "Fixed Cell Content",
    cell: ({
      row: {
        original: [, , fixedValue],
      },
    }) => (
      <Text color="green" fontWeight="semibold">
        {fixedValue}
      </Text>
    ),
  },
] satisfies ColumnDef<
  SerializableTalDomainValidationResults["fixed"][number]
>[];

export const duplicatedColumns = [
  {
    header: "Row #",
    size: 75,
    cell: ({
      row: {
        original: [rowIndex],
      },
    }) => <Text>{rowIndex + 1}</Text>,
  },
  {
    header: "Cell Content",
    cell: ({
      row: {
        original: [, cellValue],
      },
    }) => <Text>{cellValue}</Text>,
  },
] satisfies ColumnDef<
  SerializableTalDomainValidationResults["duplicated"][number]
>[];

export const invalidRowsColumns = [
  {
    header: "Row #",
    size: 75,
    cell: ({
      row: {
        original: [, rowIndex],
      },
    }) => (
      <Text color="red" fontWeight="semibold">
        {rowIndex + 1}
      </Text>
    ),
  },
  {
    header: "Column #",
    size: 75,
    cell: ({
      row: {
        original: [, , columnIndex],
      },
    }) => (
      <Text color="red" fontWeight="semibold">
        {columnIndex + 1}
      </Text>
    ),
  },
  {
    header: "Reason",
    size: 250,
    cell: ({
      row: {
        original: [errorCode],
      },
    }) => (
      <Text color="red" fontWeight="semibold">
        {TalValidationErrorMessage[errorCode]}
      </Text>
    ),
  },
  {
    header: "Column Content",
    cell: ({
      row: {
        original: [, , , columnValue],
      },
    }) => (
      <Text color="red" fontWeight="semibold">
        {columnValue}
      </Text>
    ),
  },
] satisfies ColumnDef<
  SerializableTalDomainValidationResults["invalidRows"][number]
>[];
