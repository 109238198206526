import { Flex } from "@chakra-ui/react";
import {
  decimalToPercentage,
  toCurrency,
  toNumberDisplayValue,
} from "@intentsify/utils";
import { FaHashtag } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";
import { DisplayPerformanceFilters } from "../../DisplayPerformance";
import { NOT_A_CPA_CAMPAIGN_MESSAGE } from "../../DisplayPerformance.utils";
import {
  ClickThrough,
  ImpressionsAndClicksOverTime,
  MediaDeliveryMap,
  OverallViewability,
  SiteEngagementLift,
  TopCreativePerformance,
  MobileDeliveryBreakdown,
  PerformanceByDevice,
} from "./components";
import { getStatistics } from "./CampaignEngagement.requests";
import { ErrorBoundary, StatCard } from "components";

type CampaignEngagementProps = {
  campaignName: string;
  id: number;
  filters: DisplayPerformanceFilters;
};

const CampaignEngagement = ({
  id,
  filters,
  campaignName,
}: CampaignEngagementProps) => {
  const { data, isFetching: isLoading } = useQuery(
    ["impressionsClicksPerformance-statistics", id, filters],
    () => getStatistics(id, filters)
  );

  return (
    <>
      <Flex w="100%" gap={4} mb={4} minH="200px">
        <ErrorBoundary>
          <StatCard
            isLoading={isLoading}
            title="Overall Spend"
            colorScheme="yellow"
            value={data?.overallSpend}
            valueFormatter={(value) => toCurrency(value)}
          />
        </ErrorBoundary>

        <ErrorBoundary>
          <StatCard
            isLoading={isLoading}
            title="Total Impressions"
            value={data?.totalImpressions}
            previousPeriodChange={data?.totalImpressionsPreviousPeriod}
            valueFormatter={(value) => toNumberDisplayValue(value)}
          />
        </ErrorBoundary>

        <ErrorBoundary>
          <StatCard
            isLoading={isLoading}
            title="Total Clicks"
            value={data?.totalClicks}
            previousPeriodChange={data?.totalClicksPreviousPeriod}
            valueFormatter={(value) => toNumberDisplayValue(value)}
          />
        </ErrorBoundary>
      </Flex>

      <Flex w="100%" gap={4} mb={4} minH="200px">
        <ErrorBoundary>
          <StatCard
            isLoading={isLoading}
            title="Overall CTR"
            value={data?.overallCTR}
            previousPeriodChange={data?.overallCTRPreviousPeriod}
            valueFormatter={(value) =>
              `${toNumberDisplayValue(decimalToPercentage(value))}%`
            }
          />
        </ErrorBoundary>

        <ErrorBoundary>
          <StatCard
            isLoading={isLoading}
            title="Total Conversions"
            value={data?.totalConversions}
            previousPeriodChange={data?.totalConversionsPreviousPeriod}
            valueFormatter={(value) => toNumberDisplayValue(value)}
            hasData={data?.totalConversions !== 0}
            noDataIcon={FaHashtag}
            noDataMessage={NOT_A_CPA_CAMPAIGN_MESSAGE}
          />
        </ErrorBoundary>

        <ErrorBoundary>
          <OverallViewability campaignId={id} filters={filters} />
        </ErrorBoundary>
      </Flex>

      <Flex w="100%" gap={4} mb={4} minH="200px">
        <ErrorBoundary>
          <ImpressionsAndClicksOverTime
            campaignId={id}
            isLoading={isLoading}
            data={data?.impressionsAndClicksOverTime ?? []}
          />
        </ErrorBoundary>

        <ErrorBoundary>
          <TopCreativePerformance campaignId={id} filters={filters} />
        </ErrorBoundary>

        <ErrorBoundary>
          <ClickThrough
            totalConversions={data?.totalConversions ?? 0}
            campaignId={id}
            filters={filters}
          />
        </ErrorBoundary>
      </Flex>

      <Flex w="100%" gap={4} mb={4}>
        <ErrorBoundary>
          <PerformanceByDevice campaignId={id} filters={filters} />
        </ErrorBoundary>
        <ErrorBoundary>
          <MobileDeliveryBreakdown campaignId={id} filters={filters} />
        </ErrorBoundary>
      </Flex>

      <Flex w="100%" gap={4} mb={4} minH="200px">
        <ErrorBoundary>
          <SiteEngagementLift campaignId={id} campaignName={campaignName} />
        </ErrorBoundary>
      </Flex>

      <MediaDeliveryMap campaignId={id} filters={filters} />
    </>
  );
};

export { CampaignEngagement };
