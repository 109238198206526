import { DateRange, Option, SplitBy } from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { Endpoints, apiService } from "api";
import { valueToQueryURL } from "utils";
import {
  TopTrendingDomainsOptions,
  TopTrendingDomainsWithTimeseriesResponse,
} from "./TopTrendingAccounts.types";

export const getTopTrendingDomainsOptions = async (
  id: string,
  dateRange: DateRange,
  splitBy: SplitBy
): Promise<Omit<TopTrendingDomainsOptions, "isLoading">> => {
  const { data } = await apiService.get(
    Endpoints.Campaigns.Get.Domains.TopTrendingDomains(Number(id)),
    {
      params: {
        dateRange,
        splitBy,
      },
    }
  );
  return data;
};

type TopTrendingDomainsParams = {
  campaignId: string | undefined;
  accounts: Option<number>[] | undefined;
  dateRange: DateRange;
  splitBy: SplitBy;
};

export const getTopTrendingDomains = async ({
  campaignId,
  accounts,
  dateRange,
  splitBy,
}: TopTrendingDomainsParams): Promise<TopTrendingDomainsWithTimeseriesResponse> => {
  if (!campaignId || !isPopulatedArray(accounts)) {
    return { data: [], timeseries: [] };
  }

  const { data } =
    await apiService.get<TopTrendingDomainsWithTimeseriesResponse>(
      Endpoints.Campaigns.Get.Domains.TopPerformingDomainsWithTimeseries(
        Number(campaignId)
      ),
      {
        params: {
          accounts: valueToQueryURL(accounts),
          dateRange,
          splitBy,
        },
      }
    );
  return data;
};
