import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  RadioGroup,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { TalToolsTypes } from "@intentsify/types";
import { Button } from "components";
import { useEffect, useState } from "react";
import { useComponentColors } from "theme";
import { talToolsUpload } from "upload/talToolsUpload";
import { useTalPreparationToolsFiles } from "../../hooks/useTalPreparationToolsFiles";
import { TalToolsFiles } from "../TalToolsFiles/TalToolsFiles";
import { DEFAULT_LOOKALIKE_DOMAINS, MAX_LOOKALIKE_DOMAINS } from "./SmarTAL";
import { useSmarTAL } from "./useSmarTAL";

const SmarTALForm = ({ lastUploadedFile }: { lastUploadedFile: string }) => {
  const componentColors = useComponentColors();
  const tusFiles = talToolsUpload.useFiles();
  const { mutateAsync: requestSmarTAL, isLoading: isSubmitting } = useSmarTAL();
  const [maxLookalikeDomains, setMaxLookalikeDomains] = useState(
    DEFAULT_LOOKALIKE_DOMAINS
  );

  const [selectedFileName, setSelectedFileName] = useState<
    string | undefined
  >();

  const uploadingFiles = tusFiles.filter(
    ({ status }) => status === "uploading"
  );

  const { data: files, isFetching: isLoadingFiles } =
    useTalPreparationToolsFiles(TalToolsTypes.SMARTAL);

  const onSubmit = () => {
    if (!selectedFileName) {
      return;
    }

    requestSmarTAL({
      fileName: selectedFileName,
      maxLookalikeDomains,
    });
  };

  useEffect(() => {
    if (lastUploadedFile) {
      setSelectedFileName(lastUploadedFile);
    }
  }, [lastUploadedFile, setSelectedFileName]);

  return (
    <Box>
      <FormControl mt={4}>
        <FormLabel
          fontSize="sm"
          fontWeight="md"
          color={componentColors.form.formLabelColor}
        >
          Maximum # of lookalike domains to be returned
        </FormLabel>
        <NumberInput
          shadow="sm"
          size="sm"
          rounded="md"
          width="2xs"
          max={MAX_LOOKALIKE_DOMAINS}
          min={1}
          step={50}
          value={maxLookalikeDomains}
          onChange={(num) => setMaxLookalikeDomains(Number(num))}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>

      <Box>
        <Heading as="h3" size="sm" my="4">
          Uploaded Files
        </Heading>
        {Boolean(isLoadingFiles || uploadingFiles.length) && (
          <Spinner
            thickness="4px"
            speed="0.75s"
            size="md"
            {...componentColors.spinner}
          />
        )}
        {!isLoadingFiles && !files?.length && (
          <Text fontSize="sm">Uploaded files will appear here.</Text>
        )}
        {Boolean(files?.length) && (
          <RadioGroup onChange={setSelectedFileName} value={selectedFileName}>
            <TalToolsFiles
              talToolsType={TalToolsTypes.SMARTAL}
              files={files ?? []}
              onFileDelete={(deletedFileName) => {
                if (deletedFileName === selectedFileName) {
                  setSelectedFileName(undefined);
                }
              }}
            />
          </RadioGroup>
        )}
      </Box>

      <Divider mt={4} />

      <Flex mt={4} justifyContent="flex-end">
        <Button
          size="md"
          variant="primary-teal"
          isLoading={isSubmitting}
          isDisabled={!selectedFileName}
          onClick={onSubmit}
        >
          Process SmartTAL
        </Button>
      </Flex>
    </Box>
  );
};

export { SmarTALForm };
