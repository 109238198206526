import { WeeklySnapshotDTO } from "@intentsify/dto";
import { useQuery } from "@tanstack/react-query";
import { ViewContainer } from "components";
import { getTopAccountsBySpend } from "../../WeeklySnapshot.requests";
import { TopAccountsBySpendTable } from "./TopAccountsBySpendTable";

type TopAccountsBySpendProps = {
  campaignId: number;
  filters: WeeklySnapshotDTO;
};

const TopAccountsBySpend = ({
  campaignId,
  filters,
}: TopAccountsBySpendProps) => {
  const { data, isFetching: isLoading } = useQuery(
    ["getTopAccountsBySpend", campaignId],
    () => getTopAccountsBySpend(campaignId, filters)
  );

  return (
    <ViewContainer
      withBackground
      title={"Top 10 Accounts by Spend & Performance"}
    >
      <TopAccountsBySpendTable data={data} isLoading={isLoading} />
    </ViewContainer>
  );
};
export { TopAccountsBySpend };
