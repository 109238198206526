import { DateRange, Option, SplitBy } from "@intentsify/types";
import { atom, selector, useResetRecoilState } from "recoil";
import { TopPerformingDomainsOptions } from "./TopPerformingDomains.types";
import { ViewEnum } from "screens/IntentAnalysis/screens/CampaignsTrendingDomains/CampaignsTrendingDomains.types";

export const topPerformingDomainsDateRangeAtom = atom<DateRange>({
  key: `topPerformingDomainsDateRange`,
  default: DateRange.LastFourWeeks,
});

export const topPerformingDomainsSplitByAtom = atom<SplitBy>({
  key: `topPerformingDomainsSplitByAtom`,
  default: SplitBy.OneWeek,
});

export const topPerformingDomainsViewAtom = atom<ViewEnum>({
  key: `topPerformingDomainsViewAtom`,
  default: ViewEnum.Table,
});

export const topPerformingSelectedDomainsAtom = atom<{
  campaignId: string | undefined;
  data: Array<Option<number>>;
}>({
  key: `topPerformingSelectedDomainsAtom`,
  default: {
    campaignId: undefined,
    data: [],
  },
});

export const performingDomainsOptionsStateAtom =
  atom<TopPerformingDomainsOptions>({
    key: `performingDomainsOptionsStateAtom`,
    default: {
      isLoading: true,
      topPerformingDomains: [],
    },
  });

export const topPerformingDomainsStateSelector = selector({
  key: "topPerformingDomainsStateSelector",
  get: ({ get }) => {
    const { isLoading, topPerformingDomains } = get(
      performingDomainsOptionsStateAtom
    );

    const selectedDateRange = get(topPerformingDomainsDateRangeAtom);
    const selectedSplitBy = get(topPerformingDomainsSplitByAtom);
    const selectedView = get(topPerformingDomainsViewAtom);
    const selectedDomains = get(topPerformingSelectedDomainsAtom);

    return {
      isLoading,
      topPerformingDomainsOptions: topPerformingDomains,
      selectedDateRange,
      selectedSplitBy,
      selectedView,
      selectedDomains,
    };
  },
});

export const useResetTopPerformingState = () => {
  const list = [
    useResetRecoilState(performingDomainsOptionsStateAtom),
    useResetRecoilState(topPerformingDomainsDateRangeAtom),
    useResetRecoilState(topPerformingDomainsSplitByAtom),
    useResetRecoilState(topPerformingDomainsViewAtom),
    useResetRecoilState(topPerformingSelectedDomainsAtom),
  ];

  return () => {
    list.forEach((f) => f());
  };
};
