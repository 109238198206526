import {
  Flex,
  Icon,
  Link,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import {
  AccountPersonaDetails,
  AccountPersonaExpandedDetails,
} from "@intentsify/types/dist/esm/BuyingGroup";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { Endpoints, apiService } from "api";
import { Table } from "components";
import { Suspense, useMemo } from "react";
import { FiCheck, FiMinus } from "react-icons/fi";
import { useCampaign } from "../../CampaignsWizard/CampaignsWizard.hooks";

type AccountsTableExpandedRowProps = {
  accountId: number;
  campaignId: number;
};

export function AccountsTableExpandedRow(props: AccountsTableExpandedRowProps) {
  return (
    <Tabs colorScheme="teal">
      <TabList>
        <Tab>Buying Group Contacts</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <Suspense
            fallback={
              <Table
                stretch
                hidePagination
                data={[{}, {}, {}, {}]}
                columns={skeletonColumns}
              />
            }
          >
            <AccountPersonaDetailsTable
              accountId={props.accountId}
              campaignId={props.campaignId}
            />
          </Suspense>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

type AccountPersonaDetailsTableProps = {
  accountId: number;
  campaignId: number;
};

function AccountPersonaDetailsTable(props: AccountPersonaDetailsTableProps) {
  const campaign = useCampaign({ campaignId: props.campaignId });
  const buyingGroupPersonaDetails = useBuyingGroupPersonaDetails({
    accountId: props.accountId,
    campaignId: props.campaignId,
  });
  const columns = useMemo(() => {
    if (campaign.campaignAdvancedSettings.showBuyingGroupContactDetails) {
      return detailedColumns;
    }

    return basicColumns;
  }, [campaign.campaignAdvancedSettings.showBuyingGroupContactDetails]);

  return (
    <Table<AccountPersonaDetails>
      stretch
      hidePagination
      data={buyingGroupPersonaDetails.data ?? []}
      columns={columns}
    />
  );
}

const baseColumns = [
  { header: "Persona Name", accessorKey: "persona_name" },
  { header: "First Name", accessorKey: "first_name" },
  { header: "Last Name", accessorKey: "last_name" },
  { header: "Job Function", accessorKey: "job_function" },
  { header: "Job Level", accessorKey: "job_level" },
  { header: "Job Title", accessorKey: "job_title" },
] satisfies ColumnDef<AccountPersonaDetails>[];

const basicColumns = [
  ...baseColumns,
  {
    header: "Business Email",
    accessorKey: "has_business_email",
    cell: ({ row }) => (
      <Flex>
        <Icon as={row.original.has_business_email ? FiCheck : FiMinus} />
      </Flex>
    ),
  },
  {
    header: "Direct Number",
    accessorKey: "has_direct_number",
    cell: ({ row }) => (
      <Flex>
        <Icon as={row.original.has_direct_number ? FiCheck : FiMinus} />
      </Flex>
    ),
  },
  {
    header: "Mobile Phone",
    accessorKey: "has_mobile_phone",
    cell: ({ row }) => (
      <Flex>
        <Icon as={row.original.has_mobile_phone ? FiCheck : FiMinus} />
      </Flex>
    ),
  },
  {
    header: "Has Social URL",
    accessorKey: "has_linkedin_url",
    cell: ({ row }) => (
      <Flex>
        <Icon as={row.original.has_linkedin_url ? FiCheck : FiMinus} />
      </Flex>
    ),
  },
] satisfies ColumnDef<AccountPersonaDetails>[];

const detailedColumns = [
  ...baseColumns,
  { header: "Business Email", accessorKey: "business_email" },
  { header: "Direct Number", accessorKey: "direct_number" },
  { header: "Mobile Phone", accessorKey: "mobile_phone" },
  {
    header: "Social URL",
    accessorKey: "linkedin_url",
    cell: ({ row }) =>
      row.original.linkedin_url && (
        <Link
          isExternal
          rel="noopener noreferrer"
          href={`https://${row.original.linkedin_url}`}
          color="teal.800"
          _dark={{ color: "teal.200" }}
          _hover={{ color: "teal.500" }}
        >
          {row.original.linkedin_url}
        </Link>
      ),
  },
] satisfies ColumnDef<AccountPersonaExpandedDetails>[];

const skeletonColumns = basicColumns.map((column, index) => ({
  ...column,
  cell: () => (
    <Skeleton
      width={index === 0 ? "150px" : index === 4 ? "240px" : "100%"}
      height="32px"
    />
  ),
})) satisfies ColumnDef<AccountPersonaDetails>[];

function useBuyingGroupPersonaDetails(options: {
  accountId: number;
  campaignId: number;
}) {
  const hasAccess = useHasAccessTo("buyingGroup.insights");

  return useQuery({
    suspense: true,
    enabled: hasAccess,
    queryKey: ["buyingGroupPersonaDetails", options],
    queryFn: async () => {
      const { data } = await apiService.get<AccountPersonaDetails[] | null>(
        Endpoints.Campaigns.Get.BuyingGroupPersonaDetails(
          options.campaignId,
          options.accountId
        )
      );

      return data;
    },
  });
}
