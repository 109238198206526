import { Box, Flex } from "@chakra-ui/react";
import { SplitBy } from "@intentsify/types";
import { getDatesFromDateRange, isPopulatedArray } from "@intentsify/utils";
import { useMemo } from "react";
import { AiOutlineLineChart, AiOutlineTable } from "react-icons/ai";
import { FiFileText } from "react-icons/fi";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { accountDetailsAtom } from "../../CampaignsTrendingDomains.state";
import { Filters, TopPerformingDomainsTable } from "./components";
import {
  getTopAccounts,
  getTopPerformingDomainsOptions,
} from "./TopPerformingDomains.requests";
import {
  performingDomainsOptionsStateAtom,
  topPerformingDomainsStateSelector,
  topPerformingDomainsViewAtom,
  topPerformingSelectedDomainsAtom,
} from "./TopPerformingDomains.state";
import { timeseriesToCsvData } from "utils";
import {
  ChartExport,
  useCampaignMeta,
  useExportableChart,
} from "shared/components";
import { ViewEnum } from "screens/IntentAnalysis/screens/CampaignsTrendingDomains/CampaignsTrendingDomains.types";
import {
  IconButton,
  Timeseries,
  ViewContainer,
  ViewSelector,
} from "components";

const title = "Most Active Accounts";

const TopPerformingDomains = () => {
  const { campaignId } = useParams<"campaignId">();

  const { campaignMeta: meta } = useCampaignMeta({ campaignId });

  const setSelectedView = useSetRecoilState(topPerformingDomainsViewAtom);
  const setOptions = useSetRecoilState(performingDomainsOptionsStateAtom);
  const setSelectedDomains = useSetRecoilState(
    topPerformingSelectedDomainsAtom
  );

  const setAccountDetails = useSetRecoilState(accountDetailsAtom);

  const {
    isLoading,
    selectedView,
    selectedDateRange,
    selectedSplitBy,
    selectedDomains,
  } = useRecoilValue(topPerformingDomainsStateSelector);

  useQuery(
    ["campaignsPerformingDomainsOptions", campaignId, selectedDateRange],
    () => {
      if (campaignId) {
        return getTopPerformingDomainsOptions(
          campaignId,
          selectedDateRange,
          SplitBy.Nothing
        );
      }
    },
    {
      enabled: Boolean(campaignId),
      onSuccess: (data) => {
        if (data) {
          setOptions({ isLoading: false, ...data });

          setSelectedDomains({
            campaignId,
            data: data.topPerformingDomains.slice(0, 5),
          });
        }
      },
    }
  );

  const { data, isFetching } = useQuery(
    [
      "getTopPerformingDomains",
      campaignId,
      selectedDomains.data.map((a) => a.value).join(),
      selectedSplitBy,
    ],
    () => {
      if (selectedDomains.campaignId !== campaignId) {
        return;
      }

      return getTopAccounts({
        campaignId,
        accounts: selectedDomains.data,
        dateRange: selectedDateRange,
        splitBy: selectedSplitBy,
      });
    },
    {
      enabled: !isLoading,
    }
  );

  const { start, end } = getDatesFromDateRange(
    selectedDateRange,
    new Date(meta?.campaignStartDate ?? ""),
    new Date(meta?.campaignEndDate ?? "")
  );

  const dates = useMemo(() => {
    return {
      start: start.toISO(),
      end: end.toISO(),
    };
  }, [start, end]);

  const chart = useExportableChart({
    title,
    campaignId: Number(campaignId),
    dates,
    chart: (
      <Timeseries
        noDataMessage={
          !isPopulatedArray(selectedDomains) ? "No data found." : undefined
        }
        stretch={true}
        dataMainKey={"label"}
        isLoading={isFetching || isLoading}
        data={data?.data ?? []}
        dataKeyLabels={{ value: "Intent Signals" }}
        timeseries={data?.timeseries ?? []}
        legendItemActions={(item) => {
          return (
            <IconButton
              size="xs"
              tooltip="View account details"
              variant="outline"
              icon={<FiFileText />}
              onClick={(e) => {
                e.stopPropagation();

                if (campaignId) {
                  setAccountDetails({
                    campaignId: Number(campaignId),
                    account: String(item.label),
                    dateRange: selectedDateRange,
                  });
                }
              }}
            />
          );
        }}
      />
    ),
  });

  return (
    <ViewContainer
      withBackground
      expandable={true}
      title={title}
      actionItems={
        <Flex>
          <ViewSelector
            items={[
              {
                value: ViewEnum.Table,
                onClick: setSelectedView,
                label: "Table",
                icon: <AiOutlineTable />,
              },
              {
                value: ViewEnum.TimeSeries,
                onClick: setSelectedView,
                label: "Timeseries",
                icon: <AiOutlineLineChart />,
              },
            ]}
            selected={selectedView}
          />

          <Box ml={2}>
            <ChartExport
              dates={dates}
              campaignId={Number(campaignId)}
              title={chart.title}
              onExportPNG={chart.downloadAsPng}
              data={
                timeseriesToCsvData(data, "label", { value: "Signal" }) ?? []
              }
            />
          </Box>
        </Flex>
      }
    >
      <Filters />

      {selectedView === ViewEnum.Table && (
        <TopPerformingDomainsTable
          isLoading={isFetching || isLoading}
          data={data}
        />
      )}

      {selectedView === ViewEnum.TimeSeries && (
        <Flex flexGrow={1}>{chart.component}</Flex>
      )}

      {chart.exportComponent}
    </ViewContainer>
  );
};

export { TopPerformingDomains };
