import { useToast } from "@chakra-ui/react";
import { CompanyNameToDomainRequestDTO, TalToolFileRO } from "@intentsify/dto";
import { TalToolsTypes } from "@intentsify/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import {
  mapTalToolFile,
  talPreparationToolsFilesKey,
} from "../../../hooks/useTalPreparationToolsFiles";

export const useCompanyNameToDomain = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (
      companyNameToDomainRequestDTO: CompanyNameToDomainRequestDTO
    ) =>
      apiService
        .post<TalToolFileRO>(
          Endpoints.TalTools.CompanyNameToDomain.Post,
          companyNameToDomainRequestDTO
        )
        .then((response) => mapTalToolFile(response.data)),
    onSuccess: (processingFile) => {
      const data = queryClient.getQueryData<TalToolFileRO[]>(
        talPreparationToolsFilesKey(TalToolsTypes.COMPANY_NAME_TO_DOMAIN)
      );

      if (!data) {
        return;
      }

      queryClient.setQueryData(
        talPreparationToolsFilesKey(TalToolsTypes.COMPANY_NAME_TO_DOMAIN),
        data.map((file) =>
          file.fileName === processingFile.fileName ? processingFile : file
        )
      );
    },
    onError: () => {
      toast({
        status: "error",
        description:
          "Requesting company name to domain failed. Please try again.",
        duration: null,
        isClosable: true,
      });
    },
  });
};
