import { Box, Center, Flex, Text } from "@chakra-ui/layout";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import { PropsWithChildren, ReactNode } from "react";
import { FaChevronRight } from "react-icons/fa";

const transition = "transform 250ms cubic-bezier(0.33, 1, 0.68, 1)";

const useColor = () => ({
  name: useColorModeValue("gray.500", "gray.300"),
  nameExpanded: useColorModeValue("black", "white"),
  border: useColorModeValue("gray.100", "gray.700"),
});

interface SectionProps {
  name: string;
  description?: string;
  icon?: ReactNode;
  isLazy?: boolean;
  defaultIndex?: number[];
  tooltip?: ReactNode;
}
export const Section = ({
  name,
  description,
  icon,
  isLazy = false,
  children,
  defaultIndex,
  tooltip,
}: PropsWithChildren<SectionProps>) => {
  const color = useColor();

  // Fixes accordion overflow issues
  const overflowVisible = css`
    .chakra-collapse {
      overflow: visible !important;
    }
  `;

  return (
    <Accordion
      css={overflowVisible}
      allowMultiple
      w="100%"
      mt="-1px"
      borderColor={color.border}
      defaultIndex={defaultIndex}
      onChange={() => {
        // Triggering a scroll event fixes https://github.com/airbnb/visx/issues/983
        // Without it visx's tooltip can end up positioned incorrectly
        setTimeout(() => {
          window.dispatchEvent(new CustomEvent("scroll"));
        }, 500);
      }}
    >
      <AccordionItem>
        {({ isExpanded }) => {
          return (
            <>
              <AccordionButton py="10">
                <Flex w="100%">
                  <Center>
                    <Icon
                      as={FaChevronRight}
                      color={isExpanded ? color.nameExpanded : color.name}
                      transform={`rotate(${isExpanded ? 90 : 0}deg)`}
                      transition={transition}
                    />
                  </Center>

                  <Flex
                    ml="2"
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Box>
                      <Text
                        fontSize="xl"
                        color={isExpanded ? color.nameExpanded : color.name}
                      >
                        {name} {tooltip}
                      </Text>

                      {description && <Text>{description}</Text>}
                    </Box>

                    <Box>{icon}</Box>
                  </Flex>
                </Flex>
              </AccordionButton>

              {!isLazy && <AccordionPanel px={0}>{children}</AccordionPanel>}

              {isLazy && isExpanded && (
                <AccordionPanel px={0}>{children}</AccordionPanel>
              )}
            </>
          );
        }}
      </AccordionItem>
    </Accordion>
  );
};
