import { Icon as CharkaIcon } from "@chakra-ui/icon";
import { CopyIcon } from "@chakra-ui/icons";
import { Flex } from "@chakra-ui/layout";
import { AutodiscoveryUrl } from "@intentsify/types";
import { ColumnDef } from "@tanstack/react-table";
import { IconButton } from "components/IconButton";
import { filesize } from "filesize";
import { useEffect, useMemo, useState } from "react";
import { FiDownload, FiTrash } from "react-icons/fi";
import { useTrackCampaignFileDownloaded } from "tracking/useTrackCampaignFileDownloaded";
import { AutodiscoveryFile } from "./AutodiscoveryFiles.types";
import {
  getFileNameFromUrl,
  getFileSizeFromUrl,
} from "./AutodiscoveryFiles.utils";

type RowProps = {
  file: AutodiscoveryFile;
  onDelete: (urlId: string) => void;
  onDownload: () => void;
};

const RowActions = ({ file, onDelete, onDownload }: RowProps) => {
  const { fileName, url, id } = file;

  const handleDownloadClick = (url: string) => {
    return fetch(url)
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      })
      .then(onDownload);
  };

  return (
    <Flex gap="2" justifyContent="flex-end">
      <IconButton
        tooltip="Delete file"
        icon={<CharkaIcon as={FiTrash} fontSize="md" />}
        onClick={() => onDelete(id.toString())}
      />
      <IconButton
        as="a"
        icon={<CharkaIcon as={CopyIcon} fontSize="md" />}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={() => navigator.clipboard.writeText(url)}
        tooltip="Copy to clipboard"
      />
      <IconButton
        as="a"
        icon={<CharkaIcon as={FiDownload} fontSize="md" />}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={() => handleDownloadClick(url)}
        tooltip="Download file"
        download={fileName}
      />
    </Flex>
  );
};

export const useColumns = (
  campaignId: string,
  handleRowDelete: (id: string) => void
) => {
  const trackCampaignFileDownloaded = useTrackCampaignFileDownloaded();

  return useMemo(() => {
    const columns: ColumnDef<AutodiscoveryFile>[] = [
      {
        header: "File name",
        accessorKey: "fileName",
      },
      {
        header: "Size",
        accessorKey: "size",
      },
      {
        id: "actions",
        header: () => null,
        cell: ({ row }) => {
          if (!row.original) {
            return null;
          }

          return (
            <RowActions
              onDelete={handleRowDelete}
              file={row.original}
              onDownload={() => {
                trackCampaignFileDownloaded({
                  type: "campaign autodiscovery",
                  campaignIds: [Number(campaignId)],
                });
              }}
            />
          );
        },
      },
    ];

    return columns;
  }, [campaignId, handleRowDelete, trackCampaignFileDownloaded]);
};

export const useGetAutodiscoveryFiles = (
  autodiscoveryFilesUrls: AutodiscoveryUrl[]
) => {
  const [files, setFiles] = useState<AutodiscoveryFile[]>([]);
  useEffect(() => {
    const getAutodiscoveryFiles = async () => {
      const files = await Promise.all(
        autodiscoveryFilesUrls.map(
          async ({ url, id, status }): Promise<AutodiscoveryFile> => {
            const size = await getFileSizeFromUrl(url);
            return {
              id,
              url,
              status,
              fileName: getFileNameFromUrl(url) || "N/A",
              size: size ? (filesize(size) as string) : "N/A",
            };
          }
        )
      );
      setFiles(files);
    };

    getAutodiscoveryFiles();
  }, [autodiscoveryFilesUrls]);

  return files;
};
