import { useQuery } from "@tanstack/react-query";
import { apiService, Endpoints } from "api";
import { TalToolsTypes } from "@intentsify/types";
import { TalToolFileRO } from "@intentsify/dto";

export const mapTalToolFile = (file: TalToolFileRO) => ({
  ...file,
  lastModified: new Date(String(file.lastModified)),
});

export const talPreparationToolsFilesKey = (type: TalToolsTypes) => [
  Endpoints.TalTools.Files(type),
];

export const useTalPreparationToolsFiles = (type: TalToolsTypes) => {
  return useQuery(
    talPreparationToolsFilesKey(type),
    async (): Promise<TalToolFileRO[]> => {
      const { data } = await apiService.get<TalToolFileRO[]>(
        Endpoints.TalTools.Files(type)
      );
      return data.map(mapTalToolFile);
    }
  );
};
