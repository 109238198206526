import { ListProgrammaticCampaignsRO } from "@intentsify/dto";
import { ProgrammaticCampaign } from "@intentsify/types";
import { apiService, Endpoints } from "api";
import { FetchDataParamsWithToken } from "types";

export const listPaginatedProgrammaticCampaigns = async (
  params: FetchDataParamsWithToken<keyof ProgrammaticCampaign>
): Promise<ListProgrammaticCampaignsRO> => {
  const { data } = await apiService.get<ListProgrammaticCampaignsRO>(
    Endpoints.ProgrammaticCampaigns.Get.Paginated,
    {
      params,
    }
  );

  return data;
};

export const getProgrammaticCampaign = async (
  programmaticCampaignId: number
): Promise<ProgrammaticCampaign> => {
  const { data } = await apiService.get<ProgrammaticCampaign>(
    Endpoints.ProgrammaticCampaigns.Get.ById(programmaticCampaignId)
  );
  return data;
};
