import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  PopoverProps,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import { formatDate } from "@intentsify/utils";
import { Popover } from "components";
import {
  getDatePickerCssModifiers,
  getWeekDays,
  getWeekRange,
} from "components/DatePicker/DatePicker.utils";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { FiCalendar } from "react-icons/fi";
import { Picker } from "./Picker";

const getDisabledMonthNavigationCss = ({
  start,
  end,
  type,
}: {
  start: Date;
  end: Date;
  type: "start" | "end";
}) => {
  const s = DateTime.fromJSDate(start);
  const e = DateTime.fromJSDate(end);

  const isTheSameMonth = s.month === e.month && s.year === e.year;

  if (!isTheSameMonth) {
    return "";
  }

  if (type === "start") {
    return `
        .rdp-nav_button_next {
            opacity: 0.3;
            pointer-events: none
        }
     `;
  }

  if (type === "end") {
    return `
        .rdp-nav_button_previous {
            opacity: 0.3;
            pointer-events: none
        }
     `;
  }
};

export type PickerRange = {
  start: string;
  end: string;
};

type RangePicker = {
  mode: "week" | "day";
  selected: PickerRange;
  onChange: (weekRange: PickerRange) => void;
  minW?: string;
  isDisabled?: boolean;
  disabledDays?: { lt?: DateTime; gt?: DateTime }[];
  placement?: PopoverProps["placement"];
};

const toInputValue = (range: PickerRange) =>
  `${formatDate({
    date: DateTime.fromISO(range.start, { zone: "utc" }).toJSDate(),
  })} - ${formatDate({
    date: DateTime.fromISO(range.end, { zone: "utc" }).toJSDate(),
  })}`;

const RangePicker = ({
  mode,
  selected,
  onChange,
  minW = "250px",
  isDisabled = false,
  disabledDays = [],
  placement = undefined,
}: RangePicker) => {
  const [isOpen, setIsOpen] = useState(false);

  const { colorMode } = useColorMode();

  const selectedProper: PickerRange = useMemo(() => {
    if (mode === "week") {
      return {
        start: DateTime.fromISO(selected.start, { zone: "utc" })
          .startOf("week")
          .toISO(),
        end: DateTime.fromISO(selected.end, { zone: "utc" })
          .startOf("week")
          .startOf("day")
          .toISO(),
      };
    }

    return {
      start: DateTime.fromISO(selected.start, { zone: "utc" }).toISO(),
      end: DateTime.fromISO(selected.end, { zone: "utc" }).toISO(),
    };
  }, [selected, mode]);

  const [inputValue, setInputValue] = useState(toInputValue(selectedProper));

  useEffect(() => {
    setInputValue(toInputValue(selectedProper));
  }, [selectedProper]);

  const [startMonth, setStartMonth] = useState(
    DateTime.fromObject({
      weekYear: DateTime.fromISO(selectedProper.start, { zone: "utc" }).year,
      weekNumber: DateTime.fromISO(selectedProper.start, { zone: "utc" })
        .weekNumber,
    }).toJSDate()
  );

  const [endMonth, setEndMonth] = useState(
    DateTime.fromObject({
      weekYear: DateTime.fromISO(selectedProper.end, { zone: "utc" }).year,
      weekNumber: DateTime.fromISO(selectedProper.end, { zone: "utc" })
        .weekNumber,
    }).toJSDate()
  );

  useEffect(() => {
    if (isOpen) {
      setStartMonth(
        DateTime.fromObject({
          weekYear: DateTime.fromISO(selectedProper.start, { zone: "utc" })
            .year,
          weekNumber: DateTime.fromISO(selectedProper.start, { zone: "utc" })
            .weekNumber,
        }).toJSDate()
      );

      setEndMonth(
        DateTime.fromObject({
          weekYear: DateTime.fromISO(selectedProper.end, { zone: "utc" }).year,
          weekNumber: DateTime.fromISO(selectedProper.end, { zone: "utc" })
            .weekNumber,
        }).toJSDate()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const startChange = (date: Date) => {
    if (mode === "week") {
      const weekDays = getWeekDays(getWeekRange(date));

      onChange({
        ...selectedProper,
        start: DateTime.fromISO(DateTime.fromJSDate(weekDays[0]).toISODate(), {
          zone: "utc",
        }).toISO(),
      });

      return;
    }

    onChange({
      ...selectedProper,
      start: DateTime.fromISO(DateTime.fromJSDate(date).toISODate(), {
        zone: "utc",
      }).toISO(),
    });
  };

  const endChange = (date: Date) => {
    if (mode === "week") {
      const weekDays = getWeekDays(getWeekRange(date));

      onChange({
        ...selectedProper,
        end: DateTime.fromISO(
          DateTime.fromJSDate(weekDays[weekDays.length - 1]).toISODate()
        )
          .toUTC()
          .toISO(),
      });

      return;
    }

    onChange({
      ...selectedProper,
      end: DateTime.fromISO(DateTime.fromJSDate(date).toISODate(), {
        zone: "utc",
      }).toISO(),
    });
  };

  const selectedRange = useMemo(() => {
    const days: Date[] = [];

    const rightEdge =
      mode === "week"
        ? DateTime.fromObject({
            weekYear: DateTime.fromISO(selectedProper.end, { zone: "utc" })
              .year,
            weekNumber: DateTime.fromISO(selectedProper.end, { zone: "utc" })
              .weekNumber,
          })
            .endOf("week")
            .startOf("day")
        : DateTime.fromObject({
            weekYear: DateTime.fromISO(selectedProper.end, { zone: "utc" })
              .year,
            weekNumber: DateTime.fromISO(selectedProper.end, { zone: "utc" })
              .weekNumber,
          });

    for (
      let current = DateTime.fromObject({
        weekYear: DateTime.fromISO(selectedProper.start, { zone: "utc" }).year,
        weekNumber: DateTime.fromISO(selectedProper.start, { zone: "utc" })
          .weekNumber,
      });
      current <= rightEdge;
      current = current.plus({ days: 1 })
    ) {
      days.push(current.toJSDate());
    }

    return days;
  }, [mode, selectedProper.end, selectedProper.start]);

  return (
    <Box
      css={css`
        ${getDatePickerCssModifiers(colorMode)}
      `}
    >
      <Popover
        placement={placement}
        isOpen={isOpen}
        width="45rem"
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        popoverTrigger={
          <InputGroup size="sm" alignItems={"center"}>
            <Input
              minW={minW}
              shadow="sm"
              rounded="md"
              isReadOnly
              value={inputValue}
              width="100%"
              cursor={"pointer"}
              isDisabled={isDisabled}
            />
            <InputRightElement pointerEvents="none" alignContent={"center"}>
              <FiCalendar />
            </InputRightElement>
          </InputGroup>
        }
        popoverBody={
          <Flex flexDir="column">
            <Flex borderTopRadius={5} p={4} backgroundColor="rgba(0,0,0,0.1)">
              <Flex w="50%" justifyContent="center">
                <Text fontSize="sm">
                  <b>From:</b> {mode === "week" && <>week starting</>}{" "}
                  {formatDate({
                    date: DateTime.fromISO(selectedProper.start, {
                      zone: "utc",
                    }).toJSDate(),
                  })}
                  .
                </Text>
              </Flex>

              <Flex w="50%" justifyContent="center">
                <Text fontSize="sm">
                  <b>To:</b> {mode === "week" && <>week ending</>}{" "}
                  {formatDate({
                    date: DateTime.fromISO(selectedProper.end, {
                      zone: "utc",
                    })
                      .endOf("week")
                      .toJSDate(),
                  })}
                  .
                </Text>
              </Flex>
            </Flex>

            <Flex justifyContent="space-evenly">
              <Flex
                alignItems="center"
                flexDir="column"
                css={css`
                  ${getDisabledMonthNavigationCss({
                    start: startMonth,
                    end: endMonth,
                    type: "start",
                  })}
                `}
              >
                <Picker
                  mode={mode}
                  selected={selectedProper.start}
                  selectedRange={selectedRange}
                  onChange={(isoDate) => {
                    startChange(
                      DateTime.fromISO(isoDate, { zone: "utc" }).toJSDate()
                    );
                  }}
                  month={startMonth}
                  onMonthChange={(month) => setStartMonth(month)}
                  disabled={(d) => {
                    const end = DateTime.fromISO(selectedProper.end, {
                      zone: "utc",
                    });

                    const date = DateTime.fromJSDate(d, { zone: "utc" });

                    if (date > end) {
                      return true;
                    }

                    const withinRange = disabledDays.some(({ lt, gt }) => {
                      if (lt && !gt && date <= lt) {
                        return true;
                      }

                      if (gt && !lt && date >= gt) {
                        return true;
                      }

                      if (gt && lt && date >= lt && date <= gt) {
                        return true;
                      }

                      return false;
                    });

                    if (withinRange) {
                      return true;
                    }

                    return false;
                  }}
                />
              </Flex>

              <Flex
                alignItems="center"
                flexDir="column"
                css={css`
                  ${getDisabledMonthNavigationCss({
                    start: startMonth,
                    end: endMonth,
                    type: "end",
                  })}
                `}
              >
                <Picker
                  mode={mode}
                  selected={selectedProper.end}
                  selectedRange={selectedRange}
                  onChange={(isoDate) => {
                    endChange(
                      DateTime.fromISO(isoDate, { zone: "utc" }).toJSDate()
                    );
                  }}
                  month={endMonth}
                  onMonthChange={(month) => setEndMonth(month)}
                  disabled={(d) => {
                    const start = DateTime.fromISO(selectedProper.start, {
                      zone: "utc",
                    });
                    const date = DateTime.fromJSDate(d, { zone: "utc" });

                    if (date < start) {
                      return true;
                    }

                    const withinRange = disabledDays.some(({ lt, gt }) => {
                      if (lt && date < lt) {
                        return true;
                      }

                      if (gt && date > gt) {
                        return true;
                      }

                      return false;
                    });

                    if (withinRange) {
                      return true;
                    }

                    return false;
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
        }
      />
    </Box>
  );
};

export { RangePicker };
