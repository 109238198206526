import { Box, Flex } from "@chakra-ui/react";
import { AccountFileDTO } from "@intentsify/dto";
import { FirmographicsFilters } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { Endpoints } from "api";
import isEqual from "lodash/isEqual";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";

import { useDownloadFile } from "utils";

import { UploadedFileEntry } from "shared/components/UploadedFiles/UploadedFileEntry";
import { UploadedFiles } from "shared/components/UploadedFiles/UploadedFiles";
import { defaultFirmoFilters } from "./Firmographics.const";
import { getFirmographicsCount } from "./Firmographics.requests";
import { filtersOrNull } from "./Firmographics.utils";
import { Filters } from "./components";

type FirmographicsProps = {
  currentFirmographicsFilters: FirmographicsFilters | undefined;
  firmographicsFilters: FirmographicsFilters;
  onSetFirmographicsFilters: (filters: FirmographicsFilters) => void;
  setIsLoadingFirmographics?: Dispatch<SetStateAction<boolean>>;
  files: AccountFileDTO[];
  onCountChange: (count: number) => void;
};

const Firmographics = ({
  currentFirmographicsFilters,
  files,
  firmographicsFilters,
  onSetFirmographicsFilters,
  onCountChange,
}: FirmographicsProps) => {
  const { campaignId } = useParams<"campaignId">();
  const fileName = `firmographics_domains_${Number(campaignId)}.csv`;
  const file = files[0];

  const didEditFilters = useMemo(() => {
    return !isEqual(currentFirmographicsFilters, firmographicsFilters);
  }, [currentFirmographicsFilters, firmographicsFilters]);

  const hasAnyFilters = Boolean(filtersOrNull(firmographicsFilters));
  const { download } = useDownloadFile();

  /**
   * Some old campaigns have firmographic domains but no filters stored in the DB
   */
  const [shouldRenderFileAnyway, setShouldRenderFileAnyway] = useState(
    Boolean(!filtersOrNull(firmographicsFilters) && file)
  );

  const properFilters: FirmographicsFilters | undefined = useMemo(() => {
    const filtersToUse = filtersOrNull(firmographicsFilters);

    if (!filtersToUse) {
      return undefined;
    }

    const { installBaseProducts, ...remainingFirmographicsFilters } =
      filtersToUse;

    return {
      ...defaultFirmoFilters, // older campaigns may miss includeOffice filter, so we need to include defaults
      ...remainingFirmographicsFilters,
      installBaseProducts: installBaseProducts.map(({ value, label }) => {
        const productId = String(value).split("_,_").pop() || "";
        return {
          value: productId,
          label: label,
        };
      }),
    };
  }, [firmographicsFilters]);

  const { isFetching, data: count } = useQuery(
    ["firmographicsList", properFilters],
    () => getFirmographicsCount(properFilters),
    {
      enabled: Boolean(properFilters),
    }
  );

  useEffect(() => {
    if (!hasAnyFilters) {
      onCountChange(0);
      return;
    }

    onCountChange(count ?? 0);
  }, [count, onCountChange, hasAnyFilters]);

  return (
    <>
      <Box w="100%">
        <Filters
          setFirmographicsFilters={(val) => {
            const newFilters = { ...firmographicsFilters, ...val };

            if (isEqual(newFilters, firmographicsFilters)) {
              return;
            }

            setShouldRenderFileAnyway(false);
            onSetFirmographicsFilters(newFilters || defaultFirmoFilters);
          }}
          filters={firmographicsFilters}
        />

        {campaignId && shouldRenderFileAnyway && file ? (
          <Flex h="100%" flex="1" flexDir="column">
            <Box
              w="100%"
              borderBottomLeftRadius={5}
              borderBottomRightRadius={5}
              p={4}
              border="1px solid #a1aabb33"
            >
              <UploadedFiles>
                <UploadedFileEntry
                  isLoadingCount={isFetching}
                  file={file}
                  onDeleteIconClick={() => {
                    setShouldRenderFileAnyway(false);
                    onSetFirmographicsFilters(defaultFirmoFilters);
                  }}
                  onDownloadClick={() => {
                    download({
                      url: Endpoints.Campaigns.Get.Domains.DownloadFirmographicsFile(
                        Number(campaignId)
                      ),
                      fileName,
                    });
                  }}
                />
              </UploadedFiles>
            </Box>
          </Flex>
        ) : (
          <>
            {hasAnyFilters && didEditFilters && (
              <Flex h="100%" flex="1" flexDir="column">
                <Box
                  w="100%"
                  borderBottomLeftRadius={5}
                  borderBottomRightRadius={5}
                  p={4}
                  border="1px solid #a1aabb33"
                >
                  <UploadedFiles>
                    <UploadedFileEntry
                      isLoadingCount={isFetching}
                      file={{
                        fileName,
                        accountsCount: count ?? 0,
                      }}
                      onDeleteIconClick={() => {
                        onSetFirmographicsFilters(defaultFirmoFilters);
                      }}
                      onDownloadClick={() => {
                        download({
                          url: Endpoints.Campaigns.Get.Firmographics.Download,
                          fileName,
                          data: firmographicsFilters,
                          method: "POST",
                        });
                      }}
                    />
                  </UploadedFiles>
                </Box>
              </Flex>
            )}

            {hasAnyFilters && !didEditFilters && file && (
              <Flex h="100%" flex="1" flexDir="column">
                <Box
                  w="100%"
                  borderBottomLeftRadius={5}
                  borderBottomRightRadius={5}
                  p={4}
                  border="1px solid #a1aabb33"
                >
                  <UploadedFiles>
                    <UploadedFileEntry
                      isLoadingCount={isFetching}
                      file={file}
                      onDeleteIconClick={() => {
                        onSetFirmographicsFilters(defaultFirmoFilters);
                      }}
                      onDownloadClick={() => {
                        download({
                          url: Endpoints.Campaigns.Get.Domains.DownloadFirmographicsFile(
                            Number(campaignId)
                          ),
                          fileName,
                        });
                      }}
                    />
                  </UploadedFiles>
                </Box>
              </Flex>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export { Firmographics };
