import {
  FirmographicsFilters,
  NaicsCodeLevel,
  Node,
  Option,
  SicCodeLevel,
} from "@intentsify/types";
import { isNumeric, nest } from "@intentsify/utils";
import findIndex from "lodash/findIndex";
import isEqual from "lodash/isEqual";
import { defaultFirmoFilters } from "./Firmographics.const";

export const filtersOrNull = (filters: FirmographicsFilters) => {
  const hasNotUsedFilters = isEqual(filters, defaultFirmoFilters);

  return hasNotUsedFilters ? null : filters;
};

/**
 * Sic codes are not unique, they can have the same value on different levels, e.g: SIC3: 313 and SIC4: 313.
 * In the database they are sorted and root of each tree begins with a letter, so the data is like:
  
  | code | name   |   |   |   |
  |------|--------|---|---|---|
  | A    | string |   |   |   |
  | 1    | string |   |   |   |
  | 11   | string |   |   |   |
  | B    | string |   |   |   |
  | 2    | string |   |   |   |

 * The data array is sliced at letters (A,B,...), it creates a pool of nodes that belong to each root (letter),
 * then each tree is built individually. 
 * We also need to prefix the values with letters, e.g.: `11` becomes `A_11`, otherwise the Tree component
 * will confuse duplicate nodes.
 */
export const buildSicCodesNodeTree = <
  T extends Node<{ level: SicCodeLevel; parent: number }>
>(
  sicCodes: Option<number, { level: SicCodeLevel; parent: number }>[]
): T[] => {
  const topLevel = sicCodes.filter((i) => !isNumeric(i.value));

  const trees = topLevel
    .map((root, index) => {
      const start = findIndex(sicCodes, (i) => i.value === root.value);
      const end =
        index === topLevel.length - 1
          ? Infinity
          : findIndex(
              sicCodes.slice(start + 1, Infinity),
              (i) => !isNumeric(i.value)
            ) +
            start +
            1;

      const pool = sicCodes.slice(start, end) as T[];

      const nodes: T[] = pool.map((i) => ({
        ...i,
        value: isNumeric(i.value) ? `${root.value}_${i.value}` : i.value,
        label: isNumeric(i.value)
          ? `${i.value.toString().padStart(2, "0")} - ${i.label}`
          : i.label,
        meta: {
          ...i.meta,
          parent: i.meta?.parent
            ? isNumeric(i.meta?.parent)
              ? `${root.value}_${String(i.meta?.parent)}`
              : i.meta?.parent
            : null,
        },
      })) as T[];

      return nest(nodes, null);
    })
    .flat();

  return trees.map((i) => {
    const firstChild = (i.children ? i.children[0] : undefined) as T;
    const lastChild = (
      i.children ? i.children[i.children.length - 1] : undefined
    ) as T;

    const firstValue = firstChild.value.toString().slice(2, Infinity);
    const lastValue = lastChild.value.toString().slice(2, Infinity);

    return {
      ...i,
      label: `${firstValue.padStart(2, "0")}-${lastValue.padStart(2, "0")} — ${
        i.label
      }`,
    };
  });
};

const NaicsGroups = [
  [31, 32, 33],
  [44, 45],
  [48, 40],
];

/**
 * Some of root nodes in Naics codes have the same name, e.g.: 31 Manufacturing, 32 Manufacturing and 33 Manufacturing.
 * These are combined into a single node for user's convenience.
 */
export const buildNaicsCodesNodeTree = <
  T extends Node<{ level: NaicsCodeLevel; parent: number }>
>(
  naicsCodes: Option<number, { level: NaicsCodeLevel; parent: number }>[],
  /**
   * Business wanted us to group naics2 codes with the same name, 31-33 for example (manufacturing).
   * To maintain backwords compatibility with previously selected naics2 codes (e.g.: someone could select just 32)
   * we don't group them.
   */
  shouldGroup: boolean
): T[] => {
  const data = naicsCodes.map((i) => ({
    ...i,
    label: `${i.value} - ${i.label}`,
  })) as unknown as T[];

  const tree = nest(data, null);

  if (!shouldGroup) {
    return tree;
  }

  const groups = NaicsGroups.map((group) => {
    const nodes = tree.filter((i) => group.includes(Number(i.value)));

    const label = `${nodes[0].value}-${
      nodes[nodes.length - 1].value
    }${nodes[0].label.slice(2, Infinity)}`;

    const node = {
      label,
      value: group.join("_"),
      children: nodes
        .flatMap((i) => i.children)
        .map((i) => ({
          ...i,
          meta: {
            ...i?.meta,
            parent: group.join("_"),
          },
        })),
    } as unknown as T;

    return node;
  });

  return [
    ...groups,
    ...tree.filter((i) => !NaicsGroups.flat().includes(Number(i.value))),
  ].sort((a, b) => {
    return (
      Number(String(a.value).slice(0, 2)) - Number(String(b.value).slice(0, 2))
    );
  });
};
