import {
  DateRange,
  defaultDateRangeFilters,
  Option,
  SplitBy,
} from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import {
  DateRangeFilter,
  Filter,
  FiltersContainer,
  FiltersContainerItem,
  SplitByFilter,
} from "components";
import { useParams } from "react-router-dom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import {
  selectedTrendingTopicsAtom,
  topicsSplitByAtom,
  trendingTopicsDateRangeAtom,
  trendingTopicsOptionsStateAtom,
  trendingTopicsStateSelector,
} from "../../TrendingTopics.state";

const Filters = () => {
  const { campaignId } = useParams<"campaignId">();
  const {
    selectedTopics,
    isLoading,
    topicsOptions,
    selectedSplitBy,
    selectedDateRange,
  } = useRecoilValue(trendingTopicsStateSelector);
  const setSelectedTopics = useSetRecoilState(selectedTrendingTopicsAtom);
  const resetOptions = useResetRecoilState(trendingTopicsOptionsStateAtom);
  const setSplitBy = useSetRecoilState(topicsSplitByAtom);
  const setDateRange = useSetRecoilState(trendingTopicsDateRangeAtom);

  const onSelectedTopicsChange = (options: Array<Option<string>>) => {
    setSelectedTopics({ campaignId, data: options });
  };

  const hasNoOptions = !isPopulatedArray(topicsOptions);
  const items: FiltersContainerItem[] = [
    {
      component: (
        <Filter
          label={"Topics"}
          options={topicsOptions}
          isLoading={isLoading}
          isDisabled={isLoading || hasNoOptions}
          placeholder="Select topics"
          onFilterValuesChange={(options: Option[]) => {
            onSelectedTopicsChange(options as Array<Option<string>>);
          }}
          selected={selectedTopics.data ?? []}
        />
      ),
      size: "xl",
    },
    {
      component: (
        <DateRangeFilter<DateRange>
          isLoading={isLoading}
          isDisabled={isLoading}
          dateRange={selectedDateRange}
          options={defaultDateRangeFilters.dateRangeOptions}
          onChange={(value) => {
            resetOptions();
            setDateRange(value);
          }}
        />
      ),
    },
    {
      component: (
        <SplitByFilter<SplitBy>
          onChange={(value) => setSplitBy(value)}
          options={defaultDateRangeFilters.splitByOptions}
          value={selectedSplitBy}
          isLoading={isLoading}
          isDisabled={isLoading || hasNoOptions}
        />
      ),
    },
  ];

  return <FiltersContainer items={items} />;
};

export { Filters };
