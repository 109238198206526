import { createScreen } from "utils/createScreen";
import { DownloadTalToolsScreen } from "../../types";

const DownloadTalToolsDefinition = createScreen({
  name: DownloadTalToolsScreen,
  path: "/download/tal-tools/:type/:fileName",
  routePath: ["/download/tal-tools/:type/:fileName"],
});

export { DownloadTalToolsDefinition };
