import { SmarTALDTO, TalToolFileRO } from "@intentsify/dto";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";

import { useToast } from "@chakra-ui/react";
import { TalToolsTypes } from "@intentsify/types";
import {
  mapTalToolFile,
  talPreparationToolsFilesKey,
} from "../../hooks/useTalPreparationToolsFiles";

export const useSmarTAL = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dto: SmarTALDTO) =>
      apiService
        .post<TalToolFileRO>(Endpoints.TalTools.SmarTAL.Post, dto)
        .then((response) => mapTalToolFile(response.data)),
    onSuccess: (processingFile) => {
      const data = queryClient.getQueryData<TalToolFileRO[]>(
        talPreparationToolsFilesKey(TalToolsTypes.SMARTAL)
      );

      if (!data) {
        return;
      }

      queryClient.setQueryData(
        talPreparationToolsFilesKey(TalToolsTypes.SMARTAL),
        data.map((file) =>
          file.fileName === processingFile.fileName ? processingFile : file
        )
      );
    },
    onError: () => {
      toast({
        status: "error",
        description: "SmartTAL failed. Please try again.",
        duration: null,
        isClosable: true,
      });
    },
  });
};
