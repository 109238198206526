import { TalToolFileState, TalToolsTypes } from "@intentsify/types";
import { TalToolFileRO } from "@intentsify/dto";
import { useCallback } from "react";
import { TalToolsFiles } from "../../TalToolsFiles/TalToolsFiles";
import { FirmoTechnoAppendFileTooltip } from "./FirmoTechnoAppendFileTooltip";

type FirmoTechnoAppendFilesProps = {
  files: TalToolFileRO[];
  onFileDelete: (fileName: string) => void;
};

export const FirmoTechnoAppendFiles = ({
  files,
  onFileDelete,
}: FirmoTechnoAppendFilesProps) => {
  const tooltipLabelRender = useCallback((file: TalToolFileRO) => {
    if (file.state === TalToolFileState.COMPLETED) {
      return {
        label: "",
        content: <FirmoTechnoAppendFileTooltip details={file.details} />,
      };
    }
    return null;
  }, []);

  return (
    <TalToolsFiles
      talToolsType={TalToolsTypes.FIRMO_AND_TECHNO}
      files={files}
      onFileDelete={onFileDelete}
      tooltipLabelRender={tooltipLabelRender}
    />
  );
};
