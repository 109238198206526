import { SiteEngagementLiftRO } from "@intentsify/dto";
import { apiService, Endpoints } from "api";

export const getSiteEngagementLift = async (
  id: number
): Promise<SiteEngagementLiftRO> => {
  const { data } = await apiService.get(
    Endpoints.ProgrammaticCampaigns.ImpressionsAndClicks.Get.SiteEngagementLift(
      id
    )
  );

  return data;
};
