import { GetProgrammaticCampaignFatigueMapRO } from "@intentsify/dto";
import { apiService, Endpoints } from "api";
import { DisplayPerformanceFilters } from "screens/CampaignPacing/screens/DisplayPerformance/DisplayPerformance";

export const getCreativeFatigueMap = async (
  id: number,
  filters: DisplayPerformanceFilters
): Promise<GetProgrammaticCampaignFatigueMapRO[]> => {
  const { data } = await apiService.get<GetProgrammaticCampaignFatigueMapRO[]>(
    Endpoints.ProgrammaticCampaigns.CreativeEngagement.Get.FatigueMap(id),
    {
      params: {
        yearFrom: filters.yearFrom,
        yearTo: filters.yearTo,
        weekFrom: filters.weekFrom,
        weekTo: filters.weekTo,
      },
    }
  );
  return data;
};
