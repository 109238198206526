import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Alert,
  Box,
  ButtonGroup,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
  chakra,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  AutomatedProgrammaticSegmentFormState,
  ProgrammaticDomainFile,
  SegmentType,
  TopLevelRegion,
  brsOptions,
  createAutomatedProgrammaticSegmentSchema,
  leadsStrategyOptions,
  regionOptions,
} from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { Button, FilterAsync, Select, ViewContainer } from "components";
import { useUserAgencyCompany } from "queries/companies/useUserAgencyCompany";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { CampaignSelector, SelectedCampaign } from "shared/components";
import { useCampaignBrief } from "shared/components/campaign/Campaign.state";
import { useComponentColors } from "theme";
import { useScreen } from "utils";
import { ProgrammaticDomainsScreenDefinition } from "../ProgrammaticDomains";
import { AutomatedProgrammaticSegmentDefinition } from "./AutomatedProgrammaticSegmentForm.definition";
import {
  listPaginatedActiveAccountsList,
  useCreateAutomatedSegment,
  useEditAutomatedProgrammaticSegment,
} from "./AutomatedProgrammaticSegmentForm.requests";
import { AutomatedProgrammaticSegmentUploads } from "./components/AutomatedProgrammaticSegmentUploads";

const formDefaultValues: AutomatedProgrammaticSegmentFormState = {
  hasIspData: false,
  buyerResearchStages: [],
  segmentType: SegmentType.IP,
  tag: "",
  region: TopLevelRegion.AMER,
  leadsStrategy: undefined,
  excludedAccounts: [],
  campaignId: 0,
};

type AutomatedProgrammaticSegmentFormProps = {
  automatedProgrammaticSegmentId?: number;
  initialState?: AutomatedProgrammaticSegmentFormState;
  mode: "create" | "edit";
  segmentUploads?: ProgrammaticDomainFile[];
};

const AutomatedProgrammaticSegmentForm = ({
  initialState,
  mode,
  segmentUploads,
  automatedProgrammaticSegmentId,
}: AutomatedProgrammaticSegmentFormProps) => {
  useScreen(AutomatedProgrammaticSegmentDefinition);
  const componentColors = useComponentColors();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    control,
    watch,
    handleSubmit,
  } = useForm<AutomatedProgrammaticSegmentFormState>({
    mode: "onChange",
    resolver: zodResolver(createAutomatedProgrammaticSegmentSchema),
    defaultValues: initialState || formDefaultValues,
  });

  const selectedSegmentType = watch("segmentType");
  const campaignId = watch("campaignId");
  const userAgencyCompany = useUserAgencyCompany();
  const selectedCompanyId = userAgencyCompany.data?.companyId;

  const availableRegionsOptions = [SegmentType.MAID].includes(
    selectedSegmentType
  )
    ? regionOptions.filter((option) => option.value === "AMER")
    : regionOptions;

  const { createAutomatedSegment, isLoading: isLoadingCreate } =
    useCreateAutomatedSegment();
  const { editAutomatedProgrammaticSegment, isLoading: isLoadingUpdate } =
    useEditAutomatedProgrammaticSegment();

  const { campaignBrief } = useCampaignBrief(campaignId);
  const [selected, setSelected] = useState<SelectedCampaign | undefined>(
    campaignId
      ? {
          value: campaignId,
          label: "",
        }
      : undefined
  );

  useEffect(() => {
    if (!campaignId) {
      setSelected(undefined);
    } else if (campaignBrief) {
      setSelected((currentlySelected) => {
        if (
          currentlySelected?.value !== campaignId ||
          !currentlySelected?.label
        ) {
          return {
            value: campaignBrief.campaignId,
            label: campaignBrief.campaignName,
            createdAt: campaignBrief.campaignCreateDate,
          };
        }

        return currentlySelected;
      });
    }
  }, [campaignBrief, campaignId]);

  useEffect(() => {
    setSelected(undefined);
  }, [selectedCompanyId]);

  return (
    <ViewContainer>
      <Alert status="info" mb={4}>
        <InfoOutlineIcon mr={2} />
        Automated workflows create new segment uploads based on defined filters
        and assigned intent models. These segments are automatically pushed to
        Beeswax every Monday.
      </Alert>

      <Divider />

      <Box display="flex" alignItems="center"></Box>

      <Box>
        <SimpleGrid>
          <chakra.form
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={handleSubmit((data) => {
              if (mode === "create") {
                createAutomatedSegment(data);
              } else {
                editAutomatedProgrammaticSegment({
                  ...data,
                  id: Number(automatedProgrammaticSegmentId),
                });
              }
            })}
            borderRadius={4}
          >
            <Text
              mt={4}
              color={componentColors.form.formLabelColor}
              fontSize="lg"
              fontWeight="bold"
            >
              Segment Upload
            </Text>
            <FormControl
              isRequired={true}
              isInvalid={Boolean(errors.campaignId?.message)}
              mt={4}
            >
              <FormLabel
                color={componentColors.form.formLabelColor}
                fontSize="sm"
                fontWeight="bold"
              >
                Intent model
              </FormLabel>
              <Controller
                name="campaignId"
                control={control}
                render={({ field }) => (
                  <CampaignSelector
                    selected={selected}
                    showOnlyCurrentUserCompanyCampaigns
                    onChange={(campaign) => field.onChange(campaign?.value)}
                  />
                )}
              />

              <FormErrorMessage>{errors.campaignId?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              w="400px"
              mt={4}
              isInvalid={!!errors?.tag?.message}
              isRequired
            >
              <FormLabel
                color={componentColors.form.formLabelColor}
                fontSize="sm"
                fontWeight="bold"
              >
                Tag
              </FormLabel>
              <Input
                {...register("tag")}
                type="name"
                name="tag"
                placeholder="Tag"
                shadow="sm"
                size="sm"
                rounded="md"
              />
              <FormErrorMessage>{errors?.tag?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              w="400px"
              mt={4}
              isInvalid={!!errors?.segmentType?.message}
              isRequired
            >
              <FormLabel
                color={componentColors.form.formLabelColor}
                fontSize="sm"
                fontWeight="bold"
              >
                Segments to Upload
              </FormLabel>

              <RadioGroup
                colorScheme="teal"
                defaultValue={initialState?.segmentType}
              >
                <Stack spacing={[1, 5]} direction={["column", "row"]}>
                  <Radio {...register("segmentType")} value={SegmentType.IP}>
                    IP Addresses
                  </Radio>
                  <Radio
                    value={SegmentType.USER_ID}
                    {...register("segmentType")}
                  >
                    User IDs
                  </Radio>
                  <Radio value={SegmentType.MAID} {...register("segmentType")}>
                    MAIDs
                  </Radio>
                </Stack>
              </RadioGroup>
              <FormErrorMessage>
                {errors?.segmentType?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              w="400px"
              mb={3}
              isInvalid={isPopulatedArray(errors?.region)}
              isRequired
              mt={4}
            >
              <FormLabel
                color={componentColors.form.formLabelColor}
                fontSize="sm"
                fontWeight="bold"
              >
                Region
              </FormLabel>
              <Controller
                name="region"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      isMulti={false}
                      value={regionOptions.find((i) => i.value === value)}
                      onChange={(selection) => {
                        onChange(selection?.value);
                      }}
                      options={availableRegionsOptions}
                      placeholder="Select region"
                    />
                  );
                }}
              />
              <FormErrorMessage>{errors?.region?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              w="400px"
              mb={3}
              isInvalid={!!errors?.hasIspData?.message}
            >
              <FormLabel
                color={componentColors.form.formLabelColor}
                fontSize="sm"
                fontWeight="bold"
              >
                ISP settings
              </FormLabel>

              <Controller
                name="hasIspData"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Checkbox
                      colorScheme="teal"
                      isChecked={Boolean(value)}
                      onChange={(v) => onChange(v)}
                    >
                      ISP Data Included
                    </Checkbox>
                  );
                }}
              />

              <FormErrorMessage>{errors?.hasIspData?.message}</FormErrorMessage>
            </FormControl>

            <Text
              mt={8}
              color={componentColors.form.formLabelColor}
              fontSize="lg"
              fontWeight="bold"
            >
              Filters
            </Text>

            <Stack maxW="400px">
              <FormControl
                isInvalid={Boolean(errors.buyerResearchStages?.message)}
              >
                <FormLabel
                  color={componentColors.form.formLabelColor}
                  fontSize="sm"
                  fontWeight="bold"
                >
                  Buyer Research Stage
                </FormLabel>
                <Controller
                  name="buyerResearchStages"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isMulti
                      isClearable={false}
                      placeholder="Select BRS stages"
                      options={brsOptions}
                      value={brsOptions.filter((option) =>
                        field.value?.includes(option.value)
                      )}
                      onChange={(value) => {
                        if (value && Array.isArray(value)) {
                          field.onChange(value.map((option) => option.value));
                        }
                      }}
                    />
                  )}
                />

                <FormErrorMessage>
                  {errors.buyerResearchStages?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={Boolean(errors.buyerResearchStages?.message)}
              >
                <FormLabel
                  color={componentColors.form.formLabelColor}
                  fontSize="sm"
                  fontWeight="bold"
                >
                  Leads Strategy
                </FormLabel>
                <Controller
                  name="leadsStrategy"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isMulti={false}
                      placeholder="Select leads strategy"
                      options={leadsStrategyOptions}
                      value={leadsStrategyOptions.filter((option) =>
                        field.value?.includes(option.value)
                      )}
                      onChange={(strategy) => field.onChange(strategy?.value)}
                    />
                  )}
                />

                <FormErrorMessage>
                  {errors.leadsStrategy?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={Boolean(errors.buyerResearchStages?.message)}
              >
                <FormLabel
                  color={componentColors.form.formLabelColor}
                  fontSize="sm"
                  fontWeight="bold"
                >
                  Exclude Accounts
                </FormLabel>
                <Controller
                  name="excludedAccounts"
                  control={control}
                  render={({ field }) => (
                    <FilterAsync
                      defaultOptions={[]}
                      showLabel={false}
                      isDisabled={!campaignId}
                      label="Exclude Accounts"
                      onFilterValuesChange={(value) => {
                        if (value && Array.isArray(value)) {
                          field.onChange(value.map((option) => option.value));
                        }
                      }}
                      dataRequest={listPaginatedActiveAccountsList(campaignId)}
                      resetFilterRequestDependencies={[campaignId]}
                    />
                  )}
                />

                <FormErrorMessage>
                  {errors.excludedAccounts?.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>

            <Divider mt={4} />

            <Flex mt={4} justifyContent="flex-end">
              <ButtonGroup>
                <Button
                  size="md"
                  onClick={() =>
                    navigate(
                      ProgrammaticDomainsScreenDefinition.navigate({
                        view: "automated",
                      })
                    )
                  }
                >
                  Cancel
                </Button>
                {mode === "create" ? (
                  <Button
                    size="md"
                    type="submit"
                    variant="primary-teal"
                    isLoading={isLoadingCreate}
                  >
                    Create Automated Segment
                  </Button>
                ) : (
                  <Button
                    size="md"
                    type="submit"
                    variant="primary-teal"
                    isLoading={isLoadingUpdate}
                  >
                    Update Automated Segment
                  </Button>
                )}
              </ButtonGroup>
            </Flex>
          </chakra.form>
        </SimpleGrid>
      </Box>

      {mode === "edit" && segmentUploads && (
        <AutomatedProgrammaticSegmentUploads segmentUploads={segmentUploads} />
      )}
    </ViewContainer>
  );
};

export { AutomatedProgrammaticSegmentForm };
