import { DateRange, Option, SplitBy } from "@intentsify/types";
import { atom, selector, useResetRecoilState } from "recoil";
import { ViewEnum } from "screens/IntentAnalysis/screens/CampaignsTrendingDomains/CampaignsTrendingDomains.types";
import { TrendingTopicsOptions } from "screens/IntentAnalysis/screens/CampaignsTrendingSignals/CampaignsTrendingSignals.types";

export const trendingTopicsDateRangeAtom = atom<DateRange>({
  key: `trendingTopicsDateRangeAtom`,
  default: DateRange.LastFourWeeks,
});

export const topicsSplitByAtom = atom<SplitBy>({
  key: `topicsSplitByAtom`,
  default: SplitBy.OneWeek,
});

export const topicsViewAtom = atom<ViewEnum>({
  key: `topicsViewAtom`,
  default: ViewEnum.TimeSeries,
});

export const selectedTrendingTopicsAtom = atom<{
  campaignId: string | undefined;
  data: Array<Option<string>>;
}>({
  key: `selectedTrendingTopicsAtom`,
  default: {
    campaignId: undefined,
    data: [],
  },
});

export const trendingTopicsOptionsStateAtom = atom<TrendingTopicsOptions>({
  key: `trendingTopicsOptionsStateAtom`,
  default: {
    isLoading: true,
    topics: [],
  },
});

export const trendingTopicsStateSelector = selector({
  key: "trendingTopicsStateSelector",
  get: ({ get }) => {
    const { isLoading, topics } = get(trendingTopicsOptionsStateAtom);

    const selectedDateRange = get(trendingTopicsDateRangeAtom);
    const selectedSplitBy = get(topicsSplitByAtom);
    const selectedView = get(topicsViewAtom);
    const selectedTopics = get(selectedTrendingTopicsAtom);

    return {
      isLoading,
      topicsOptions: topics,
      selectedDateRange,
      selectedSplitBy,
      selectedView,
      selectedTopics,
    };
  },
});

export const useResetTrendingTopicsState = () => {
  const list = [
    useResetRecoilState(trendingTopicsOptionsStateAtom),
    useResetRecoilState(trendingTopicsDateRangeAtom),
    useResetRecoilState(topicsSplitByAtom),
    useResetRecoilState(topicsViewAtom),
    useResetRecoilState(selectedTrendingTopicsAtom),
  ];

  return () => {
    list.forEach((f) => f());
  };
};
