import {
  Alert,
  Box,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { TalToolsTypes } from "@intentsify/types";
import { isBasicValidDomain, pluralize } from "@intentsify/utils";
import {
  ValueValidationError,
  buildZodCsvValidator,
} from "@intentsify/utils/csvStream/browser";
import { Upload } from "components";
import { z } from "zod";
import { useTalToolDropzone } from "../../hooks/useTalToolDropzone";
import { SmarTALForm } from "./SmarTALForm";

const csvValidator = () =>
  buildZodCsvValidator(
    z.object({
      Domain: z
        .string()
        .refine(
          (value) => isBasicValidDomain(value),
          "Invalid domain"
        ) as unknown as z.ZodString,
    }),
    {
      quoteMode: "none",
      caseInsensitiveHeader: true,
      validationMode: "validateAll",
    }
  );

const MAX_DOMAINS_COUNT = 200;
export const DEFAULT_LOOKALIKE_DOMAINS = 500;
export const MAX_LOOKALIKE_DOMAINS = 1000;

const resumeUploadConfirm = {
  title: "SmartTAL uploading",
  description: `We detected that uploading SmartTAL was interrupted. Would you like to resume uploading?`,
  confirmText: "Continue",
};

const csvErrorConfirmBuilder = ({
  hasMoreErrorsThanTheLimit,
  totalErrorsCount,
  errorSamples,
}: {
  hasMoreErrorsThanTheLimit: boolean;
  totalErrorsCount: number;
  errorSamples: ValueValidationError[];
}) => ({
  title: "Company Name to Domain Validation",
  description: (
    <>
      <Box>
        We detected{" "}
        {pluralize(totalErrorsCount, "1 error", `${totalErrorsCount} errors`)}{" "}
        in CSV you uploaded. You can ignore and continue, however we may not be
        able to append data with errors.
      </Box>
      <Box mt={2}>
        {hasMoreErrorsThanTheLimit ? "Top " : ""}
        {pluralize(totalErrorsCount, "1 error", `${totalErrorsCount} errors`)}:
      </Box>
      <Box maxH={150} overflowY="auto">
        <Table size="sm">
          <Thead position="sticky">
            <Tr>
              <Th>CSV Row</Th>
              <Th>Value</Th>
            </Tr>
          </Thead>
          <Tbody>
            {errorSamples.map((err, index) => (
              <Tr key={index}>
                <Td>{err.rowPos}</Td>
                <Td px={0}>
                  {err.value || <Text opacity={0.6}>(NO VALUE)</Text>}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </>
  ),
  confirmText: "Ignore & Continue",
});

const SmarTAL = () => {
  const {
    getRootProps,
    getInputProps,
    isTooManyFiles,
    lastUploadedFile,
    validationProgress,
  } = useTalToolDropzone(TalToolsTypes.SMARTAL, {
    resumeUploadConfirm,
    csvErrorConfirmBuilder,
    csvValidator,
  });

  return (
    <Flex direction="column" gap="4">
      <Box>
        <Heading as="h2" size="sm" my="4">
          Instructions
        </Heading>
        <Box as="ol" fontSize="xs" ml="8">
          <li>
            Upload a CSV file with one column containing domains and a header
            row with single value “Domain”.{" "}
            <b>You can upload a maximum of {MAX_DOMAINS_COUNT} domains.</b>
          </li>
          <li>
            Adjust the maximum #of lookalike domains to be returned. The default
            is {DEFAULT_LOOKALIKE_DOMAINS}, and maximum is{" "}
            {MAX_LOOKALIKE_DOMAINS}.
          </li>
          <li>Click "Process SmartTAL"</li>
          <li>
            You'll receive an email when the list has been processed containing
            the link to download.
          </li>
        </Box>

        <Heading as="h2" size="sm" my="4">
          Best Practices
        </Heading>
        <Box as="ul" fontSize="xs" ml="8">
          <li>
            Seed domains should be similar to each other. We recommend keeping
            seed domains within the same or similar industries. If they span
            different industries, best results are achieved by splitting them
            into 2 requests.
          </li>
          <li>
            For optimal results, upload seed domains that have previously
            demonstrated high success for the customer.
          </li>
        </Box>

        <Box mt={4}>
          <Upload
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            subHeader={"CSV with domains."}
            validationProgress={validationProgress}
          />
        </Box>

        {isTooManyFiles && (
          <Box mb={2} mt={4}>
            <Alert status="error">
              Too many files uploaded. Please remove some files before
              uploading, maximum 25 files allowed.
            </Alert>
          </Box>
        )}
      </Box>

      <SmarTALForm lastUploadedFile={lastUploadedFile} />
    </Flex>
  );
};

export { SmarTAL };
