import { Button, useDisclosure } from "@chakra-ui/react";
import { LeadSignalRow } from "@intentsify/types";
import { isDefined } from "@intentsify/utils";
import { ColumnDef, Row, Table } from "@tanstack/react-table";
import { EMPTY_CELL, IndeterminateCheckbox } from "components";
import { useParams } from "react-router";
import { useCampaign } from "screens/Campaigns/screens/CampaignsWizard/CampaignsWizard.hooks";
import { LeadActivationModal } from "./LeadActivationSummaryModal";

export const useLeadsColumns = (): Array<ColumnDef<LeadSignalRow>> => {
  const campaignId = Number(useParams<"campaignId">().campaignId);
  const campaign = useCampaign({ campaignId });
  const areLeadActivationSummariesEnabled =
    campaign?.campaignAdvancedSettings.areLeadActivationSummariesEnabled;

  const columns: Array<ColumnDef<LeadSignalRow>> = [
    areLeadActivationSummariesEnabled
      ? {
          id: "select",
          header: ({ table }: { table: Table<LeadSignalRow> }) => (
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          ),
          cell: ({ row }: { row: Row<LeadSignalRow> }) => (
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          ),
        }
      : undefined,
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Locations",
      accessorKey: "location",
      cell: ({ row }: { row: Row<LeadSignalRow> }) => {
        return row.original?.location.filter((l) => l).join(" | ");
      },
    },
    areLeadActivationSummariesEnabled
      ? {
          header: "Lead Activation Summary",
          enableSorting: false,
          cell: ({ row }: { row: Row<LeadSignalRow> }) => {
            if (!row.original.leadId || !row.original.leadNote) {
              return EMPTY_CELL;
            }

            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { isOpen, onOpen, onClose } = useDisclosure();

            return (
              <>
                <Button size="sm" onClick={onOpen}>
                  View Summary
                </Button>

                <LeadActivationModal
                  isOpen={isOpen}
                  onClose={onClose}
                  location={row.original.location}
                  jobTitle={row.original.name}
                  leadActivationSummary={row.original.leadNote}
                />
              </>
            );
          },
        }
      : undefined,
  ].filter(isDefined);

  return columns;
};
