import { Box, useOutsideClick } from "@chakra-ui/react";
import {
  AuthenticatedUser,
  RoomEventType,
  SocketRoom,
} from "@intentsify/types";
import { Popover } from "components";
import { useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import { useAppStore } from "store/useAppStore";
import { useRoomEvent } from "webSocket";
import {
  useNotificationTypes,
  useReadNotifications,
  useUnreadNotifications,
} from "./Notifications.hooks";
import {
  unreadNotificationsState,
  useResetOnClose,
} from "./Notifications.state";
import { NotificationsCounter } from "./components/NotificationCounter/NotificationCounter";
import { NotificationIcon } from "./components/NotificationIcon/NotificationIcon";
import { NotificationsOverlay } from "./components/NotificationsOverlay/NotificationsOverlay";

const Notifications = () => {
  const impersonation = useAppStore.use.impersonation();
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  const reset = useResetOnClose();

  useUnreadNotifications();
  useReadNotifications();

  useOutsideClick({
    ref: wrapperRef,
    handler: () => {
      if (open) {
        setOpen(false);
        // Slight delay to let the closing animation finish
        setTimeout(() => {
          reset();
        }, 100);
      }
    },
  });

  return (
    <Box
      ref={wrapperRef}
      style={{
        display: "inline-block",
        position: "relative",
      }}
    >
      <Popover
        isOpen={open}
        width="450px"
        placement="bottom-end"
        popoverTrigger={
          <Box>
            <NotificationIcon onClick={() => setOpen(true)} />{" "}
          </Box>
        }
        popoverBody={
          <Box>
            <NotificationsOverlay />
          </Box>
        }
      />

      {!impersonation && <NotificationsCounter onClick={() => setOpen(true)} />}
    </Box>
  );
};

/**
 * Use a wrapper so `useRoomEvent` is not affected by unecessary re-renders.
 */
const Wrapper = () => {
  const user = useAppStore.use.user() as AuthenticatedUser;
  const setUnreadNotifications = useSetRecoilState(unreadNotificationsState);
  const notificationTypes = useNotificationTypes();

  useRoomEvent(
    `${SocketRoom.USER_NOTIFICATIONS}_${user.userId}`,
    RoomEventType.NOTIFICATION,
    (event) => {
      const filtered = event.data.notifications.filter((i) =>
        notificationTypes.includes(i.type)
      );

      setUnreadNotifications((prev) => [...prev, ...filtered]);
    }
  );

  return <Notifications />;
};

export { Wrapper as Notifications };
