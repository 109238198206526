import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { FiFilter } from "react-icons/fi";
import { WithChildren } from "types";

type FiltersContainerProps = WithChildren<{
  items: FiltersContainerItem[];
  label?: string;
  isExpandable?: boolean;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  withMarginBottom?: boolean;
}>;

type FiltersContainerItemSize = "lg" | "xl";

export type FiltersContainerItem = {
  component: React.ReactNode;
  size?: FiltersContainerItemSize;
};

const FiltersContainer = ({
  items,
  label,
  header,
  footer,
  isExpandable = false,
  withMarginBottom = true,
}: FiltersContainerProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const filters = (
    <Box>
      <Grid
        templateColumns={`repeat(auto-fill, minmax(250px, 1fr))`}
        {...(withMarginBottom ? { mb: 4 } : {})}
      >
        {items.map(({ component, size }, index) => (
          <GridItem
            mb={2}
            mr={1}
            p={2}
            key={index}
            colSpan={{ base: 1, md: size === "xl" ? 2 : 1 }}
            minW="250px"
          >
            {component}
          </GridItem>
        ))}
      </Grid>
    </Box>
  );

  return isExpandable ? (
    <Accordion
      allowToggle
      onChange={(expandedIndex) => {
        const isExpanded = expandedIndex === 0;
        setIsExpanded(isExpanded);
      }}
    >
      <AccordionItem>
        <AccordionButton>
          <Flex alignItems="center" gap={2}>
            <Icon as={FiFilter} />
            {label ? (
              <Text fontWeight="medium" pr={2}>
                {label}
              </Text>
            ) : (
              <Text>{isExpanded ? "Collapse" : "Expand"} filters</Text>
            )}
            <AccordionIcon />
          </Flex>
        </AccordionButton>
        <AccordionPanel py={4}>
          {header}
          {filters}
          {footer}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  ) : (
    <>
      {filters}
      {footer}
    </>
  );
};

export { FiltersContainer };
