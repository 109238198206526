import { Box, chakra, Flex, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import {
  Option,
  ProgrammaticCampaignCreativesEngagements,
  SortDirection,
} from "@intentsify/types";
import { RowSelectionState, SortingState } from "@tanstack/react-table";
import { ParentSize } from "@visx/responsive";
import { useQuery } from "@tanstack/react-query";
import orderBy from "lodash/orderBy";
import { getCreativeWithHighestEngagement } from "./CreativeWithHighestEngagement.requests";
import { getColumns } from "./getColumns";
import { Status, useScript } from "utils";
import { Card, Creative, Select, TableVirtualised } from "components";
import { DisplayPerformanceFilters } from "screens/CampaignPacing/screens/DisplayPerformance/DisplayPerformance";
import { colors } from "theme";

export type BasedOnValue = "conversions" | "ctr";

const basedOnOptions: Option<BasedOnValue>[] = [
  { value: "ctr", label: "CTR" },
  { value: "conversions", label: "Conversions" },
];

type CreativeWithHighestEngagementProps = {
  campaignId: number;
  filters: DisplayPerformanceFilters;
};

const getDefaultSorting = (
  val?: BasedOnValue
): { id: keyof ProgrammaticCampaignCreativesEngagements; desc: boolean } => {
  switch (true) {
    case val === "ctr":
      return { id: "ctr", desc: true };
    case val === "conversions":
    default:
      return { id: "conversions", desc: true };
  }
};

const CreativeWithHighestEngagement = ({
  campaignId,
  filters,
}: CreativeWithHighestEngagementProps) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({
    0: true,
  });
  const [defaultSortByColumn, setDefaultSortByColumn] =
    useState<keyof ProgrammaticCampaignCreativesEngagements>("conversions");

  const [baseOnSelectValue, setBasedOnSelectValue] = useState<
    Option<BasedOnValue>
  >(basedOnOptions[0]);

  const [sortBy, setSortBy] = useState<SortingState>([getDefaultSorting()]);

  const { data: rawData, isFetching } = useQuery(
    ["getCreativeWithHighestEngagement", campaignId, filters],
    () => getCreativeWithHighestEngagement(campaignId, filters)
  );

  const data = useMemo(() => {
    if (!rawData?.creativesEngagements) {
      return [];
    }

    const [sort] = sortBy;

    if (sort) {
      return orderBy(
        rawData?.creativesEngagements,
        [sort.id],
        sort.desc ? "desc" : "asc"
      );
    }

    return rawData.creativesEngagements;
  }, [rawData, sortBy]);

  useEffect(() => {
    setRowSelection({ 0: true });

    const sortBy = getDefaultSorting(baseOnSelectValue.value);
    setDefaultSortByColumn(sortBy.id);

    setSortBy([sortBy]);
  }, [campaignId, baseOnSelectValue.value]);

  const selectedItem = useMemo(() => {
    const index = Object.keys(rowSelection)[0];

    if (data[Number(index)]) {
      return {
        data: data[Number(index)],
        rowNumber: Number(index) + 1,
      };
    }

    return undefined;
  }, [rowSelection, data]);

  const basedOnColumns = useMemo(() => {
    return getColumns(baseOnSelectValue.value);
  }, [baseOnSelectValue]);

  const textColor = useColorModeValue("gray", colors.gray[300]);

  const hasAnyConversions = useMemo(() => {
    return data.some((i) => i.conversions && i.conversions > 0);
  }, [data]);

  const status = useScript("//imasdk.googleapis.com/js/sdkloader/ima3.js");

  if (status !== Status.ready) {
    return null;
  }

  return (
    <Card
      title={"Creative With Highest Engagement"}
      isExpandable
      isLoading={isFetching}
    >
      {(isExpanded) => (
        <Flex flexDir="column" h="100%">
          <Flex mb={2}>
            <Flex alignItems="center">
              <Flex mr={2} color={textColor} fontSize="sm">
                Based on
              </Flex>
              <Box minWidth={300}>
                <Select
                  isClearable={false}
                  isMulti={false}
                  value={baseOnSelectValue}
                  options={basedOnOptions}
                  onChange={(val) => {
                    if (val) {
                      setBasedOnSelectValue(val);
                    }
                  }}
                />
              </Box>
            </Flex>
          </Flex>

          <Flex gap="4" flexGrow={1} flexDir={isExpanded ? "column" : "row"}>
            {!hasAnyConversions && baseOnSelectValue.value === "conversions" ? (
              <Flex
                w="100%"
                h="100%"
                justifyContent="center"
                alignItems="center"
                minH="250px"
              >
                There are no conversions at the moment.
              </Flex>
            ) : (
              <>
                <Flex
                  w={isExpanded ? "100%" : "60%"}
                  h={isExpanded ? "360px" : undefined}
                >
                  <ParentSize>
                    {(parent) => {
                      const { width, height } = parent;

                      return (
                        <Flex w={width} h={height}>
                          <TableVirtualised<ProgrammaticCampaignCreativesEngagements>
                            defaultSortByColumn={defaultSortByColumn}
                            defaultSortDirection={SortDirection.DESC}
                            onSortingChange={setSortBy}
                            rowSelection={rowSelection}
                            setRowSelection={setRowSelection}
                            selectable="clickable-single-select"
                            variant="corporateCompact"
                            data={data.slice(0, 10)}
                            columns={basedOnColumns}
                            containerProps={{
                              width,
                              maxHeight: height,
                            }}
                            showRowNumbers
                          />
                        </Flex>
                      );
                    }}
                  </ParentSize>
                </Flex>

                <Flex w={isExpanded ? "100%" : "40%"}>
                  {selectedItem && (
                    <chakra.figure
                      h="100%"
                      w="100%"
                      display="flex"
                      flexDir="column"
                      overflow="hidden"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <ParentSize>
                        {(parent) => {
                          const { width, height } = parent;

                          return (
                            <Flex
                              justifyContent="center"
                              alignItems="center"
                              h="100%"
                              w="100%"
                              minH={isExpanded ? "500px" : undefined}
                            >
                              <div>
                                <Creative
                                  creativeUrl={selectedItem.data.creativeUrl}
                                  showIframe={selectedItem.data.showIframe}
                                  containerProps={{ width, height }}
                                  isVideoCreative={
                                    selectedItem.data.isVideoCreative
                                  }
                                />
                              </div>
                            </Flex>
                          );
                        }}
                      </ParentSize>

                      <chakra.figcaption
                        mt={2}
                        display="flex"
                        fontSize="sm"
                        justifyContent="center"
                      >
                        <b>{selectedItem.rowNumber}.</b>
                        &nbsp;
                        <chakra.span
                          fontSize="xs"
                          color="gray"
                          w="100%"
                          maxW={!isExpanded ? "175px" : undefined}
                        >
                          {selectedItem.data.creativeName}
                        </chakra.span>
                      </chakra.figcaption>
                    </chakra.figure>
                  )}
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      )}
    </Card>
  );
};

export { CreativeWithHighestEngagement };
