import { Box, Flex } from "@chakra-ui/react";
import { DarkMode, LightMode, useColorMode } from "@chakra-ui/system";
import { getExportFileName, isPopulatedArray } from "@intentsify/utils";
import { Button, IconButton, Popover, Tooltip } from "components";
import mapKeys from "lodash/mapKeys";
import upperFirst from "lodash/upperFirst";
import * as Papa from "papaparse";
import { useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { useDeepEffect } from "utils";
import { EXPORT_RENDER_DELAY } from "./useExportableChart";

type ChartExportProps = {
  onExportPNG: () => void;
  data: Array<Record<any, any>> | undefined;
  campaignId: number;
  title: string;
  dates?: {
    start: string;
    end: string;
  };
  size?: "normal" | "small";
  disableCSVExport?: boolean;
};

const ChartExport = ({
  onExportPNG,
  data = [],
  title,
  campaignId,
  dates,
  size = "normal",
  disableCSVExport = false,
}: ChartExportProps) => {
  const { colorMode } = useColorMode();
  const [isOpen, setIsOpen] = useState(false);
  const [exporting, setIsExporting] = useState(false);

  const isDisabled = !isPopulatedArray(data);

  const [downloadLink, setDownloadLink] = useState("");

  useDeepEffect(() => {
    const dataWithUppercaseKeys = data.map((item) =>
      mapKeys(item, (_, key) => upperFirst(key))
    );

    const csv = Papa.unparse(dataWithUppercaseKeys);
    const blob = new Blob([csv], { type: "text/plain" });
    setDownloadLink(window.URL.createObjectURL(blob));
  }, [data]);

  const fileNamePNG = getExportFileName({
    prefix: `${title.toLocaleLowerCase().replace(/ /g, "_")}`,
    data: {
      id: campaignId,
    },
    dates,
    extension: ".png",
  });

  const fileNameCSV = getExportFileName({
    prefix: `${title.toLocaleLowerCase().replace(/ /g, "_")}`,
    data: {
      id: Number(campaignId),
    },
    dates,
    extension: ".csv",
  });

  return (
    <>
      <Popover
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        width="75px"
        popoverTrigger={
          <Box>
            <IconButton
              variant="solid"
              isDisabled={isDisabled}
              tooltip="Export"
              icon={<AiOutlineDownload />}
              size={size === "small" ? "xs" : "sm"}
              onClick={() => setIsOpen(true)}
            />
          </Box>
        }
        placement="bottom-end"
        popoverBody={
          <>
            {!isDisabled && (
              <Flex flexDir="column" p={1}>
                {colorMode === "light" ? (
                  <LightMode>
                    <Tooltip
                      placement="left"
                      w={"100%"}
                      shouldWrapChildren
                      aria-label="Export as PNG info"
                      label={
                        <>
                          {fileNamePNG}
                          <Box mt={2}>
                            When downloading transparent PNG, select "light
                            mode" or "dark mode" in your profile settings for
                            preferred font and axis colors.
                          </Box>
                        </>
                      }
                    >
                      <DarkMode>
                        <Button
                          fullWidth
                          isLoading={exporting}
                          onClick={() => {
                            onExportPNG();
                            setIsExporting(true);
                            setTimeout(() => {
                              setIsExporting(false);
                              setIsOpen(false);
                            }, EXPORT_RENDER_DELAY);
                          }}
                        >
                          PNG
                        </Button>
                      </DarkMode>
                    </Tooltip>

                    {!disableCSVExport && (
                      <Tooltip
                        placement="left"
                        w={"100%"}
                        shouldWrapChildren
                        aria-label="Export as CSV"
                        label={fileNameCSV}
                      >
                        <DarkMode>
                          <Button
                            mt={1}
                            as="a"
                            download={fileNameCSV}
                            fullWidth
                            onClick={() => setIsOpen(false)}
                            href={downloadLink}
                          >
                            CSV
                          </Button>
                        </DarkMode>
                      </Tooltip>
                    )}
                  </LightMode>
                ) : (
                  <DarkMode>
                    <Tooltip
                      placement="left"
                      w={"100%"}
                      shouldWrapChildren
                      aria-label="Export as PNG"
                      label={fileNamePNG}
                    >
                      <Button fullWidth onClick={onExportPNG}>
                        PNG
                      </Button>
                    </Tooltip>

                    {!disableCSVExport && (
                      <Tooltip
                        placement="left"
                        w={"100%"}
                        shouldWrapChildren
                        aria-label="Export as CSV"
                        label={fileNameCSV}
                      >
                        <Button
                          mt={1}
                          as="a"
                          download={fileNameCSV}
                          fullWidth
                          onClick={() => undefined}
                          href={downloadLink}
                        >
                          CSV
                        </Button>
                      </Tooltip>
                    )}
                  </DarkMode>
                )}
              </Flex>
            )}
          </>
        }
      />
    </>
  );
};

export { ChartExport };
