import { Flex } from "@chakra-ui/react";
import { ListCampaignsDTO } from "@intentsify/dto";
import { CampaignForList, Option } from "@intentsify/types";
import {
  defaultListPaginatedCampaignsParams,
  listPaginatedCampaigns,
} from "api";
import { FilterAsyncTokenBased } from "components";
import { FetchDataParamsWithToken } from "types";

export type SelectedCampaign = Option<number> & {
  createdAt?: string;
  campaignEndDate?: string;
  campaignStatusId?: number;
};

type CampaignSelectorProps = {
  isMulti?: boolean;
  selected?: SelectedCampaign;
  blockOnChangeIfValueIsNotPresent?: boolean;
  showOnlyCurrentUserCompanyCampaigns?: boolean;
  onChange: (selection: SelectedCampaign | undefined) => void;
  // TODO: remove this
  w?: string;
  placeholder?: string;
  params?: Partial<ListCampaignsDTO>;
};

const listCampaigns =
  () =>
  async (
    params: FetchDataParamsWithToken<keyof CampaignForList, undefined> & {
      pageSize?: number;
      showOnlyCurrentUserCompanyCampaigns?: boolean;
    }
  ) => {
    const { campaigns, nextPageToken } = await listPaginatedCampaigns(params);

    return {
      nextPageToken,
      results: campaigns.map((c) => ({
        value: c.campaignId,
        label: c.displayName,
        createdAt: c.createdAt,
        campaignEndDate: c.endAt,
        campaignStatusId: c.campaignStatusId,
      })),
    };
  };

const CampaignSelector = ({
  isMulti = false,
  // hate it
  blockOnChangeIfValueIsNotPresent = false,
  showOnlyCurrentUserCompanyCampaigns = false,
  selected,
  onChange,
  w = "400px",
  placeholder = "Select an intent model",
  params,
}: CampaignSelectorProps) => {
  return (
    <Flex w={w} flexDir={"column"} justify="space-between">
      <FilterAsyncTokenBased
        key={selected?.value}
        testId="campaign-selector"
        isMulti={isMulti}
        params={{
          ...defaultListPaginatedCampaignsParams,
          ...params,
          showOnlyCurrentUserCompanyCampaigns,
        }}
        currentValue={selected}
        defaultOptions={[]}
        dataRequest={listCampaigns()}
        label="Intent Model"
        showLabel={false}
        placeholder={placeholder}
        onFilterValuesChange={(val) => {
          if (blockOnChangeIfValueIsNotPresent) {
            if (val?.value === selected?.value) {
              return;
            }
          }

          onChange(val);
        }}
      />
    </Flex>
  );
};

export { CampaignSelector };
