import { InfoIcon } from "@chakra-ui/icons";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Tooltip } from "components";
import { DynamicInputNext } from "components/DynamicInputNext/DynamicInput";
import { useForm } from "react-hook-form";
import { useComponentColors } from "theme";
import { z } from "zod";

const validationSchema = z.object({
  email: z.string().email(),
});

const BuyingGroupContactsDelivery = ({
  recipients,
  onRecipientsChange,
  isEnabled,
  onIsEnabledChange,
}: {
  recipients: string[];
  onRecipientsChange: (emails: string[]) => void;
  isEnabled: boolean;
  onIsEnabledChange: (isChecked: boolean) => void;
}) => {
  const componentColors = useComponentColors();

  const {
    formState: { errors, isValid },
    register,
    watch,
    resetField,
  } = useForm<z.infer<typeof validationSchema>>({
    mode: "all",
    resolver: zodResolver(validationSchema),
    defaultValues: {
      email: "",
    },
  });

  const emailInputValue = watch("email");

  const onSubmit = () => {
    if (isValid) {
      resetField("email");
      onRecipientsChange([...new Set([...recipients, emailInputValue])]);
    }
  };

  return (
    <Stack spacing={2}>
      <Text
        fontSize="md"
        fontWeight="bold"
        color={componentColors.form.formLabelColor}
      >
        Buying Group Contacts
      </Text>

      <Flex alignItems="center">
        <Text color={componentColors.form.formLabelColor}>
          Automated Buying Group Contact Delivery
          <Tooltip
            aria-label={"automated-intent-delivery-tooltip"}
            placement={"top-end"}
            label={
              <>
                This automated email delivery system is for any customer/user
                that is interested in receiving our intent intelligence for
                accounts that have garnered leads during their campaign. Please
                toggle “on” and input an email address below to begin the weekly
                cadence email service.
              </>
            }
          >
            <InfoIcon w={4} h={4} verticalAlign={"center"} pl={1} />
          </Tooltip>
        </Text>

        <Switch
          ml={4}
          size="sm"
          onChange={() => onIsEnabledChange(!isEnabled)}
          isChecked={isEnabled}
        />
      </Flex>

      <DynamicInputNext.Root alignItems={"flex-start"}>
        <DynamicInputNext.Body>
          <Text
            p="0"
            m="0"
            fontSize="sm"
            fontWeight="md"
            color={componentColors.form.formLabelColor}
          >
            Buying Group Delivery Recipients
          </Text>
          <InputGroup size="md">
            <FormControl
              isInvalid={!!errors?.email?.message && emailInputValue.length > 0}
            >
              <Input
                {...register("email")}
                mt={1}
                placeholder="Email address"
                shadow="sm"
                size="sm"
                rounded="md"
                isDisabled={!isEnabled}
                autoComplete="off"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
              />
              <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
            </FormControl>
            <InputRightElement>
              <Button
                onClick={onSubmit}
                isDisabled={
                  !!errors?.email?.message || emailInputValue.length < 1
                }
                size="sm"
              >
                Add
              </Button>
            </InputRightElement>
          </InputGroup>
        </DynamicInputNext.Body>
        {isEnabled && (
          <DynamicInputNext.Tag
            tags={recipients}
            onRemoveClick={(email) =>
              onRecipientsChange(recipients.filter((i) => i !== email))
            }
          />
        )}
      </DynamicInputNext.Root>
    </Stack>
  );
};

export { BuyingGroupContactsDelivery };
