import { Box, Divider, Flex } from "@chakra-ui/layout";
import {
  FormControl,
  FormErrorMessage,
  Heading,
  RadioGroup,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { TalToolsTypes } from "@intentsify/types";
import { Button, Select } from "components";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useComponentColors } from "theme";
import { z } from "zod";
import { talToolsUpload } from "../../../../../../../upload/talToolsUpload";
import { useTalPreparationToolsFiles } from "../../../hooks/useTalPreparationToolsFiles";
import { useFirmoAndTechnoAppend } from "../hooks/useFirmoAndTechnoAppend";
import { firmoOptions } from "./FirmoTechnoAppend.options";
import { FirmoTechnoAppendFiles } from "./FirmoTechnoAppendFiles";
import { TechnographicProducts } from "./TechnographicProducts";

const firmoAndTechnoAppendSchema = z
  .object({
    fileName: z.string().nonempty(),
    firmo: z.array(z.string()),
    techno: z
      .array(
        z.object({
          id: z.number(),
          name: z.string(),
          value: z.string(),
        })
      )
      .max(10),
  })
  .refine((value) => value.firmo.length > 0 || value.techno.length > 0, {
    message:
      "At least one firmographic or technographic filter must be selected.",
  });

type FirmoTechnoAppendFormProps = {
  lastUploadedFile: string;
};

export const FirmoTechnoAppendForm = ({
  lastUploadedFile,
}: FirmoTechnoAppendFormProps) => {
  const componentColors = useComponentColors();
  const tusFiles = talToolsUpload.useFiles();
  const { mutateAsync: appendFirmoAndTechno, isLoading: isSubmitting } =
    useFirmoAndTechnoAppend();
  const { data: files, isFetching: isLoadingFiles } =
    useTalPreparationToolsFiles(TalToolsTypes.FIRMO_AND_TECHNO);

  const { control, handleSubmit, reset, formState, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      firmo: [],
      techno: [],
      fileName: "",
    },
    resolver: zodResolver(firmoAndTechnoAppendSchema),
  });

  const uploadingFiles = tusFiles.filter(
    ({ status }) => status === "uploading"
  );

  const onSubmitHandler = handleSubmit(async (data) => {
    await appendFirmoAndTechno({
      fileName: data.fileName,
      firmos: data.firmo,
      technos: data.techno,
    });
    reset();
  }) as () => void;

  useEffect(() => {
    if (lastUploadedFile) {
      setValue("fileName", lastUploadedFile);
    }
  }, [lastUploadedFile, setValue]);

  return (
    <Box as="form" onSubmit={onSubmitHandler}>
      <Box>
        <Heading as="h3" size="sm" my="4">
          Uploaded Files
        </Heading>
        {Boolean(isLoadingFiles || uploadingFiles.length) && (
          <Spinner
            thickness="4px"
            speed="0.75s"
            size="md"
            {...componentColors.spinner}
          />
        )}
        {!isLoadingFiles && !files?.length && (
          <Text fontSize="sm">Uploaded files will appear here.</Text>
        )}
        {Boolean(files?.length) && (
          <Controller
            name="fileName"
            control={control}
            render={({ field }) => (
              <RadioGroup
                onChange={(e) => {
                  field.onBlur();
                  field.onChange(e);
                }}
                value={field.value}
              >
                <FirmoTechnoAppendFiles
                  files={files ?? []}
                  onFileDelete={(deletedFileName) => {
                    if (deletedFileName === field.value) {
                      field.onChange("");
                    }
                  }}
                />
              </RadioGroup>
            )}
          />
        )}
      </Box>

      <Heading as="h3" size="sm" my="4">
        Firmographic Append Filters
      </Heading>
      <Box w={320} mb={2}>
        <Controller
          name="firmo"
          control={control}
          render={({ field }) => (
            <Select
              options={firmoOptions}
              isMulti
              value={firmoOptions.filter((option) =>
                field.value.some((value) => option.value.includes(value))
              )}
              onChange={(options) =>
                field.onChange(options.map((option) => option.value))
              }
              onBlur={field.onBlur}
            />
          )}
        />
      </Box>
      <Heading as="h3" size="sm" my="4">
        Technographic Append Filters
      </Heading>
      <Flex>
        <Box w={320}>
          <Controller
            name="techno"
            control={control}
            render={({ field, fieldState: { error, invalid } }) => (
              <FormControl isInvalid={invalid}>
                <TechnographicProducts
                  value={field.value}
                  onChange={(value) => {
                    field.onBlur();
                    field.onChange(value);
                  }}
                />
                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </Box>
      </Flex>

      <Divider mt={4} />

      <Flex mt={4} justifyContent="flex-end">
        <Button
          size="md"
          variant="primary-teal"
          isLoading={isSubmitting}
          isDisabled={!formState.isValid}
          type="submit"
        >
          Process Domains
        </Button>
      </Flex>
    </Box>
  );
};
