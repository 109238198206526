import { ProgrammaticCampaignEngagementBreakdownAttributionMethod } from "@intentsify/types";
import { isDefined } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { AiOutlineBarChart } from "react-icons/ai";
import { getEngagementBreakdown } from "./ClickThrough.requests";
import { Card, PieChart, MIN_MODAL_CONTENT_HEIGHT } from "components";
import { DisplayPerformanceFilters } from "screens/CampaignPacing/screens/DisplayPerformance";
import { NOT_A_CPA_CAMPAIGN_MESSAGE } from "screens/CampaignPacing/screens/DisplayPerformance/DisplayPerformance.utils";
import { ChartExport, useExportableChart } from "shared/components";

type ClickThroughProps = {
  campaignId: number;
  filters: DisplayPerformanceFilters;
  totalConversions: number;
};

const ClickThrough = ({
  campaignId,
  filters,
  totalConversions,
}: ClickThroughProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { data: engagementData, isFetching: isLoadingEngagementData } =
    useQuery(
      ["impressionsClicksPerformance-engagement", campaignId, filters],
      () => getEngagementBreakdown(campaignId, filters)
    );

  const sortedEngagementData = useMemo(() => {
    const mapped = (engagementData?.engagementBreakdown ?? []).map((i) => ({
      value: i.countConversions,
      label: i.attributionMethod,
    }));

    const order = [
      ProgrammaticCampaignEngagementBreakdownAttributionMethod.CLICK_THROUGH,
      ProgrammaticCampaignEngagementBreakdownAttributionMethod.VIEW_THROUGH,
      ProgrammaticCampaignEngagementBreakdownAttributionMethod.NOT_ATTRIBUTED,
    ];

    return order
      .map((i) => mapped.find((m) => m.label === i))
      .filter(isDefined);
  }, [engagementData?.engagementBreakdown]);

  const clickThroughChart = useExportableChart({
    title: "Click-Through Vs View-Through Conversions",
    campaignId: Number(campaignId),

    chart: (
      <PieChart
        minHeight={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : undefined}
        stretch
        isLoading={isLoadingEngagementData}
        legendPosition="bottom"
        data={sortedEngagementData}
        noDataMessage="No conversions to display."
      />
    ),
  });

  return (
    <Card
      onExpandedChange={setIsExpanded}
      title={clickThroughChart.title}
      isExpandable
      actions={
        <ChartExport
          size="small"
          campaignId={Number(campaignId)}
          title={clickThroughChart.title}
          onExportPNG={clickThroughChart.downloadAsPng}
          data={(engagementData?.engagementBreakdown ?? []).map((i) => ({
            value: i.countConversions,
            label: i.attributionMethod,
          }))}
        />
      }
      hasData={totalConversions !== 0}
      noDataIcon={AiOutlineBarChart}
      noDataMessage={NOT_A_CPA_CAMPAIGN_MESSAGE}
      isLoading={isLoadingEngagementData}
    >
      {clickThroughChart.component}
      {clickThroughChart.exportComponent}
    </Card>
  );
};

export { ClickThrough };
