import { useToast } from "@chakra-ui/react";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import { Campaign } from "@intentsify/types";
import { useQueryClient } from "@tanstack/react-query";
import { StatusModal, ViewContainer } from "components";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { MenuWithContentItem } from "types";
import { useScreen } from "utils";
import {
  haveDomainsSettingsChanged,
  haveKeywordsAndTopicsChanged,
  haveSettingsChanged,
} from "../YourCampaigns/YourCampaigns.utils";
import { useCampaign, useNavigateToCampaigns } from "./CampaignsWizard.hooks";
import {
  isCreateCampaignParams,
  isMutateCampaignSettingsParams,
} from "./CampaignsWizard.requests";
import { CreateCampaignScreenDefinition } from "./CreateCampaign.definition";
import {
  ActivationSettingsStep,
  DomainsStep,
  KeywordsAndTopicsStep,
  Steps,
  isSavingWizardAtom,
} from "./components";
import { BuyingGroupStep } from "./components/BuyingGroupStep";
import { haveCampaignsPersonasChanges } from "./components/BuyingGroupStep/BuyingGroupForm/BuyingGroupForm.hooks";
import { SettingsStep } from "./components/SettingsStep";

const CreateCampaign = () => {
  useScreen(CreateCampaignScreenDefinition);

  const { campaignId } = useParams<"campaignId">();
  const { step } = useParams<"step">();
  const navigate = useNavigate();
  const toast = useToast();
  const isSaving = useRecoilValue(isSavingWizardAtom);
  const navigateToCampaigns = useNavigateToCampaigns();
  const queryClient = useQueryClient();

  const {
    createCampaign,
    isLoadingCampaign,
    campaignSettings,
    campaignsDomains,
    campaignKeywordsAndTopics,
    campaignAdvancedSettings,
    updateCampaignSettings,
    updateDomainsSettings,
    updateCampaignKeywordsAndTopics,
    updateCampaignBuyingGroup,
    updateCampaignAdvancedSettings,
    campaignBuyingGroup,
  } = useCampaign({
    campaignId: Number(campaignId),
  });

  useEffect(() => {
    toast.closeAll();
  }, [step, toast]);

  const currentStep = Number(step);

  const nextStepHandler = (campaignId: number) =>
    navigate(
      CreateCampaignScreenDefinition.navigate({
        campaignId: Number(campaignId),
        step: currentStep + 1,
      })
    );

  const campaignCreatedHandler = useCallback(() => {
    if (!campaignId) {
      throw Error("missing campaign id");
    }

    const data = queryClient.getQueryData<Campaign>([
      "campaign",
      Number(campaignId),
    ]);

    toast.closeAll();
    toast({
      title: data?.displayName
        ? `Campaign "${data.displayName}" (${campaignId}) was created.`
        : `Campaign (${campaignId}) was created.`,
      status: "success",
      duration: 5000,
    });

    navigateToCampaigns();
  }, [campaignId, navigateToCampaigns, queryClient, toast]);

  const hasAccessToBuyingGroup = useHasAccessTo("targetPersona");

  const items: MenuWithContentItem[] = [
    {
      label: "Program Settings",
      content: (
        <SettingsStep
          editing={false}
          onSubmit={(params) => {
            if (isMutateCampaignSettingsParams(params)) {
              if (!haveSettingsChanged(campaignSettings, params.settings)) {
                nextStepHandler(Number(campaignId));
                return;
              }

              updateCampaignSettings({
                settings: params.settings,
                shouldExitOnSuccess: params.shouldExitOnSuccess,
                onSuccessCallback: () => nextStepHandler(params.settings.id),
              });
            }

            if (isCreateCampaignParams(params)) {
              createCampaign({
                settings: params.settings,
                onSuccessCallback: (campaignId: number) => {
                  nextStepHandler(campaignId);
                },
              });
              return;
            }
          }}
        />
      ),
    },
    {
      label: "Accounts",
      content: (
        <DomainsStep
          editing={false}
          onSubmit={({ haveFilesChanged, dto, shouldExitOnSuccess }) => {
            if (
              !haveDomainsSettingsChanged(
                campaignsDomains.firmographicsFilters,
                dto.firmographicsFilters,
                haveFilesChanged,
                campaignsDomains.domainEnhancementStrategy,
                dto.domainEnhancementStrategy
              )
            ) {
              nextStepHandler(Number(campaignId));
              return;
            }

            updateDomainsSettings({
              haveFilesChanged,
              dto,
              shouldExitOnSuccess,
              onSuccessCallback: () => nextStepHandler(Number(campaignId)),
            });
          }}
        />
      ),
    },
    ...(hasAccessToBuyingGroup
      ? [
          {
            label: "Buying Group",
            content: (
              <BuyingGroupStep
                companyId={campaignSettings.companyId}
                onSubmit={({ newSettings, shouldExitOnSuccess }) => {
                  if (
                    !haveCampaignsPersonasChanges(
                      campaignBuyingGroup,
                      newSettings.selectedPersonas,
                      newSettings.maxBuyerGroupSizePerAccount,
                      newSettings.buyingGroupContactsAccounts
                    )
                  ) {
                    nextStepHandler(Number(campaignId));
                    return;
                  }

                  updateCampaignBuyingGroup({
                    newSettings,
                    shouldExitOnSuccess,
                    onSuccessCallback: () => {
                      nextStepHandler(Number(campaignId));
                    },
                  });
                }}
              />
            ),
          },
        ]
      : []),
    {
      label: "Keywords & Topics",
      content: (
        <KeywordsAndTopicsStep
          editing={false}
          id={Number(campaignId)}
          campaignKeywordsAndTopics={campaignKeywordsAndTopics}
          onSubmit={({ newSettings, shouldExitOnSuccess }) => {
            if (
              !haveKeywordsAndTopicsChanged(
                campaignKeywordsAndTopics,
                newSettings
              )
            ) {
              nextStepHandler(Number(campaignId));
              return;
            }

            updateCampaignKeywordsAndTopics({
              newSettings,
              shouldExitOnSuccess,
              onSuccessCallback: () => nextStepHandler(Number(campaignId)),
            });
          }}
        />
      ),
    },
    {
      label: "Activation Settings",
      content: (
        <ActivationSettingsStep
          editing={false}
          id={Number(campaignId)}
          campaignAdvancedSettings={campaignAdvancedSettings}
          onSubmit={({ newSettings, shouldExitOnSuccess }) => {
            updateCampaignAdvancedSettings({
              newSettings,
              shouldExitOnSuccess,
              onSuccessCallback: () => {
                campaignCreatedHandler();
              },
            });
          }}
        />
      ),
    },
  ];

  return (
    <>
      <ViewContainer compact stretch noPadding>
        <Steps
          currentStep={currentStep}
          isLoading={isLoadingCampaign && Boolean(campaignId)}
          items={items}
        />
      </ViewContainer>

      <StatusModal status="Saving changes..." isOpen={isSaving} />
    </>
  );
};

export { CreateCampaign };
