import { Box, HStack, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { useComponentColors } from "theme";

const UploadedFiles = ({
  children,
  hideUploadedAt = true,
}: PropsWithChildren & { hideUploadedAt?: boolean }) => {
  const componentColors = useComponentColors();

  return (
    <>
      <Box h="100%" w="100%" alignItems="center" mb={2}>
        <HStack
          alignItems="center"
          mb={1}
          pb={1}
          w="100%"
          fontSize="xs"
          textTransform="uppercase"
          borderBottom="1px solid"
          borderColor={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
        >
          <Text w="300px" color={componentColors.form.formLabelColor}>
            FILE NAME
          </Text>

          <Text w="125px" color={componentColors.form.formLabelColor}>
            DOMAIN COUNT
          </Text>

          {!hideUploadedAt && (
            <Text w="175px" color={componentColors.form.formLabelColor}>
              UPLOADED AT
            </Text>
          )}
        </HStack>

        <VStack spacing={2} alignItems="flex-start">
          {children}
        </VStack>
      </Box>
    </>
  );
};

export { UploadedFiles };
