import { InfoIcon } from "@chakra-ui/icons";
import {
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  SimpleGrid,
  Spinner,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  BusinessUnit,
  CampaignSettings,
  CompanyType,
  Country,
  Node,
  Option,
} from "@intentsify/types";
import { isDefined } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import { listCompanyBusinessUnits } from "api";
import { DayPicker, Filter, Select, Tooltip, Tree, useTree } from "components";
import { DateTime } from "luxon";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { useComponentColors } from "theme";
import { useDeepEffect } from "utils";
import { z } from "zod";
import { NavigationBlockers } from "../../NavigationBlockers/NavigationBlockers";
import {
  getCampaignCountries,
  getCampaignManagers,
  getCorporateEntities,
  getCustomers,
  getRepresentativeNames,
} from "../SettingsStep.requests";
import { SettingsStepState } from "../SettingsStep.state";
import { CampaignSettingsInputs } from "../SettingsStep.types";
import { useWatchSettings } from "../SettingsStep.utils";

const customerTypeOptions = [
  {
    label: "Media",
    value: CompanyType.Media,
  },
  {
    label: "Subscription",
    value: CompanyType.Subscription,
  },
];

const validationSchemaZod = z
  .object({
    displayName: z
      .string({
        required_error: "Intent Model must be specified.",
        invalid_type_error: "Intent Model must be specified.",
      })
      .min(3, "Intent Model must be at least 3 characters long."),
    repId: z
      .number({
        required_error: "Sales Representative must be selected.",
        invalid_type_error: "Sales Representative must be selected.",
      })
      .positive({ message: "Sales Representative must be selected." }),
    customerSuccessManagerId: z
      .number({
        required_error: "Customer Success Manager must be selected.",
        invalid_type_error: "Customer Success Manager must be selected.",
      })
      .positive({ message: "Customer Success Manager must be selected." }),
    companyId: z
      .number({
        required_error: "Company must be selected 1.",
        // invalid_type_error: "Company must be selected 2.",
      })
      .positive({ message: "Company must be selected 3." }),
    corporateEntityId: z
      .number({
        required_error: "Corporate Entity must be selected.",
        invalid_type_error: "Corporate Entity must be selected.",
      })
      .positive({ message: "Corporate Entity must be selected." }),
    companyType: z
      .nativeEnum(CompanyType)
      .refine((companyType) => companyType !== CompanyType.Unspecified, {
        message: "Customer type must be selected.",
      }),
    startDate: z.string({
      required_error: "Start Date is required.",
      invalid_type_error: "Start Date is required.",
    }),
    endDate: z
      .string({
        required_error: "End Date is required.",
        invalid_type_error: "End Date is required.",
      })
      .nullable()
      .refine(
        (endDate) => {
          if (!endDate) {
            return null;
          }

          const tommorow = DateTime.fromJSDate(new Date())
            .plus({ day: 1 })
            .startOf("day");

          return DateTime.fromISO(endDate).startOf("day") >= tommorow;
        },
        { message: "End date should be at least the next day." }
      ),
    notes: z.string().optional(),
  })
  .refine(
    (data) => {
      if (!data.endDate) {
        return false;
      }

      const startDate = DateTime.fromISO(data.startDate);
      const endDate = DateTime.fromISO(data.endDate);

      return startDate.startOf("day") < endDate.startOf("day");
    },
    {
      message: "Start date should be set before the end date.",
      path: ["startDate"],
    }
  )
  .refine(
    (data) => {
      if (!data.endDate) {
        return false;
      }

      const startDate = DateTime.fromISO(data.startDate);
      const endDate = DateTime.fromISO(data.endDate);

      return endDate.startOf("day") > startDate.startOf("day");
    },
    {
      message: "End date should be set after the start date.",
      path: ["endDate"],
    }
  );

export type SubmitTrigger = {
  submit: ({ shouldExitOnSuccess }: { shouldExitOnSuccess: boolean }) => void;
};

type FormProps = {
  state?: CampaignSettings;
  onSubmit: (params: {
    settings: CampaignSettings;
    shouldExitOnSuccess: boolean;
  }) => void;
};

const SettingsForm = forwardRef<SubmitTrigger | undefined, FormProps>(
  ({ onSubmit, state }, ref) => {
    const componentColors = useComponentColors();

    const setLoading = useSetRecoilState(SettingsStepState.isLoadingAtom);

    const {
      handleSubmit,
      register,
      control,
      watch,
      formState: { errors },
    } = useForm<CampaignSettingsInputs>({
      mode: "onChange",
      resolver: zodResolver(validationSchemaZod),
      defaultValues: state
        ? {
            ...state,
            startDate: !state.startDate
              ? DateTime.now().toISODate()
              : state.startDate,
            endDate: state.endDate ?? null,
          }
        : undefined,
    });

    const companyId = watch("companyId");

    const { data: businessUnitsData, isLoading: isBusinessUnitsLoading } =
      useQuery(
        ["listCompanyBusinessUnits", Number(companyId)],
        () => listCompanyBusinessUnits(companyId),
        { enabled: Boolean(companyId) }
      );

    const [businessUnits, setBusinessUnits] = useState<Option[]>([]);
    const [selectedBusinessUnits, setSelectedBusinessUnits] = useState<
      Option[]
    >([]);

    useEffect(() => {
      setSelectedBusinessUnits([]);
    }, [companyId]);

    useDeepEffect(() => {
      if (!state) {
        return;
      }

      const businessUnitsAsOptions: Option[] =
        state.businessUnits?.map(({ businessUnitId, displayName }) => ({
          value: businessUnitId,
          label: displayName,
        })) ?? [];
      setSelectedBusinessUnits([...businessUnitsAsOptions]);
    }, [state?.businessUnits]);

    useDeepEffect(() => {
      const businessUnitsAsOptions: Option[] =
        businessUnitsData?.businessUnits?.map(
          ({ businessUnitId, displayName }) => ({
            value: businessUnitId,
            label: displayName,
          })
        ) ?? [];
      setBusinessUnits([...businessUnitsAsOptions]);
    }, [businessUnitsData?.businessUnits]);

    const { data: countries, isLoading: isLoadingCountries } = useQuery(
      ["getCampaignCountries"],
      getCampaignCountries
    );
    const { data: customers, isLoading: isLoadingCustomers } = useQuery(
      ["getCampaignCustomers"],
      getCustomers
    );
    const { data: representatives, isLoading: isLoadingRepresentatives } =
      useQuery(["getCampaignRepresentatives"], getRepresentativeNames);
    const { data: campaignManagers, isLoading: isLoadingCampaignManagers } =
      useQuery(["getCampaignManagers"], getCampaignManagers);

    const { data: corporateEntities, isLoading: isLoadingEntities } = useQuery(
      ["getCorporateEntities"],
      getCorporateEntities
    );

    const {
      checkedValues: checkedCountries,
      expandedValues: expandedCountries,
      setCheckedValues: setCheckedCountries,
      setExpandedValues: setExpandedCountries,
      onReset: onResetCountries,
    } = useTree({
      checked: (state?.countries || []).map(({ countryId }) => countryId),
      expanded: [],
    });

    const isLoading =
      isLoadingCustomers ||
      isLoadingEntities ||
      isLoadingRepresentatives ||
      isLoadingCountries;

    const regionsOptions = useMemo(() => {
      const regions = (countries?.countries || []).reduce(
        (acc: { [key: string]: Node[] }, country: Country) => {
          if (acc[country.region]) {
            acc[country.region].push({
              label: country.displayName,
              value: country.countryId,
            });
          } else {
            acc[country.region] = [
              {
                label: country.displayName,
                value: country.countryId,
              },
            ];
          }
          return acc;
        },
        {}
      );

      return Object.keys(regions).map((region) => ({
        label: region,
        value: region.toLocaleLowerCase(),
        children: regions[region],
      }));
    }, [countries]);

    useEffect(() => {
      setLoading(isLoading);
    }, [isLoading, setLoading]);

    useImperativeHandle(ref, () => ({
      submit({ shouldExitOnSuccess }) {
        handleSubmit((values) => {
          const settings = {
            ...(values as CampaignSettings),
            countries: checkedCountries
              .map((countryId) => {
                return (countries?.countries || []).find(
                  (el) => countryId === el.countryId
                );
              })
              .filter(isDefined)
              .map((country) => ({
                displayName: country.displayName,
                countryId: country.countryId,
              })),
            businessUnits: selectedBusinessUnits?.map(
              ({ value, label }) =>
                ({
                  displayName: label,
                  businessUnitId: Number(value),
                } as BusinessUnit)
            ),
          };

          onSubmit({ settings, shouldExitOnSuccess });
        })();
      },
    }));

    const haveChanged = useWatchSettings(
      state,
      checkedCountries,
      control,
      selectedBusinessUnits
    );

    return (
      <>
        <NavigationBlockers
          isUnsavedChangesBlockerEnabled={haveChanged && !isLoading}
        />

        <chakra.form borderTopRadius={4}>
          <SimpleGrid columns={6} spacing={4}>
            <FormControl
              as={GridItem}
              colSpan={[6, 3]}
              isInvalid={!!errors?.displayName?.message}
              isRequired
            >
              <FormLabel
                fontSize="sm"
                fontWeight="md"
                color={componentColors.form.formLabelColor}
              >
                Intent Model Name
              </FormLabel>
              <Input
                {...register("displayName")}
                type="text"
                name="displayName"
                shadow="sm"
                size="sm"
                w="full"
                rounded="md"
              />
              <FormErrorMessage>
                {errors?.displayName?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              as={GridItem}
              colSpan={[6, 3]}
              isInvalid={!!errors?.companyType?.message}
              isRequired
            >
              <FormLabel
                fontSize="sm"
                fontWeight="md"
                color={componentColors.form.formLabelColor}
              >
                Customer Type
              </FormLabel>
              <Controller
                name="companyType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isMulti={false}
                    value={customerTypeOptions.find((o) => o.value === value)}
                    onChange={(selectedOption) => {
                      onChange(selectedOption?.value ?? null);
                    }}
                    placeholder="Select customer type"
                    options={customerTypeOptions}
                    isClearable
                  />
                )}
              />
              <FormErrorMessage>
                {errors?.companyType?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              as={GridItem}
              colSpan={[6, 3]}
              isInvalid={!!errors?.repId?.message}
              isRequired
            >
              <FormLabel
                fontSize="sm"
                fontWeight="md"
                color={componentColors.form.formLabelColor}
              >
                Sales Representative
              </FormLabel>
              <Controller
                name="repId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isMulti={false}
                    value={
                      representatives &&
                      representatives.find((r) => r.value === value)
                    }
                    onChange={(selectedOption: Option | null) => {
                      onChange(selectedOption?.value);
                    }}
                    isLoading={isLoadingRepresentatives}
                    options={representatives ?? []}
                    placeholder="Select sales representative"
                  />
                )}
              />
              <FormErrorMessage>{errors?.repId?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              as={GridItem}
              colSpan={[6, 3]}
              isInvalid={!!errors?.customerSuccessManagerId?.message}
              isRequired
            >
              <FormLabel
                fontSize="sm"
                fontWeight="md"
                color={componentColors.form.formLabelColor}
              >
                Campaign Managers
              </FormLabel>
              <Controller
                name="customerSuccessManagerId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isMulti={false}
                    value={
                      campaignManagers &&
                      campaignManagers.find((r) => r.value === value)
                    }
                    onChange={(selectedOption: Option | null) => {
                      onChange(selectedOption?.value);
                    }}
                    isLoading={isLoadingCampaignManagers}
                    options={campaignManagers ?? []}
                    placeholder="Select customer success manager"
                  />
                )}
              />
              <FormErrorMessage>
                {errors?.customerSuccessManagerId?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={!!errors?.startDate?.message}
              as={GridItem}
              colSpan={[6, 3]}
              isRequired
            >
              <FormLabel
                fontSize="sm"
                fontWeight="md"
                color={componentColors.form.formLabelColor}
              >
                Start Date
              </FormLabel>
              <Controller
                control={control}
                name="startDate"
                render={({ field: { onChange, value } }) => (
                  <DayPicker initialValue={value} onChange={onChange} />
                )}
              />
              <FormErrorMessage>{errors?.startDate?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={!!errors?.endDate?.message}
              as={GridItem}
              colSpan={[6, 3]}
              isRequired
            >
              <FormLabel
                fontSize="sm"
                fontWeight="md"
                color={componentColors.form.formLabelColor}
              >
                End Date
                <Tooltip
                  aria-label="Campaign settings end date"
                  label={`Please reflect end date for the full program this campaign connects to. Users with access to this campaign will only have viewability 45 days after selected end date.`}
                >
                  <InfoIcon w={4} h={4} ml={2} />
                </Tooltip>
              </FormLabel>
              <Controller
                control={control}
                name="endDate"
                render={({ field: { onChange, value } }) => (
                  <DayPicker
                    initialValue={value ?? undefined}
                    onChange={onChange}
                  />
                )}
              />
              <FormErrorMessage>{errors?.endDate?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              as={GridItem}
              colSpan={[6, 3]}
              isInvalid={!!errors?.companyId?.message}
              isRequired
            >
              <FormLabel
                fontSize="sm"
                fontWeight="md"
                color={componentColors.form.formLabelColor}
              >
                Company
              </FormLabel>
              <Controller
                name="companyId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isMulti={false}
                    value={
                      customers &&
                      customers.find((c) => c.value === String(value))
                    }
                    onChange={(selectedOption) => {
                      onChange(Number(selectedOption?.value));
                    }}
                    isLoading={isLoadingCustomers}
                    placeholder="Select company"
                    options={customers ?? []}
                  />
                )}
              />
              <FormErrorMessage>{errors?.companyId?.message}</FormErrorMessage>
            </FormControl>

            <FormControl as={GridItem} colSpan={[6, 3]}>
              <FormLabel
                color={componentColors.form.formLabelColor}
                fontSize="sm"
                fontWeight="md"
              >
                Company Subsidiary
              </FormLabel>

              <Filter
                isDisabled={!companyId || isBusinessUnitsLoading}
                options={businessUnits}
                isLoading={isBusinessUnitsLoading && Boolean(companyId)}
                placeholder="Select subsidiary"
                onFilterValuesChange={(values) => {
                  setSelectedBusinessUnits([...values]);
                }}
                selected={selectedBusinessUnits}
              />
            </FormControl>

            <FormControl
              as={GridItem}
              colSpan={[6, 3]}
              isInvalid={!!errors?.corporateEntityId?.message}
              isRequired
            >
              <FormLabel
                fontSize="sm"
                fontWeight="md"
                color={componentColors.form.formLabelColor}
              >
                Corporate Entity
              </FormLabel>
              <Controller
                name="corporateEntityId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    isMulti={false}
                    value={
                      corporateEntities &&
                      corporateEntities.find((e) => e.value === value)
                    }
                    onChange={(selectedOption) => {
                      onChange(selectedOption?.value);
                    }}
                    isLoading={isLoadingEntities}
                    options={corporateEntities ?? []}
                    placeholder="Select corporate entity"
                  />
                )}
              />
              <FormErrorMessage>
                {errors?.corporateEntityId?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl as={GridItem} colSpan={6}>
              <FormLabel
                fontSize="sm"
                fontWeight="md"
                color={componentColors.form.formLabelColor}
              >
                Notes
              </FormLabel>
              <Textarea
                {...register("notes")}
                name="notes"
                mt={1}
                shadow="sm"
                size="sm"
                w="full"
                rounded="md"
              />
            </FormControl>
          </SimpleGrid>

          {!isLoadingCountries ? (
            <FormControl mt="4">
              <FormLabel
                color={componentColors.form.formLabelColor}
                fontSize="sm"
                fontWeight="md"
              >
                <Text fontWeight="semibold">Regions</Text>
                <Text>Select regions from list below.</Text>
              </FormLabel>

              <Tree
                subject="regions"
                nodes={regionsOptions}
                checked={checkedCountries}
                expanded={expandedCountries}
                onCheck={(checked) => setCheckedCountries(checked)}
                onExpand={(expanded) => setExpandedCountries(expanded)}
                onReset={onResetCountries}
              />
            </FormControl>
          ) : (
            <Spinner size="sm" />
          )}
        </chakra.form>
      </>
    );
  }
);

export { SettingsForm };
