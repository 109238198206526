import { InfoIcon } from "@chakra-ui/icons";
import { Box, Checkbox, Flex, Icon, Text, useToast } from "@chakra-ui/react";
import { HasAccess } from "@intentsify/authorization/dist/react";
import { AccountFileDTO, TempAccountFileDTO } from "@intentsify/dto";
import {
  CampaignAccounts,
  CampaignSettings,
  DomainEnhancementStrategy,
  MAX_FIRMOGRAPHICS_ALLOWED_WIZARD,
} from "@intentsify/types";
import {
  TalColumnTemplate,
  isDefined,
  isPopulatedArray,
  toNumberDisplayValue,
} from "@intentsify/utils";
import { Endpoints } from "api";
import { IconButton, Tooltip } from "components";
import noop from "lodash/noop";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { FaFileCsv } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { MutateCampaignDomainsParams } from "screens/Campaigns/screens/CampaignsWizard/CampaignsWizard.requests";

import isEqual from "lodash/isEqual";
import { DomainsUploadTus } from "shared/components/DomainsUploadTus/DomainsUploadTus";
import { UploadedFileEntry } from "shared/components/UploadedFiles/UploadedFileEntry";
import { UploadedFiles } from "shared/components/UploadedFiles/UploadedFiles";
import { UploadedFilesWrapper } from "shared/components/UploadedFiles/UploadedFilesWrapper";
import { useDownloadCsvLink } from "shared/hooks/useDownloadCsvLink";
import { useComponentColors } from "theme";
import { blocklistUpload } from "upload/blocklistUpload";
import { talUpload } from "upload/talUpload";
import { useDownloadFile } from "utils";
import { Section } from "../../../../../../../components/Section";
import { NavigationBlockers } from "../../NavigationBlockers/NavigationBlockers";
import { SubmitTrigger } from "../../SettingsStep/SettingsForm";
import {
  MAXIMUM_UPLOADED_DOMAINS,
  MAXIMUM_UPLOADED_FILES,
} from "./DomainsForm.const";
import { useUploadedFiles, useWatchDomains } from "./DomainsForm.utils";
import { Firmographics } from "./components/Firmographics";
import { defaultFirmoFilters } from "./components/Firmographics/Firmographics.const";

export const isAccountFileDTO = (
  file: AccountFileDTO | TempAccountFileDTO
): file is AccountFileDTO => {
  return !isDefined((file as TempAccountFileDTO).tempFileName);
};

export const isTempAccountFileDTO = (
  file: AccountFileDTO | TempAccountFileDTO
): file is TempAccountFileDTO => {
  return isDefined((file as TempAccountFileDTO).tempFileName);
};

type DomainsFormProps = {
  domainsSettings: CampaignAccounts;
  campaignSettings: CampaignSettings;
  onSubmit: (params: MutateCampaignDomainsParams) => void;
  onAccountsCountChange: (count: number) => void;
};

const template = [TalColumnTemplate];

const DomainsForm = forwardRef<SubmitTrigger | undefined, DomainsFormProps>(
  (
    { domainsSettings, campaignSettings, onSubmit, onAccountsCountChange },
    ref
  ) => {
    const componentColors = useComponentColors();
    const [state, setState] = useState<CampaignAccounts>(domainsSettings);
    const toast = useToast();
    const { download } = useDownloadFile();
    const [haveFilesChanged, setHaveFilesChanged] = useState(false);
    const [firmographicsCount, setFirmographicsCount] = useState(0);

    const { campaignId } = campaignSettings;

    const {
      filesForList: uploadedFiles,
      files: accountsFiles,
      setFiles: setAccountsFiles,
    } = useUploadedFiles({
      campaignId,
      files: domainsSettings.files,
      onDeleteIconClick: () => setHaveFilesChanged(true),
      onDownloadClick: (fileName) => {
        download({
          url: `${Endpoints.Campaigns.Get.Domains.DownloadDomainsFile(
            campaignId,
            fileName
          )}`,
          fileName,
        });
      },
    });

    const {
      filesForList: blocklistedFilesList,
      files: blocklistedFiles,
      setFiles: setBlocklistedFiles,
    } = useUploadedFiles({
      campaignId,
      files: domainsSettings.blocklistedFiles,
      onDeleteIconClick: () => setHaveFilesChanged(true),
      onDownloadClick: (fileName) => {
        download({
          url: `${Endpoints.Campaigns.Get.Domains.DownloadBlocklistedDomainsFile(
            campaignId,
            fileName
          )}`,
          fileName,
        });
      },
    });

    useEffect(() => {
      setAccountsFiles(domainsSettings.files);
      setBlocklistedFiles(domainsSettings.blocklistedFiles);
      setHaveFilesChanged(false);
    }, [domainsSettings, setAccountsFiles, setBlocklistedFiles]);

    const { firmographicsFilters } = state;

    const accountsCount = useMemo(() => {
      return (
        firmographicsCount +
        accountsFiles.reduce((prev, curr) => {
          if (isAccountFileDTO(curr) && curr.toBeDeleted) {
            return prev;
          }

          return curr.accountsCount + prev;
        }, 0)
      );
    }, [accountsFiles, firmographicsCount]);

    useEffect(() => {
      onAccountsCountChange(accountsCount);
    }, [accountsCount, onAccountsCountChange]);

    const isWithinDomainFilesLimit = useCallback(
      (newFileAccountCount: number) => {
        if (accountsFiles.length >= MAXIMUM_UPLOADED_FILES) {
          return false;
        }

        const totalDomains = accountsFiles.reduce((prev, curr) => {
          if (isAccountFileDTO(curr) && curr.toBeDeleted) {
            return prev;
          }

          return curr.accountsCount + prev;
        }, 0);

        if (totalDomains + newFileAccountCount > MAXIMUM_UPLOADED_DOMAINS) {
          return false;
        }

        return true;
      },
      [accountsFiles]
    );

    const isWithinBlocklistedFilesLimit = useCallback(
      (newFileAccountCount: number) => {
        if (blocklistedFiles.length >= MAXIMUM_UPLOADED_FILES) {
          return false;
        }

        const totalDomains = blocklistedFiles.reduce((prev, curr) => {
          if (isAccountFileDTO(curr) && curr.toBeDeleted) {
            return prev;
          }

          return curr.accountsCount + prev;
        }, 0);

        if (totalDomains + newFileAccountCount > MAXIMUM_UPLOADED_DOMAINS) {
          return false;
        }

        return true;
      },
      [blocklistedFiles]
    );

    useImperativeHandle(ref, () => ({
      submit({ shouldExitOnSuccess }) {
        onSubmit({
          haveFilesChanged,
          dto: {
            blocklistedFiles,
            accountsFiles,
            domainEnhancementStrategy: state.domainEnhancementStrategy,
            firmographicsFilters: firmographicsFilters
              ? {
                  ...defaultFirmoFilters, // older campaigns may miss includeOffice filter, so we need to include defaults
                  ...firmographicsFilters,
                }
              : undefined,
          },
          shouldExitOnSuccess,
        });
      },
    }));

    const [downloadCsvLink] = useDownloadCsvLink(template);

    const haveChanged = useWatchDomains(
      haveFilesChanged,
      domainsSettings.firmographicsFilters,
      state.firmographicsFilters,
      domainsSettings.domainEnhancementStrategy,
      state.domainEnhancementStrategy
    );

    return (
      <>
        <NavigationBlockers isUnsavedChangesBlockerEnabled={haveChanged} />

        <Flex flexDirection="column">
          <Section
            isLazy
            name="Upload a list"
            tooltip={
              <Tooltip
                aria-label="Upload domains"
                label={`Upload domains as a CSV file. A "Domain" column is required. Refer to the template below for additional mapping columns you can upload. A max of ${toNumberDisplayValue(
                  MAXIMUM_UPLOADED_FILES
                )} files can be uploaded and a total of ${toNumberDisplayValue(
                  MAXIMUM_UPLOADED_DOMAINS
                )} domains.`}
              >
                <InfoIcon
                  color={componentColors.form.formLabelColor}
                  w={4}
                  h={4}
                  ml={2}
                  mt={-1}
                />
              </Tooltip>
            }
          >
            <DomainsUploadTus
              tusUpload={talUpload}
              uploadedFileNames={accountsFiles
                .filter((i) => {
                  if (isAccountFileDTO(i) && i.toBeDeleted) {
                    return false;
                  }

                  return true;
                })
                .map((i) => i.fileName)}
              onFileUpload={(tempFileName, fileName, validationResults) => {
                if (
                  !isWithinDomainFilesLimit(validationResults.validRowCount)
                ) {
                  toast({
                    status: "error",
                    description: `Sorry, you can upload at most ${toNumberDisplayValue(
                      MAXIMUM_UPLOADED_FILES
                    )} files and the total number of accouts must be less then ${toNumberDisplayValue(
                      MAXIMUM_UPLOADED_DOMAINS
                    )}.`,
                    duration: null,
                    isClosable: true,
                  });
                  return;
                }

                setAccountsFiles((prev) => {
                  return [
                    ...prev.filter((file) => file.fileName !== fileName),
                    {
                      tempFileName,
                      fileName,
                      accountsCount: validationResults.validRowCount,
                    },
                  ];
                });

                setHaveFilesChanged(true);
              }}
            />

            {isPopulatedArray(uploadedFiles) && (
              <UploadedFilesWrapper variant="plain">
                <UploadedFiles hideUploadedAt={false}>
                  {uploadedFiles.map((uploadedFileEntryProps) => (
                    <UploadedFileEntry
                      hideDownload={isTempAccountFileDTO(
                        uploadedFileEntryProps.file
                      )}
                      hideUploadedAt={false}
                      key={uploadedFileEntryProps.file.fileName}
                      {...uploadedFileEntryProps}
                    />
                  ))}
                </UploadedFiles>
              </UploadedFilesWrapper>
            )}

            <Flex w="full" color="teal" alignItems="center" mt={4}>
              <Flex w="full" alignItems="center">
                <Icon as={FaFileCsv} w={3} h={3} mr={1} />
                <Text
                  fontWeight="semibold"
                  fontSize="xs"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  title={"domain_mapping_template.csv"}
                  mr={2}
                >
                  domain_mapping_template.csv
                </Text>

                <IconButton
                  as="a"
                  icon={<Icon cursor="pointer" as={FiDownload} fontSize="xs" />}
                  href={downloadCsvLink}
                  download="domain_mapping_template.csv"
                  tooltip="Download file"
                  onClick={noop}
                />
              </Flex>
            </Flex>

            <HasAccess to="campaign.domainReplacement">
              <Flex
                w="full"
                flexDir="column"
                color="black"
                gap="2"
                mt="4"
                _dark={{ color: "white" }}
              >
                <Text fontSize="sm">Domain enhancement</Text>

                <Flex w="full" color="black" gap="8" _dark={{ color: "white" }}>
                  <Flex gap="2">
                    <Checkbox
                      id="replace"
                      isChecked={
                        state.domainEnhancementStrategy ===
                        DomainEnhancementStrategy.replace
                      }
                      onChange={(e) => {
                        const domainEnhancementStrategy = e.target.checked
                          ? DomainEnhancementStrategy.replace
                          : DomainEnhancementStrategy.unspecified;

                        setState((prev) => ({
                          ...prev,
                          domainEnhancementStrategy,
                        }));
                      }}
                    />
                    <Text
                      as="label"
                      htmlFor="replace"
                      display="flex"
                      alignItems="center"
                      fontSize="sm"
                      lineHeight={1.1}
                      gap="2"
                      whiteSpace="nowrap"
                    >
                      Replace suboptimal domains
                      <Tooltip
                        aria-label={"replace"}
                        label={
                          <>
                            Using Domain Enhancement, our system will find
                            domains that don't show intent and/or aren't
                            resolvable and replace with related domains that are
                            resolvable and/or have intent signal. Only select if
                            customer has approved.
                          </>
                        }
                      >
                        <InfoIcon w={4} h={4} verticalAlign={"center"} pl={1} />
                      </Tooltip>
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </HasAccess>
          </Section>

          <HasAccess to="digitalForecasting">
            <Box zIndex={1} position={"relative"}>
              <Section
                name="Select from firmographics"
                tooltip={
                  <Tooltip
                    aria-label="Select from firmographics"
                    label={`Select domains using firmographics. These domains can be used in addition to the uploaded file or, you can use firmographics exclusively. Selection is capped at ${toNumberDisplayValue(
                      MAX_FIRMOGRAPHICS_ALLOWED_WIZARD
                    )} domains.`}
                  >
                    <InfoIcon w={4} h={4} ml={2} mt={-1} />
                  </Tooltip>
                }
              >
                <Firmographics
                  onCountChange={setFirmographicsCount}
                  currentFirmographicsFilters={
                    domainsSettings.firmographicsFilters
                  }
                  files={domainsSettings.firmographicsFiles}
                  firmographicsFilters={
                    state.firmographicsFilters ?? defaultFirmoFilters
                  }
                  onSetFirmographicsFilters={(firmographicsFilters) => {
                    if (isEqual(firmographicsFilters, defaultFirmoFilters)) {
                      setState((prev) => {
                        return {
                          ...prev,
                          firmographicsFilters: undefined,
                        };
                      });

                      return;
                    }

                    setState((prev) => {
                      return {
                        ...prev,
                        firmographicsFilters,
                      };
                    });
                  }}
                />
              </Section>
            </Box>
          </HasAccess>

          <Section
            name="Upload a blocklist"
            tooltip={
              <Tooltip
                aria-label="Upload a blocklist"
                label={`Upload domains that you would like to exclude from your TAL. A max of ${toNumberDisplayValue(
                  MAXIMUM_UPLOADED_FILES
                )} files can be uploaded and a total of ${toNumberDisplayValue(
                  MAXIMUM_UPLOADED_DOMAINS
                )} domains.`}
              >
                <InfoIcon w={4} h={4} ml={2} mt={-1} />
              </Tooltip>
            }
          >
            <DomainsUploadTus
              tusUpload={blocklistUpload}
              uploadedFileNames={blocklistedFiles
                .filter((i) => {
                  if (isAccountFileDTO(i) && i.toBeDeleted) {
                    return false;
                  }

                  return true;
                })
                .map((i) => i.fileName)}
              onFileUpload={(tempFileName, fileName, validationResults) => {
                if (
                  !isWithinBlocklistedFilesLimit(
                    validationResults.validRowCount
                  )
                ) {
                  toast({
                    status: "error",
                    description: `Sorry, you can upload at most ${toNumberDisplayValue(
                      MAXIMUM_UPLOADED_FILES
                    )} files and the total number of accouts must be less then ${toNumberDisplayValue(
                      MAXIMUM_UPLOADED_DOMAINS
                    )}.`,
                    duration: null,
                    isClosable: true,
                  });
                  return;
                }

                setBlocklistedFiles((prev) => {
                  return [
                    ...prev.filter((file) => file.fileName !== fileName),
                    {
                      tempFileName,
                      fileName,
                      accountsCount: validationResults.validRowCount,
                    },
                  ];
                });

                setHaveFilesChanged(true);
              }}
            />

            {blocklistedFilesList && blocklistedFilesList?.length > 0 && (
              <UploadedFilesWrapper variant="plain">
                <UploadedFiles hideUploadedAt={false}>
                  {blocklistedFilesList.map((uploadedFileEntryProps) => (
                    <UploadedFileEntry
                      hideDownload={isTempAccountFileDTO(
                        uploadedFileEntryProps.file
                      )}
                      hideUploadedAt={false}
                      key={uploadedFileEntryProps.file.fileName}
                      {...uploadedFileEntryProps}
                    />
                  ))}
                </UploadedFiles>
              </UploadedFilesWrapper>
            )}
          </Section>
        </Flex>
      </>
    );
  }
);

export { DomainsForm };
