import {
  GetProgrammaticCampaignStatisticsRO,
  CampaignImpressionsByLocationRO,
  CampaignTopicsByLocationRO,
  GetProgrammaticCampaignIntegratedCampaignReportRO,
} from "@intentsify/dto";
import { DisplayPerformanceFilters } from "../../DisplayPerformance";
import { apiService, Endpoints } from "api";

export const getStatistics = async (
  id: number,
  filters: DisplayPerformanceFilters
): Promise<GetProgrammaticCampaignStatisticsRO> => {
  const { data } = await apiService.get(
    Endpoints.ProgrammaticCampaigns.ImpressionsAndClicks.Get.Statistics(id),
    {
      params: {
        yearFrom: filters.yearFrom,
        yearTo: filters.yearTo,
        weekFrom: filters.weekFrom,
        weekTo: filters.weekTo,
      },
    }
  );
  return data;
};

export const getImpressionsByLocation = async (
  id: number,
  filters: DisplayPerformanceFilters
): Promise<CampaignImpressionsByLocationRO> => {
  const { data } = await apiService.get(
    Endpoints.ProgrammaticCampaigns.ImpressionsAndClicks.Get.ImpressionsByLocation(
      id
    ),
    {
      params: {
        yearFrom: filters.yearFrom,
        yearTo: filters.yearTo,
        weekFrom: filters.weekFrom,
        weekTo: filters.weekTo,
      },
    }
  );

  return data;
};

export const getTopicsByLocation = async (
  id: number,
  filters: DisplayPerformanceFilters
): Promise<CampaignTopicsByLocationRO> => {
  const { data } = await apiService.get(
    Endpoints.ProgrammaticCampaigns.ImpressionsAndClicks.Get.TopicsByLocation(
      id
    ),
    {
      params: {
        yearFrom: filters.yearFrom,
        yearTo: filters.yearTo,
        weekFrom: filters.weekFrom,
        weekTo: filters.weekTo,
      },
    }
  );

  return data;
};

export const getIntegratedCampaignReport = async (
  id: number,
  filters: DisplayPerformanceFilters
): Promise<GetProgrammaticCampaignIntegratedCampaignReportRO> => {
  const { data } = await apiService.get(
    Endpoints.ProgrammaticCampaigns.ImpressionsAndClicks.Get.IntegratedCampaignReport(
      id
    ),
    {
      params: {
        yearFrom: filters.yearFrom,
        yearTo: filters.yearTo,
        weekFrom: filters.weekFrom,
        weekTo: filters.weekTo,
      },
    }
  );
  return data;
};
