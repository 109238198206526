import { useRef } from "react";
import { useParams } from "react-router";
import { useCampaign } from "../../CampaignsWizard.hooks";
import { MutateCampaignBuyingGroupParams } from "../../CampaignsWizard.requests";
import { Controls } from "../Controls";
import { SubmitTrigger } from "../SettingsStep/SettingsForm";
import { BuyingGroupForm } from "./BuyingGroupForm";

type BuyingGroupStepProps = {
  companyId: number;
  editing?: boolean;
  onSubmit: (params: MutateCampaignBuyingGroupParams) => void;
  nextItemLabel?: string;
};

const BuyingGroupStep = ({
  companyId,
  onSubmit,
  editing = false,
  nextItemLabel,
}: BuyingGroupStepProps) => {
  const formRef = useRef<SubmitTrigger | undefined>();

  const { step } = useParams<"step">();
  const { campaignId } = useParams<"campaignId">();

  const { campaignBuyingGroup } = useCampaign({
    campaignId: Number(campaignId),
  });

  if (!step || !campaignId) {
    throw Error("404");
  }

  return (
    <>
      <BuyingGroupForm
        ref={formRef}
        id={Number(campaignId)}
        companyId={companyId}
        campaignBuyingGroup={campaignBuyingGroup}
        onSubmit={onSubmit}
      />

      <Controls
        onNextLabel={`Next${nextItemLabel ? `: ${nextItemLabel}` : ""}`}
        editing={editing}
        isDisabled={false}
        formRef={formRef}
        step={Number(step)}
        campaignId={Number(campaignId)}
      />
    </>
  );
};
export { BuyingGroupStep };
