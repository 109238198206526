import { InfoIcon } from "@chakra-ui/icons";
import { Divider, Flex, Text, TooltipProps, FlexProps } from "@chakra-ui/react";
import { IconButton, Modal } from "components";
import React, { ReactNode, useState } from "react";
import { IconType } from "react-icons";
import { AiOutlineExpand } from "react-icons/ai";
import { Loader } from "../Loader";
import { Tooltip } from "../Tooltip";
import { Body } from "./Body";
import { CardColorScheme, usePalette } from "./Card.theme";

export type CardProps = {
  title: string;
  titleTooltip?: string;
  titleTooltipPlacement?: TooltipProps["placement"];
  isLoading?: boolean;
  colorScheme?: CardColorScheme;
  actions?: ReactNode;
  hasData?: boolean;
  noDataMessage?: string;
  noDataIcon?: IconType;
  expandedSize?: React.ComponentProps<typeof Modal>["size"];
  isExpandable?: boolean;
  onExpandedChange?: (expanded: boolean) => void;
  children?: ReactNode | ((expanded: boolean) => ReactNode);
  ignoreTitleMargin?: boolean;
  minHeight?: FlexProps["minHeight"];
  minWidth?: FlexProps["minWidth"];
};

const Card = ({
  title,
  titleTooltip,
  titleTooltipPlacement,
  children,
  colorScheme = "white",
  isLoading = false,
  actions,
  hasData = true,
  noDataMessage = "No data found.",
  noDataIcon,
  isExpandable = false,
  expandedSize,
  onExpandedChange,
  ignoreTitleMargin = false,
  minHeight = "150px",
  minWidth = "150px",
}: CardProps) => {
  const [isExpanded, setExpanded] = useState(false);
  const palette = usePalette(isLoading || hasData, colorScheme);

  return (
    <>
      <Modal
        size={expandedSize ?? "expandedCard"}
        isOpen={isExpanded}
        onClose={() => {
          setExpanded(false);
          onExpandedChange && onExpandedChange(false);
        }}
        title={title}
        body={
          <Body
            isExpanded={true}
            hasData={hasData}
            noDataIcon={noDataIcon}
            noDataMessage={noDataMessage}
            palette={palette}
          >
            {children}
          </Body>
        }
      />

      <Flex
        flexDir="column"
        bg={palette?.bg}
        borderWidth="1px"
        borderStyle="solid"
        borderColor={palette?.border}
        minH={minHeight}
        minW={minWidth}
        w="100%"
      >
        {isLoading ? (
          <Loader stretch minHeight="100%" label="" />
        ) : (
          <>
            <Flex
              p={2}
              pb={0}
              flexDir="column"
              bg={palette?.titleBg}
              mb={ignoreTitleMargin ? undefined : 4}
            >
              <Text
                as="div"
                fontSize="xs"
                fontWeight="semibold"
                textTransform="uppercase"
                alignItems="center"
                display="flex"
                color={palette?.title}
                p={actions ? 0 : 1}
              >
                {title}
                <Flex flexGrow={1} />
                {titleTooltip && (
                  <Tooltip
                    aria-label={title}
                    placement={titleTooltipPlacement}
                    label={titleTooltip}
                  >
                    <InfoIcon color={palette?.helpIcon} w={3} h={3} ml={2} />
                  </Tooltip>
                )}

                {actions && <Flex ml={2}>{actions}</Flex>}

                {isExpandable && (
                  <Flex ml={2}>
                    <IconButton
                      size="xs"
                      variant="solid"
                      tooltip="Expand"
                      onClick={() => {
                        setExpanded(true);
                        onExpandedChange && onExpandedChange(true);
                      }}
                      icon={<AiOutlineExpand />}
                    />
                  </Flex>
                )}
              </Text>

              <Divider borderColor={palette?.divider} mt={1} mb={0} />
            </Flex>

            {!isExpanded && (
              <Body
                isExpanded={false}
                hasData={hasData}
                noDataIcon={noDataIcon}
                noDataMessage={noDataMessage}
                palette={palette}
              >
                {children}
              </Body>
            )}
          </>
        )}
      </Flex>
    </>
  );
};

export { Card };
