import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import { WeeklySnapshotRangeDTO } from "@intentsify/dto";
import { useQuery } from "@tanstack/react-query";
import { BarChart, Card, StatCardsContainer } from "components";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { getImpressionsAndClicksOverTime } from "screens/IntentAnalysis/screens/WeeklySnapshot/WeeklySnapshot.requests";
import { ChartExport, useExportableChart } from "shared/components";
import { ImpressionsAndClicksOverTime } from "shared/components/ImpressionsAndClicksOverTime";
import { AccountsReachedOverTime } from "../../../AccountsReachedOverTime/AccountsReachedOverTime";
import { TopAccountsBySpend } from "../../../TopAccountsBySpend";
import { useLeadsPerformance } from "./Performance.state";

type PerformanceProps = {
  campaignId: number;
  filters: WeeklySnapshotRangeDTO;
};

export const Performance = ({ campaignId, filters }: PerformanceProps) => {
  const { data, isLoading } = useLeadsPerformance(campaignId, filters);

  const {
    data: impressionsAndClicksOverTimeData,
    isFetching: isFetchingimpressionsAndClicksOverTime,
  } = useQuery(
    ["WeeklySnapshotimpressionsAndClicksOverTime", campaignId, filters],
    () => getImpressionsAndClicksOverTime(campaignId, filters)
  );

  const minH = "350px";

  const dates = useMemo(() => {
    if (!filters) {
      return {
        start: DateTime.utc().toISO(),
        end: DateTime.utc().toISO(),
      };
    }

    return {
      start: DateTime.fromObject({
        weekNumber: filters.weekFrom,
        weekYear: filters.yearFrom,
      }).toISO(),
      end: DateTime.fromObject({
        weekNumber: filters.weekTo,
        weekYear: filters.yearTo,
      }).toISO(),
    };
  }, [filters]);

  const jobFunctionChart = useExportableChart({
    title: "Leads by job function",
    campaignId: campaignId,
    dates,
    chart: (
      <BarChart
        stretch
        minHeight={minH}
        orientation={
          (data?.byJobFunction ?? []).length > 5 ? "horizontal" : "vertical"
        }
        isLoading={isLoading}
        data={data?.byJobFunction ?? []}
        valueAxisLabel={"Sum of Leads Delivered"}
      />
    ),
  });

  const jobLevelChart = useExportableChart({
    title: "Leads by job level",
    campaignId: Number(campaignId),
    dates,
    chart: (
      <BarChart
        stretch
        minHeight={minH}
        orientation={
          (data?.byJobLevel ?? []).length > 5 ? "horizontal" : "vertical"
        }
        isLoading={isLoading}
        data={data?.byJobLevel ?? []}
        valueAxisLabel={"Sum of Leads Delivered"}
      />
    ),
  });

  const byIndustryChart = useExportableChart({
    title: "Leads by industry",
    campaignId: Number(campaignId),
    dates,
    chart: (
      <BarChart
        stretch
        minHeight={minH}
        orientation={
          (data?.byIndustry ?? []).length > 5 ? "horizontal" : "vertical"
        }
        isLoading={isLoading}
        data={data?.byIndustry ?? []}
        valueAxisLabel={"Sum of Leads Delivered"}
      />
    ),
  });

  const emptyMessage =
    "This visualization is allocated for content syndication programs associated with this campaign. If the program began recently, data may still be configuring.";

  return (
    <Box mb={4}>
      <StatCardsContainer
        items={[
          {
            isLoading,
            title: "Total Leads Generated",
            noValueMessage: "0",
            noDataMessage:
              "This visualization is allocated for content syndication programs only.",
            hasData: data?.totalLeads !== null,
            value: data?.totalLeads,
            colorScheme: "white",
          },
          {
            isLoading,
            title: "Total Impressions Delivered",
            noDataMessage:
              "This visualization is allocated for display programs only.",
            noValueMessage: "0",
            hasData: data?.totalImpressions !== null,
            value: data?.totalImpressions,
            colorScheme: "white",
          },
        ]}
      />
      <Flex gap={4} mb={4} minH="350px" maxH="500px">
        <ImpressionsAndClicksOverTime
          campaignId={campaignId}
          data={impressionsAndClicksOverTimeData || []}
          isLoading={isFetchingimpressionsAndClicksOverTime}
        />
        <AccountsReachedOverTime campaignId={campaignId} filters={filters} />
      </Flex>
      <TopAccountsBySpend campaignId={campaignId} filters={filters} />
      <Grid
        mt={2}
        gap={4}
        gridTemplateColumns="repeat(auto-fit, minmax(400px, 1fr))"
      >
        <GridItem>
          <Card
            title={jobFunctionChart.title}
            isExpandable
            isLoading={isLoading}
            hasData={Boolean(data?.byJobFunction.length)}
            noDataMessage={emptyMessage}
            actions={
              <ChartExport
                size="small"
                dates={dates}
                campaignId={Number(campaignId)}
                title={jobFunctionChart.title}
                onExportPNG={jobFunctionChart.downloadAsPng}
                data={data?.byJobFunction}
              />
            }
          >
            {jobFunctionChart.component}
            {jobFunctionChart.exportComponent}
          </Card>
        </GridItem>
        <GridItem>
          <Card
            title={jobLevelChart.title}
            isExpandable
            isLoading={isLoading}
            hasData={Boolean(data?.byJobLevel.length)}
            noDataMessage={emptyMessage}
            actions={
              <ChartExport
                size="small"
                dates={dates}
                campaignId={Number(campaignId)}
                title={jobLevelChart.title}
                onExportPNG={jobLevelChart.downloadAsPng}
                data={data?.byJobLevel}
              />
            }
          >
            {jobLevelChart.component}
            {jobLevelChart.exportComponent}
          </Card>
        </GridItem>
        <GridItem>
          <Card
            title={byIndustryChart.title}
            isExpandable
            isLoading={isLoading}
            hasData={Boolean(data?.byIndustry.length)}
            noDataMessage={emptyMessage}
            actions={
              <ChartExport
                size="small"
                dates={dates}
                campaignId={Number(campaignId)}
                title={byIndustryChart.title}
                onExportPNG={byIndustryChart.downloadAsPng}
                data={data?.byIndustry}
              />
            }
          >
            {byIndustryChart.component}
            {byIndustryChart.exportComponent}
          </Card>
        </GridItem>
      </Grid>
    </Box>
  );
};
