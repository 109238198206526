import {
  Country,
  Firmographic,
  FirmographicsFilters,
  NaicsCodeLevel,
  Node,
  PaginatedResponse,
  SicCodeLevel,
} from "@intentsify/types";
import { Endpoints, apiService } from "api";
import {
  FirmographicsFiltersResponse,
  PaginatedFirmographicsParams,
} from "./Firmographics.types";
import {
  buildNaicsCodesNodeTree,
  buildSicCodesNodeTree,
} from "./Firmographics.utils";

export const listPaginatedFirmographics =
  () =>
  async (
    params: PaginatedFirmographicsParams
  ): Promise<PaginatedResponse<Firmographic>> => {
    const { filters, page, per_page, search, order, order_by } = params;

    const { data } = await apiService.post(
      Endpoints.Campaigns.Post.Firmographics.Paginated,
      {
        filters,
        page,
        per_page,
        search,
        order,
        order_by,
      }
    );
    return data;
  };

export const getFirmographicsFilters =
  ({ shouldGroupNaics }: { shouldGroupNaics: boolean }) =>
  async (): Promise<{
    filters: FirmographicsFilters;
    regionsTree: Node[];
    sicCodesTree: Node<{ level: SicCodeLevel; parent: number }>[];
    naicsCodesTree: Node<{ level: NaicsCodeLevel; parent: number }>[];
    installBaseProductsTree: Node<{ parent: string }>[];
  }> => {
    const { data } = await apiService.get<FirmographicsFiltersResponse>(
      Endpoints.Campaigns.Get.FirmographicsFilters()
    );

    const nodes = data.countries.reduce(
      (acc: { [key: string]: Node[] }, country: Country) => {
        if (acc[country.region]) {
          acc[country.region].push({
            label: country.displayName,
            value: country.countryId,
          });
        } else {
          acc[country.region] = [
            {
              label: country.displayName,
              value: country.countryId,
            },
          ];
        }
        return acc;
      },
      {}
    );

    const regionsTree = Object.keys(nodes).map((region) => ({
      label: region,
      value: region.toLocaleLowerCase(),
      children: nodes[region],
    }));

    const filters: FirmographicsFilters = {
      ...data,
      countries: [],
      naics2Codes: [],
      naics3Codes: [],
      naics4Codes: [],
      naics5Codes: [],
      naics6Codes: [],
      installBaseProducts: [],
    };

    return {
      filters,
      regionsTree,
      sicCodesTree: buildSicCodesNodeTree(data.sicCodes),
      naicsCodesTree: buildNaicsCodesNodeTree(
        data.naicsCodes,
        shouldGroupNaics
      ),
      installBaseProductsTree: data.installBaseProductsTree,
    };
  };
