import {
  Box,
  Flex,
  HStack,
  Icon,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";
import { decimalToPercentage } from "@intentsify/utils";
import { useComponentColors } from "theme";

type UploadProps = {
  getInputProps: (props?: DropzoneInputProps | undefined) => DropzoneInputProps;
  subHeader?: string;
  getRootProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps;
  testId?: string;
  validationProgress?: number;
};

const Upload = ({
  getInputProps,
  subHeader,
  getRootProps,
  testId,
  validationProgress,
}: UploadProps) => {
  const componentColors = useComponentColors();
  const subHeaderColor = useColorModeValue("gray.600", "gray.50");
  return (
    <Flex
      data-test-id={testId}
      position="relative"
      justify="center"
      px={4}
      py={10}
      borderWidth={2}
      borderColor={useColorModeValue("gray.300", "gray.500")}
      borderStyle="dashed"
      rounded="md"
      w="100%"
      bg={useColorModeValue("gray.25", "brand.425")}
      {...getRootProps({ onClick: (e) => e.preventDefault() })}
    >
      <Stack spacing={1} alignItems="center">
        <HStack
          spacing={4}
          mb="2"
          color={useColorModeValue("gray.500", "gray.200")}
        >
          {validationProgress === undefined ? (
            <Icon w={10} h={10} as={FiUploadCloud} />
          ) : (
            <Spinner
              thickness="4px"
              speed="0.75s"
              size="md"
              {...componentColors.spinner}
            />
          )}

          <Box>
            {validationProgress === undefined ? (
              <>
                <Text fontWeight="semibold">Drag and drop files here</Text>
                <Text fontSize="sm">
                  or click here to browse files to upload
                </Text>
              </>
            ) : (
              <>
                <Text fontSize="sm">
                  Validation Progress:
                  <Box display="inline-block" width="4.5ch" textAlign="right">
                    {decimalToPercentage(validationProgress, 0)}%
                  </Box>
                </Text>
              </>
            )}
          </Box>
        </HStack>

        <input type="file" name="file" {...getInputProps()} />

        {validationProgress === undefined && subHeader && (
          <Text
            position="absolute"
            bottom="5"
            fontSize="xs"
            color={subHeaderColor}
          >
            {subHeader}
          </Text>
        )}
      </Stack>
    </Flex>
  );
};

export { Upload };
