import {
  ListCampaignManagersRO,
  ListCompaniesRO,
  ListCorporateEntitiesRO,
  ListCountriesRO,
  ListRepresentativeNamesRO,
} from "@intentsify/dto";
import { CampaignManager, Option } from "@intentsify/types";
import { Endpoints, apiService } from "api";
import { Item } from "types";

export const getCampaignTopics = async (): Promise<Item[]> => {
  const { data } = await apiService.get<Item[]>(Endpoints.Signals.Get.Topics);
  return data;
};

export const getCampaignCountries = async (): Promise<ListCountriesRO> => {
  const { data } = await apiService.get<ListCountriesRO>(
    Endpoints.Campaigns.Get.Countries
  );

  return data;
};

export const getCustomers = async (): Promise<Option[]> => {
  const { data } = await apiService.get<ListCompaniesRO>(
    Endpoints.Campaigns.Get.Companies
  );
  return data.companies.map((company) => ({
    value: company.companyId,
    label: company.displayName,
  }));
};

export const getRepresentativeNames = async (): Promise<Option[]> => {
  const { data } = await apiService.get<ListRepresentativeNamesRO>(
    Endpoints.Campaigns.Get.RepresentativeNames
  );
  return data.accountRepresentatives.map((rep) => ({
    value: rep.repId,
    label: rep.displayName,
  }));
};

export const getCampaignManagers = async (): Promise<Option[]> => {
  const { data } = await apiService.get<ListCampaignManagersRO>(
    Endpoints.Campaigns.Get.CampaignManagers
  );
  return data.campaignManagers.map((item: CampaignManager) => ({
    value: item.id,
    label: item.displayName,
  }));
};

export const getCorporateEntities = async (): Promise<Option[]> => {
  const { data } = await apiService.get<ListCorporateEntitiesRO>(
    Endpoints.Campaigns.Get.CorporateEntities
  );
  return data.corporateEntities.map((customer) => ({
    value: customer.corporateEntityId,
    label: customer.displayName,
  }));
};
