import { DateRange, Option, SplitBy } from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { Endpoints, apiService } from "api";
import { valueToQueryURL } from "utils";
import {
  TopPerformingDomainsOptions,
  TopPerformingDomainsWithTimeseriesResponse,
} from "./TopPerformingDomains.types";

export const getTopPerformingDomainsOptions = async (
  id: string,
  dateRange: DateRange,
  splitBy: SplitBy
): Promise<Omit<TopPerformingDomainsOptions, "isLoading">> => {
  const { data } = await apiService.get(
    Endpoints.Campaigns.Get.Domains.TopPerformingDomains(Number(id)),
    {
      params: {
        dateRange,
        splitBy,
      },
    }
  );
  return data;
};

type TopAccountsParams = {
  campaignId: string | undefined;
  accounts: Option<number>[] | undefined;
  dateRange: DateRange;
  splitBy: SplitBy;
};

export const getTopAccounts = async ({
  campaignId,
  accounts,
  dateRange,
  splitBy,
}: TopAccountsParams): Promise<TopPerformingDomainsWithTimeseriesResponse> => {
  if (!campaignId || !isPopulatedArray(accounts)) {
    return { data: [], timeseries: [] };
  }

  const { data } =
    await apiService.get<TopPerformingDomainsWithTimeseriesResponse>(
      Endpoints.Campaigns.Get.Domains.TopPerformingDomainsWithTimeseries(
        Number(campaignId)
      ),
      {
        params: {
          accounts: valueToQueryURL(accounts),
          dateRange,
          splitBy,
        },
      }
    );
  return data;
};
