import { FirmographicsFilters } from "@intentsify/types";

export const defaultFirmoFilters: FirmographicsFilters = {
  countries: [],
  employeeRanges: [],
  revenueRanges: [],
  usStates: [],
  naics2Codes: [],
  naics3Codes: [],
  naics4Codes: [],
  naics5Codes: [],
  naics6Codes: [],
  topics: [],
  keywords: [],
  installBaseProducts: [],
  includeOffice: false,
};
