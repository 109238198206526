import { Box } from "@chakra-ui/react";
import { firmoOptions } from "./FirmoTechnoAppend.options";

type FirmoTechnoAppendFileTooltipProps = {
  details: Record<string, string[]>;
};

export const FirmoTechnoAppendFileTooltip = ({
  details,
}: FirmoTechnoAppendFileTooltipProps) => {
  return (
    <>
      {Boolean(details.firmos?.length) && (
        <Box>
          Firmo:{" "}
          {details.firmos
            .map(
              (firmo) =>
                firmoOptions.find((option) => option.value === firmo)?.label
            )
            .filter(Boolean)
            .join(", ")}
        </Box>
      )}
      {Boolean(details.technos?.length) && (
        <Box>Techno: {details.technos.filter(Boolean).join(", ")}</Box>
      )}
    </>
  );
};
