import { TreeFilter } from "components";
import { useTechnographicProducts } from "../hooks/useTechnographicProducts";
import { useMemo } from "react";

type TechnographicProductsValue = {
  id: number;
  name: string;
  value: string;
};

type TechnographicProductsProps = {
  value: TechnographicProductsValue[];
  onChange: (value: TechnographicProductsValue[]) => void;
};

export const TechnographicProducts = ({
  value,
  onChange,
}: TechnographicProductsProps) => {
  const { data, isLoading } = useTechnographicProducts();
  const included = useMemo(() => {
    if (!data || !value?.length) {
      return [];
    }

    return data
      .flatMap((node) => node.children.flatMap((child) => child.children))
      .filter(
        (node) =>
          Boolean(node.id) &&
          value.some(
            ({ value: selectedNodeValue }) => node.value === selectedNodeValue
          )
      );
  }, [data, value]);

  return (
    <TreeFilter
      subject="Technographic Products"
      searchPlaceholder="Search by code or name"
      nodes={data ?? []}
      included={included}
      onIncludedChange={(selected) => {
        onChange(
          selected.map(({ id, label, value }) => ({
            id: id ?? -1,
            name: label,
            value,
          }))
        );
      }}
      isLoading={isLoading}
      isDisabled={isLoading}
    />
  );
};
