import {
  GetProgrammaticCampaignAccountAndPersonaInsightsRO,
  GetProgrammaticCampaignAccountsInBuyerResearchStageRO,
  GetProgrammaticCampaignAccountsLiftRO,
  GetProgrammaticCampaignActiveAccountEngagementsRO,
  GetProgrammaticCampaignEngagementAcrossPersonasRO,
  GetProgrammaticCampaignTopAccountsRO,
} from "@intentsify/dto";
import { DisplayPerformanceFilters } from "../../DisplayPerformance";
import { apiService, Endpoints } from "api";

export const getAccountLifts = async (
  id: number,
  filters: DisplayPerformanceFilters
): Promise<GetProgrammaticCampaignAccountsLiftRO> => {
  const { data } = await apiService.get(
    Endpoints.ProgrammaticCampaigns.Accounts.Get.AccountLifts(id),
    {
      params: {
        yearFrom: filters.yearFrom,
        yearTo: filters.yearTo,
        weekFrom: filters.weekFrom,
        weekTo: filters.weekTo,
      },
    }
  );
  return data;
};

export const getAccountInBuyerResearchStage = async (
  id: number,
  filters: DisplayPerformanceFilters
): Promise<GetProgrammaticCampaignAccountsInBuyerResearchStageRO> => {
  const { data } = await apiService.get(
    Endpoints.ProgrammaticCampaigns.Accounts.Get.AccountsInBuyerResearchStage(
      id
    ),
    {
      params: {
        yearFrom: filters.yearFrom,
        yearTo: filters.yearTo,
        weekFrom: filters.weekFrom,
        weekTo: filters.weekTo,
      },
    }
  );
  return data;
};

export const getTopAccounts = async (
  id: number,
  filters: DisplayPerformanceFilters
): Promise<GetProgrammaticCampaignTopAccountsRO> => {
  const { data } = await apiService.get(
    Endpoints.ProgrammaticCampaigns.Accounts.Get.TopAccounts(id),
    {
      params: {
        yearFrom: filters.yearFrom,
        yearTo: filters.yearTo,
        weekFrom: filters.weekFrom,
        weekTo: filters.weekTo,
      },
    }
  );
  return data;
};

export const getAccountEngagement = async (
  id: number,
  filters: DisplayPerformanceFilters
): Promise<GetProgrammaticCampaignActiveAccountEngagementsRO> => {
  const { data } = await apiService.get(
    Endpoints.ProgrammaticCampaigns.Accounts.Get.AccountEngagement(id),
    {
      params: {
        yearFrom: filters.yearFrom,
        yearTo: filters.yearTo,
        weekFrom: filters.weekFrom,
        weekTo: filters.weekTo,
      },
    }
  );
  return data;
};

export const getAccountAndPersonasInsights = async (
  id: number,
  filters: DisplayPerformanceFilters
): Promise<GetProgrammaticCampaignAccountAndPersonaInsightsRO> => {
  const { data } = await apiService.get(
    Endpoints.ProgrammaticCampaigns.Accounts.Get.AccountAndPersonasInsights(id),
    {
      params: {
        yearFrom: filters.yearFrom,
        yearTo: filters.yearTo,
        weekFrom: filters.weekFrom,
        weekTo: filters.weekTo,
      },
    }
  );
  return data;
};

export const getEngagementAcrossPersonas = async (
  id: number,
  filters: DisplayPerformanceFilters
): Promise<GetProgrammaticCampaignEngagementAcrossPersonasRO> => {
  const { data } = await apiService.get(
    Endpoints.ProgrammaticCampaigns.Accounts.Get.EngagementAcrossPersonas(id),
    {
      params: {
        yearFrom: filters.yearFrom,
        yearTo: filters.yearTo,
        weekFrom: filters.weekFrom,
        weekTo: filters.weekTo,
      },
    }
  );
  return data;
};
