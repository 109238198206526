import { useQuery } from "@tanstack/react-query";
import {
  getCampaignDetailsAggregatedSignals,
  getCampaignDetailsAvailableSignals,
  getCampaignDetailsFilters,
  getCampaignDetailsTopLocations,
  getCampaignSignals,
} from "../CampaignDetails.requests";
import {
  CampaignDetailsAggregatedSignal,
  CampaignDetailsAvailableSignals,
  CampaignDetailsTopLocations,
} from "./CampaignDetailsTable.types";

const useCampaignSignalsAndFilters = (
  campaignId: string,
  weekNumber: number,
  yearNumber: number
) => {
  const signals = useQuery(["signals"], getCampaignSignals);
  const filters = useQuery(
    [`campaignDetailsFilters`, campaignId, weekNumber, yearNumber],
    () => getCampaignDetailsFilters(campaignId, weekNumber, yearNumber)
  );

  return {
    signals,
    filters,
    error: signals.isError || filters.isError,
  };
};

const useAvailableSignals = ({
  campaignId,
  domainId,
  weekNumber,
  yearNumber,
}: CampaignDetailsAvailableSignals) => {
  return useQuery(
    [
      "campaignDetailsAvailableSignals",
      campaignId,
      domainId,
      weekNumber,
      yearNumber,
    ],
    () =>
      getCampaignDetailsAvailableSignals({
        campaignId,
        domainId,
        weekNumber,
        yearNumber,
      })
  );
};

const useSignal = <T>({
  campaignId,
  domainId,
  signalId,
  weekNumber,
  yearNumber,
}: CampaignDetailsAggregatedSignal) => {
  return useQuery<T>(
    [
      `campaignDetailsAggregatedSignals`,
      campaignId,
      domainId,
      signalId,
      weekNumber,
      yearNumber,
    ],
    () =>
      getCampaignDetailsAggregatedSignals({
        campaignId,
        domainId,
        signalId,
        weekNumber,
        yearNumber,
      })
  );
};

const useTopLocations = ({
  campaignId,
  domainId,
  weekNumber,
  yearNumber,
}: CampaignDetailsTopLocations) => {
  return useQuery(
    [
      `campaignDetailsTopLocations`,
      campaignId,
      domainId,
      weekNumber,
      yearNumber,
    ],
    () =>
      getCampaignDetailsTopLocations({
        campaignId,
        domainId,
        weekNumber,
        yearNumber,
      })
  );
};

export {
  useAvailableSignals,
  useCampaignSignalsAndFilters,
  useSignal,
  useTopLocations,
};
