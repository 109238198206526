import { Box, Spinner, Stack, Text } from "@chakra-ui/react";
import { Option, Range } from "@intentsify/types";
import {
  Button,
  Filter,
  FiltersContainer,
  FiltersContainerItem,
  LabeledSwitch,
  RangeFilter,
  Select,
} from "components";
import {
  CampaignDetailsFiltersOptions,
  TopicStage,
} from "screens/Campaigns/screens/CampaignDetails/CampaignDetails.types";

type FiltersProps = {
  isLoadingFilters: boolean;
  isLoadingResults: boolean;
  filtersData?: CampaignDetailsFiltersOptions;
  setCampaignDetailsFilters: (
    val:
      | Record<string, Option[]>
      | Record<string, Range>
      | Record<string, boolean>
      | Record<string, TopicStage>
  ) => void;
  onApplyFilters: () => void;

  resultsCount: number | undefined;
  isPreviousWeek: boolean;
  hasLeadsDisabled?: boolean;
  hasLeadsDefaultValue?: boolean;
  selectedFilters?: CampaignDetailsFiltersOptions;
};

const stageOptions = [
  { label: "Early", value: "Early" },
  { label: "Late", value: "Late" },
];

const Filters = ({
  isLoadingFilters,
  isLoadingResults,
  filtersData,
  setCampaignDetailsFilters,
  onApplyFilters,
  resultsCount,
  isPreviousWeek,
  hasLeadsDisabled,
  hasLeadsDefaultValue,
  selectedFilters,
}: FiltersProps) => {
  const items: FiltersContainerItem[] = [
    {
      component: (
        <Filter
          options={filtersData?.buyerResearchStage ?? []}
          isLoading={isLoadingFilters}
          label="Buyer Research Stage"
          placeholder="Select Buyer Research"
          onFilterValuesChange={(option) => {
            setCampaignDetailsFilters({ buyerResearchStage: option });
          }}
        />
      ),
    },
    {
      component: (
        <Filter
          options={filtersData?.keywords ?? []}
          selected={selectedFilters?.keywords}
          isLoading={isLoadingFilters}
          label="Keywords"
          placeholder="Select keywords"
          onFilterValuesChange={(option) => {
            setCampaignDetailsFilters({ keywords: option });
          }}
        />
      ),
    },
    {
      component: (
        <Filter
          options={filtersData?.topics ?? []}
          isLoading={isLoadingFilters}
          selected={selectedFilters?.topics}
          label="Topics"
          placeholder="Select topics"
          onFilterValuesChange={(option) => {
            setCampaignDetailsFilters({ topics: option });
          }}
        />
      ),
    },
    {
      component: (
        <Stack>
          <Text fontSize="xs" fontWeight="semibold">
            Topic stage:
          </Text>
          <Select
            isMulti={false}
            options={stageOptions}
            value={stageOptions.find(
              (option) => option.value === filtersData?.topicStage
            )}
            placeholder="Select topic stage"
            onChange={(option) => {
              const selectedOption = option ? option.value : "";

              setCampaignDetailsFilters({
                topicStage: selectedOption as TopicStage,
              });
            }}
          />
        </Stack>
      ),
    },
    {
      component: (
        <Filter
          options={filtersData?.domains ?? []}
          isLoading={isLoadingFilters}
          selected={selectedFilters?.domains}
          label="Accounts"
          placeholder="Select accounts"
          onFilterValuesChange={(option) => {
            setCampaignDetailsFilters({ domains: option });
          }}
        />
      ),
    },
    {
      component: (
        <Filter
          options={filtersData?.locations ?? []}
          isLoading={isLoadingFilters}
          label="Locations"
          placeholder="Select locations"
          onFilterValuesChange={(option) => {
            setCampaignDetailsFilters({ locations: option });
          }}
        />
      ),
    },
    {
      component: (
        <Filter
          options={filtersData?.customSources ?? []}
          isLoading={isLoadingFilters}
          label="Custom Sources"
          placeholder="Select Custom Sources"
          onFilterValuesChange={(option) => {
            setCampaignDetailsFilters({ customSources: option });
          }}
        />
      ),
    },
    {
      component: (
        <RangeFilter
          label="Active Sources"
          step={1}
          min={0}
          max={10}
          onChange={(range) => {
            setCampaignDetailsFilters({
              activeSources: { from: range[0], to: range[1] },
            });
          }}
        />
      ),
    },
    {
      component: (
        <RangeFilter
          label="Signal Strength"
          step={1}
          min={0}
          max={10}
          onChange={(range) => {
            setCampaignDetailsFilters({
              signalStrength: { from: range[0], to: range[1] },
            });
          }}
        />
      ),
    },

    {
      component: (
        <LabeledSwitch
          size="sm"
          leftLabel="Off"
          rightLabel="On"
          label="Website Activity"
          onChange={(isChecked) =>
            setCampaignDetailsFilters({ hasWebsiteActivity: isChecked })
          }
        />
      ),
    },
    {
      component: (
        <LabeledSwitch
          size="sm"
          leftLabel="Off"
          rightLabel="On"
          label="Leads"
          disabled={hasLeadsDisabled}
          defaultChecked={hasLeadsDefaultValue}
          onChange={(isChecked) =>
            setCampaignDetailsFilters({ hasLeads: isChecked })
          }
        />
      ),
    },
    {
      component: (
        <LabeledSwitch
          size="sm"
          leftLabel="Off"
          rightLabel="On"
          label="Impressions"
          onChange={(isChecked) =>
            setCampaignDetailsFilters({ hasImpressions: isChecked })
          }
        />
      ),
    },
    {
      component: (
        <LabeledSwitch
          size="sm"
          leftLabel="Off"
          rightLabel="On"
          label="Clicks"
          onChange={(isChecked) =>
            setCampaignDetailsFilters({ hasClicks: isChecked })
          }
        />
      ),
    },
    {
      component: isPreviousWeek ? (
        <LabeledSwitch
          size="sm"
          leftLabel="Off"
          rightLabel="On"
          label="Spotlight Ready Accounts"
          onChange={(isChecked) =>
            setCampaignDetailsFilters({ hasSpotlight: isChecked })
          }
        />
      ) : (
        <></>
      ),
    },
  ];

  return (
    <FiltersContainer
      items={items}
      isExpandable
      footer={
        <Stack
          direction="row"
          spacing={4}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Box>
            <Text>
              Result count:{" "}
              {isLoadingResults ? <Spinner size="xs" /> : resultsCount}
            </Text>
          </Box>
          <Button size="sm" variant="secondary" onClick={onApplyFilters}>
            Apply Filters
          </Button>
        </Stack>
      }
    />
  );
};

export { Filters };
