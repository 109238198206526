import {
  Box,
  Flex,
  Input,
  Spinner,
  Stack,
  Tag,
  TagLabel,
  Text,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { useColorModeValue, useMultiStyleConfig } from "@chakra-ui/system";
import { BaseMetaData, Node } from "@intentsify/types";
import {
  flattenNodes,
  getLeafNodes,
  isDefined,
  isPopulatedArray,
} from "@intentsify/utils";
import { Popover, Tree, useTree } from "components";
import isEqual from "lodash/isEqual";
import { useCallback, useMemo, useRef, useState } from "react";
import { useDebounce, useMeasure } from "react-use";
import { useDeepEffect } from "utils";
import { InputTags, SelectedList } from "./components";

type TreeFilterProps<F extends BaseMetaData, T extends Node<F>> = {
  label?: string;
  subject: string;
  nodes: T[];
  included: T[];
  onIncludedChange: (nodes: T[]) => void;
  searchPlaceholder?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  expandOnInitialRender?: boolean;
};

const TreeFilter = <F extends BaseMetaData, T extends Node<F> = Node<F>>({
  label,
  subject,
  nodes,
  included,
  onIncludedChange,
  searchPlaceholder,
  isLoading = false,
  isDisabled = false,
  expandOnInitialRender = false,
}: TreeFilterProps<F, T>) => {
  const [ref, { width: containerWidth }] = useMeasure<HTMLDivElement>();
  const [internalState, setInternalState] = useState<(string | number)[]>(
    included.map((i) => i.value)
  );
  const spinnerColor = useColorModeValue("gray.500", "gray.100");
  const selectedBgColor = useColorModeValue(
    "rgba(0,0,0,0.05)",
    "rgba(255,255,255,0.05)"
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const wrapperRef = useRef<HTMLDivElement>(null);

  useOutsideClick({
    ref: wrapperRef,
    handler: () => onClose(),
  });

  useDeepEffect(() => {
    setInternalState(included.map((i) => i.value));
  }, [included, setInternalState]);

  /**
   * I want to maintain the expanded items list between re-renders
   */
  const expandedValuesRef = useRef<(string | number)[]>([]);

  const { expandedValues, setExpandedValues, onReset } = useTree({
    checked: [],
    expanded: expandedValuesRef.current,
  });

  const leafs = useMemo(() => getLeafNodes(nodes), [nodes]);

  useDebounce(
    () => {
      const newNodes = leafs.filter((n) => internalState.includes(n.value));

      if (!isEqual(included, newNodes) && isPopulatedArray(leafs)) {
        onIncludedChange(newNodes);
      }
    },
    500,
    [internalState, onIncludedChange]
  );

  const onRemoveNode = useCallback(
    (value: string | number) => {
      const without = internalState.filter((v) => v !== value);

      setInternalState(without);
    },
    [setInternalState, internalState]
  );

  const selected = useMemo(() => {
    return flattenNodes(nodes)
      .filter((n) => n && internalState.includes(n.value))
      .filter(isDefined);
  }, [nodes, internalState]);

  const inputStyles = useMultiStyleConfig("Input", { size: "sm" });
  /**
   * I want to avoid the re-render each time I slice the array,
   * hence join in the useMemo deps
   */
  const selectedSubset = useMemo(() => {
    return selected.slice(0, 15);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <Stack>
      {label && (
        <Text fontSize="xs" fontWeight="semibold">
          {label}:
        </Text>
      )}

      <Flex ref={ref} width="100%">
        <Popover
          isOpen={isOpen}
          onOpen={() => {
            if (expandOnInitialRender) {
              setExpandedValues(flattenNodes(nodes).map((i) => i.value));
            }
          }}
          width={containerWidth + 300}
          height="400px"
          popoverTrigger={
            <Box onClick={() => onOpen()} w="100%">
              {!isPopulatedArray(selectedSubset) ? (
                <Box position="relative" w="100%">
                  <Input
                    borderRadius="md"
                    size="sm"
                    placeholder={`Select ${subject} `}
                    isDisabled={isDisabled}
                  />
                  {isLoading && (
                    <Spinner
                      position="absolute"
                      top="2"
                      right="2"
                      color={spinnerColor}
                      size="sm"
                    />
                  )}
                </Box>
              ) : (
                <Flex
                  sx={{
                    ...inputStyles.field,
                    height: "auto",
                    maxH: "auto",
                    flexWrap: "wrap",
                    minH: "33px",
                    borderRadius: "md",
                    alignItems: "center",
                    maxW: "500px",
                    // _disabled exists on field, it's just not typed
                    // https://github.com/chakra-ui/chakra-ui/issues/6261
                    // @ts-ignore
                    ...(isDisabled ? inputStyles.field._disabled : {}),
                  }}
                >
                  <InputTags items={selectedSubset} />

                  {selectedSubset.length < selected.length && (
                    <Tag size="sm" h="22px" m="2px">
                      <TagLabel>
                        and {selected.length - selectedSubset.length} more...
                      </TagLabel>
                    </Tag>
                  )}
                </Flex>
              )}
            </Box>
          }
          popoverBody={
            <Flex h="100%" ref={wrapperRef}>
              <Flex p={2} w="60%">
                <Tree
                  size="sm"
                  showSelected={false}
                  subject="regions"
                  nodes={nodes}
                  checked={internalState}
                  expanded={expandedValues}
                  onCheck={(checked) => setInternalState(checked)}
                  onExpand={(expanded) => {
                    expandedValuesRef.current = expanded;
                    setExpandedValues(expanded);
                  }}
                  onReset={onReset}
                  focusOnRender
                  searchAsYouType
                  searchWidth="100%"
                  searchPlaceholder={searchPlaceholder}
                />
              </Flex>
              <Flex flexDirection="column" p={2} bg={selectedBgColor} w="40%">
                <Text mb={2} fontSize="xs">
                  Selected ({selected.length}):
                </Text>

                <Flex overflowY="auto" flexDirection="column">
                  <SelectedList
                    selected={selected}
                    onRemoveNode={onRemoveNode}
                  />
                </Flex>
              </Flex>
            </Flex>
          }
        />
      </Flex>
    </Stack>
  );
};

export { TreeFilter };
