import { LeadsPerformanceRO, WeeklySnapshotRangeDTO } from "@intentsify/dto";
import { apiService, Endpoints } from "api";

export const getLeadsPerformance = async (
  campaignId: number,
  filters: WeeklySnapshotRangeDTO
) => {
  const { data } = await apiService.get<LeadsPerformanceRO>(
    Endpoints.Campaigns.Get.WeeklySnapshot.LeadsPerformance(campaignId),
    {
      params: {
        yearFrom: filters.yearFrom,
        weekFrom: filters.weekFrom,
        yearTo: filters.yearTo,
        weekTo: filters.weekTo,
      },
    }
  );

  return data;
};
