import { useMemo, useState } from "react";
import { Text, useColorModeValue } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { toNumberDisplayValue } from "@intentsify/utils";
import { getCreativeFatigueMap } from "./CreativeFatigueMap.requests";
import { Card, Scatter, MIN_MODAL_CONTENT_HEIGHT } from "components";
import { ChartExport, useExportableChart } from "shared/components";
import { DisplayPerformanceFilters } from "screens/CampaignPacing/screens/DisplayPerformance/DisplayPerformance";
import { colors } from "theme";

type CreativeFatigueMapProps = {
  campaignId: number;
  filters: DisplayPerformanceFilters;
};

const CreativeFatigueMap = ({
  campaignId,
  filters,
}: CreativeFatigueMapProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { data, isFetching } = useQuery(
    ["getCreativeFatigueMap", campaignId, filters],
    () => getCreativeFatigueMap(campaignId, filters)
  );

  const dataFormatted = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map((i) => ({
      x: i.frequency,
      y: i.ctr * 100,
      label: i.creativeName,
    }));
  }, [data]);

  const benchmarkLineColor = useColorModeValue(
    colors.yellow["500"],
    colors.yellow["300"]
  );

  const chart = useExportableChart({
    title: "Creative Fatigue Map",
    campaignId: Number(campaignId),
    chart: (
      <Scatter
        minHeight={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : undefined}
        xAxisLabel="Frequency"
        yAxisLabel="CTR (%)"
        yAxisBenchmark={[
          {
            value: 0.05,
            label: "B2B CTR Benchmark",
            color: benchmarkLineColor,
            strokeDasharray: "10,5",
            strokeWidth: "3px",
          },
        ]}
        data={dataFormatted}
        tooltipRenderer={(textColor, item) => {
          const dataPoint = data?.find((i) => i.creativeName === item.label);

          if (!dataPoint) {
            return null;
          }

          return (
            <>
              <Text maxW="400px" fontSize="medium" color={textColor}>
                <b>Creative Name:</b> {dataPoint.creativeName}
              </Text>
              <Text fontSize="medium" color={textColor}>
                <b>Frequency:</b> {toNumberDisplayValue(dataPoint.frequency)}
              </Text>
              <Text fontSize="medium" color={textColor}>
                <b>CTR:</b> {toNumberDisplayValue(dataPoint.ctr * 100)}%
              </Text>
              <Text fontSize="medium" color={textColor}>
                <b>Impressions:</b> {dataPoint.impressions}
              </Text>
            </>
          );
        }}
      />
    ),
  });

  const csvExportData = useMemo(() => {
    return dataFormatted.map((i) => ({
      CTR: `${toNumberDisplayValue(i.y)}%`,
      Frequency: toNumberDisplayValue(i.x),
      "Creative Name": i.label,
    }));
  }, [dataFormatted]);

  return (
    <Card
      onExpandedChange={setIsExpanded}
      isLoading={isFetching}
      title={chart.title}
      titleTooltip="This visualization plots out specific creatives and their respective CTR x frequency to understand creative fatigue over time for the campaign."
      isExpandable
      actions={
        <ChartExport
          size="small"
          campaignId={Number(campaignId)}
          title={chart.title}
          onExportPNG={chart.downloadAsPng}
          data={csvExportData}
        />
      }
    >
      {chart.component}
      {chart.exportComponent}
    </Card>
  );
};

export { CreativeFatigueMap };
