import { Collapse, Td, Tr } from "@chakra-ui/react";
import { RowData, Row as RowType } from "@tanstack/react-table";
import { Selectable } from "components/Table/TableVirtualised";
import { Cell } from "../Cell";

const Row = <T extends RowData>({
  row,
  renderExpandableRowComponent,
  colSpan,
  selected,
  height = "auto",
  onClick,
  selectable,
  isCentered = false,
}: {
  row: RowType<T>;
  renderExpandableRowComponent?: (row: RowType<T>) => React.ReactNode;
  colSpan: number;
  selected?: boolean;
  height?: string;
  onClick?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
  selectable?: Selectable;
  isCentered?: boolean;
}) => {
  return (
    <>
      <Tr
        style={{
          background:
            selected && selectable === "clickable-single-select"
              ? "rgba(95, 221, 188, 0.3)"
              : undefined,
        }}
        onClick={onClick}
        height={height}
      >
        {row.getVisibleCells().map((cell) => {
          return <Cell key={cell.id} cell={cell} isCentered={isCentered} />;
        })}
      </Tr>

      {row.getIsExpanded() && renderExpandableRowComponent && (
        <Tr>
          <Td colSpan={colSpan} borderBottom={"0px"}>
            <Collapse in={row.getIsExpanded()} animateOpacity>
              {renderExpandableRowComponent(row)}
            </Collapse>
          </Td>
        </Tr>
      )}
    </>
  );
};

export { Row };
