import { Box, Flex } from "@chakra-ui/react";
import { SplitBy } from "@intentsify/types";
import { getDatesFromDateRange, isPopulatedArray } from "@intentsify/utils";
import { useMemo } from "react";
import { AiOutlineLineChart, AiOutlineTable } from "react-icons/ai";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Filters } from "./components";
import { TrendingKeywordsTable } from "./components/TrendingKeywordsTable";
import {
  getMatchTermsActivity,
  getMostActiveKeywords,
} from "./TrendingKeywords.requests";
import {
  keywordsViewAtom,
  selectedTrendingKeywordsAtom,
  trendingKeywordsOptionsStateAtom,
  trendingKeywordsStateSelector,
} from "./TrendingKeywords.state";
import { timeseriesToCsvData } from "utils";
import {
  ChartExport,
  useCampaignMeta,
  useExportableChart,
} from "shared/components";
import { ViewEnum } from "screens/IntentAnalysis/screens/CampaignsTrendingSignals/CampaignsTrendingSignals.types";
import {
  AccountChangeTooltip,
  Timeseries,
  ViewContainer,
  ViewSelector,
} from "components";

const title = "Trending Keywords";

const TrendingKeywords = () => {
  const { campaignId } = useParams<"campaignId">();
  const {
    isLoading,
    selectedView,
    selectedDateRange,
    selectedSplitBy,
    selectedKeywords,
  } = useRecoilValue(trendingKeywordsStateSelector);
  const setSelectedKeywords = useSetRecoilState(selectedTrendingKeywordsAtom);
  const setOptions = useSetRecoilState(trendingKeywordsOptionsStateAtom);
  const setSelectedView = useSetRecoilState(keywordsViewAtom);

  const { campaignMeta: meta } = useCampaignMeta({ campaignId });

  useQuery(
    ["trendingKeywordsOptions", campaignId, selectedDateRange],
    () => {
      if (campaignId) {
        return getMostActiveKeywords(
          campaignId,
          selectedDateRange,
          SplitBy.Nothing
        );
      }
    },
    {
      onSuccess: (data) => {
        if (data) {
          setOptions({ isLoading: false, ...data });

          setSelectedKeywords({
            campaignId,
            data: data.keywords.slice(0, 5),
          });
        }
      },
    }
  );

  const { data, isFetching } = useQuery(
    [
      "campaignTopChangesKeywordsChange",
      campaignId,
      selectedDateRange,
      selectedSplitBy,
      selectedKeywords.data.map((k) => k.value).join(),
    ],
    () => {
      if (campaignId) {
        return getMatchTermsActivity({
          campaignId,
          dateRange: selectedDateRange,
          splitBy: selectedSplitBy,
          signals: selectedKeywords.data,
        });
      }
    },
    {
      enabled: !isLoading,
    }
  );

  const { start, end } = getDatesFromDateRange(
    selectedDateRange,
    new Date(meta?.campaignStartDate ?? ""),
    new Date(meta?.campaignEndDate ?? "")
  );

  const dates = useMemo(() => {
    return {
      start: start.toISO(),
      end: end.toISO(),
    };
  }, [start, end]);

  const chart = useExportableChart({
    title,
    campaignId: Number(campaignId),
    dates,
    chart: (
      <Timeseries
        noDataMessage={
          !isPopulatedArray(selectedKeywords.data)
            ? "No data found."
            : undefined
        }
        stretch={true}
        dataMainKey="label"
        isLoading={isFetching || isLoading}
        data={data?.data ?? []}
        dataKeyLabels={{ value: "Intent Signals", accounts: "Accounts" }}
        timeseries={data?.timeseries ?? []}
        tooltipRenderer={AccountChangeTooltip}
        snapToDateTime
      />
    ),
  });

  return (
    <ViewContainer
      withBackground
      expandable={true}
      title={title}
      actionItems={
        <Flex>
          <ViewSelector
            items={[
              {
                value: ViewEnum.Table,
                onClick: setSelectedView,
                label: "Table",
                icon: <AiOutlineTable />,
              },
              {
                value: ViewEnum.TimeSeries,
                onClick: setSelectedView,
                label: "Timeseries",
                icon: <AiOutlineLineChart />,
              },
            ]}
            selected={selectedView}
          />

          <Box ml={2}>
            <ChartExport
              dates={dates}
              campaignId={Number(campaignId)}
              title={chart.title}
              onExportPNG={chart.downloadAsPng}
              data={timeseriesToCsvData(
                data,
                "label",
                { value: "Signals", accounts: "Accounts" },
                {
                  accounts: (item: { account: string; count: number }[]) => {
                    return item
                      .map((a) => `${a.account} (${a.count})`)
                      .join(", ");
                  },
                }
              )}
            />
          </Box>
        </Flex>
      }
    >
      <Filters />

      {selectedView === ViewEnum.Table && (
        <TrendingKeywordsTable
          isLoading={isFetching || isLoading}
          data={data}
        />
      )}

      {selectedView === ViewEnum.TimeSeries && (
        <Flex flexGrow={1}>{chart.component}</Flex>
      )}

      {chart.exportComponent}
    </ViewContainer>
  );
};

export { TrendingKeywords };
