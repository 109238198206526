import { AxiosError, AxiosInstance, isAxiosError } from "axios";
import { queryClient } from "queryClient/PortalQueryClientProvider";
import { HttpStatus } from "types";
import { CustomApiError } from "utils";
import { axiosMock } from "../test/http-mock/axiosMock";
import { refreshAccessToken, signOutRequest } from "./api.requests";
import { isAuthRefreshing, setIsAuthRefreshing } from "./isAuthRefreshing";

export const makeApiAxiosServiceProxy = (apiService: AxiosInstance) => {
  const isJestTest = process.env.JEST_WORKER_ID !== undefined;

  if (isJestTest) {
    return axiosMock;
  }

  apiService.interceptors.response.use(undefined, async (error) => {
    if (
      !isAuthRefreshing(apiService) &&
      error instanceof AxiosError &&
      error.response?.status === HttpStatus.UNAUTHORIZED &&
      error.response?.data?.internalCode !== "401PersonalInfoExpiredToken"
    ) {
      try {
        setIsAuthRefreshing(apiService, true);
        await refreshAccessToken();
        setIsAuthRefreshing(apiService, false);
        queryClient.cancelQueries();
        queryClient.invalidateQueries();
      } catch {
        setIsAuthRefreshing(apiService, false);

        void signOutRequest();

        throw new CustomApiError(
          error.message,
          {
            statusCode: error.response?.status ?? 0,
            internalCode: error.response?.data?.internalCode,
            message: error.response?.data?.message,
            metadata: error.response?.data?.metadata,
          },
          error.response?.config,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          error.request?.responseURL
        );
      }
    }

    if (error.response && isAxiosError(error)) {
      throw new CustomApiError(
        error.message,
        {
          statusCode: error.response?.status ?? 0,
          internalCode: error.response?.data?.internalCode,
          message: error.response?.data?.message,
          metadata: error.response?.data?.metadata,
        },
        error.response?.config,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        error.request?.responseURL
      );
    }

    // probably a network error, or some js error like a TypeError
    if (error instanceof Error) {
      throw error;
    }
  });

  return apiService;
};

export const makeApiFetchServiceProxy = (
  fetch: WindowOrWorkerGlobalScope["fetch"]
) => {
  setIsAuthRefreshing(fetch, false);

  return new Proxy(fetch, {
    async apply(
      target,
      thisArg,
      args: [input: RequestInfo | URL, init?: RequestInit | undefined]
    ) {
      const response = await target.apply(thisArg, args);

      if (
        !isAuthRefreshing(fetch) &&
        response.status === HttpStatus.UNAUTHORIZED
      ) {
        try {
          setIsAuthRefreshing(fetch, true);
          await refreshAccessToken();
          setIsAuthRefreshing(fetch, false);
          queryClient.cancelQueries();
          queryClient.invalidateQueries();
        } catch {
          setIsAuthRefreshing(fetch, false);

          void signOutRequest();

          throw new CustomApiError(response.statusText, {
            statusCode: HttpStatus.UNAUTHORIZED,
          });
        }
      }

      return response;
    },
  });
};
