import { Box, Flex, Text } from "@chakra-ui/layout";
import { PropsWithChildren } from "react";
import { HttpStatus, isCustomApiError } from "types";
import { OptOutDisplayError } from "./OptOutDisplayError";
import { optOutSupportEmail } from "./supportEmail";

interface OptOutErrorProps {
  error?: unknown | undefined | null;
}

export function OptOutError(props: OptOutErrorProps) {
  if (!props.error) {
    return null;
  }

  if (!isCustomApiError(props.error)) {
    return (
      <Container>
        <OptOutDisplayError />
      </Container>
    );
  }

  if (props.error.response.statusCode === HttpStatus.NOT_FOUND) {
    return (
      <Container>
        <NotFound />
      </Container>
    );
  }

  if (props.error.response.statusCode === HttpStatus.UNAUTHORIZED) {
    return (
      <Container>
        <Unauthorized />
      </Container>
    );
  }

  if (props.error.response.statusCode === HttpStatus.FORBIDDEN) {
    return (
      <Container>
        <Forbidden />
      </Container>
    );
  }

  return <OptOutDisplayError />;
}

function Container(props: PropsWithChildren) {
  return (
    <Box w="full" maxWidth="lg" mx="auto" pt="4vh">
      {props.children}
    </Box>
  );
}

function NotFound() {
  return (
    <Flex
      flexDir="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      gap="2"
    >
      <Text fontSize="xl">Your personal information could not be found.</Text>

      <Text color="blackAlpha.600" _dark={{ color: "whiteAlpha.600" }}>
        We have received your request but we could not find your personal
        information held by Intentsify.
      </Text>

      <Text
        color="blackAlpha.600"
        _dark={{ color: "whiteAlpha.600" }}
        style={{ textWrap: "balance" } as any}
      >
        If you have any questions, please contact support at{" "}
        <a href={`mailto:${optOutSupportEmail}`}>{optOutSupportEmail}</a>.
      </Text>
    </Flex>
  );
}

function Unauthorized() {
  return (
    <Flex
      flexDir="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      gap="2"
    >
      <Text fontSize="xl">Token has expired</Text>

      <Text color="blackAlpha.600" _dark={{ color: "whiteAlpha.600" }}>
        We have received your request but your token has expired. Please retry
        your opt out request.
      </Text>

      <Text
        color="blackAlpha.600"
        _dark={{ color: "whiteAlpha.600" }}
        style={{ textWrap: "balance" } as any}
      >
        If you have any questions, please contact support at{" "}
        <a href={`mailto:${optOutSupportEmail}`}>{optOutSupportEmail}</a>.
      </Text>
    </Flex>
  );
}

function Forbidden() {
  return (
    <Flex
      flexDir="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      gap="2"
    >
      <Text fontSize="xl">Forbidden action</Text>

      <Text color="blackAlpha.600" _dark={{ color: "whiteAlpha.600" }}>
        We have received your request but you are not authorized to perform this
        action.
      </Text>

      <Text
        color="blackAlpha.600"
        _dark={{ color: "whiteAlpha.600" }}
        style={{ textWrap: "balance" } as any}
      >
        If you have any questions, please contact support at{" "}
        <a href={`mailto:${optOutSupportEmail}`}>{optOutSupportEmail}</a>.
      </Text>
    </Flex>
  );
}
