import { DateRange, Option, SignalType, SplitBy } from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { Endpoints, apiService } from "api";
import { TrendingTopicsOptions } from "screens/IntentAnalysis/screens/CampaignsTrendingSignals/CampaignsTrendingSignals.types";
import { valueToQueryURL } from "utils";
import { TrendingTopicsWithTimeseriesResponse } from "./TrendingTopics.types";

export const getMostActiveTopics = async (
  id: string,
  dateRange: DateRange,
  splitBy: SplitBy
): Promise<Omit<TrendingTopicsOptions, "isLoading">> => {
  const { data } = await apiService.get(
    Endpoints.Campaigns.Get.Signals.MostActiveSignals(Number(id)),
    {
      params: {
        dateRange,
        signalType: SignalType.Topics,
        splitBy,
      },
    }
  );
  return data;
};

type MostActiveTopicsParams = {
  campaignId: string | undefined;
  signals: Option<string>[] | undefined;
  dateRange: DateRange;
  splitBy: SplitBy;
};

export const getMostActiveTopicsTimeseries = async ({
  campaignId,
  dateRange,
  splitBy,
  signals,
}: MostActiveTopicsParams): Promise<TrendingTopicsWithTimeseriesResponse> => {
  if (!campaignId || !isPopulatedArray(signals)) {
    return { data: [], timeseries: [] };
  }

  const { data } = await apiService.get<TrendingTopicsWithTimeseriesResponse>(
    Endpoints.Campaigns.Get.Signals.SignalActivityWithTimeseries(
      Number(campaignId)
    ),
    {
      params: {
        signals: valueToQueryURL(signals),
        dateRange,
        splitBy,
        signalType: SignalType.Topics,
      },
    }
  );
  return data;
};
