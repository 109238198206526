import {
  CampaignImpressionsByLocationRO,
  CampaignTopicsByLocationRO,
  WeeklySnapshotRangeDTO,
} from "@intentsify/dto";
import { apiService, Endpoints } from "api";

export const getTopicsByLocation = async (
  id: number,
  filters: WeeklySnapshotRangeDTO
): Promise<CampaignTopicsByLocationRO> => {
  const { data } = await apiService.get<CampaignTopicsByLocationRO>(
    Endpoints.Campaigns.Get.WeeklySnapshot.TopicsByLocation(id),
    {
      params: filters,
    }
  );

  return data;
};

export const getImpressionsByLocation = async (
  id: number,
  filters: WeeklySnapshotRangeDTO
): Promise<CampaignImpressionsByLocationRO> => {
  const { data } = await apiService.get<CampaignImpressionsByLocationRO>(
    Endpoints.Campaigns.Get.WeeklySnapshot.ImpressionsByLocation(id),
    {
      params: filters,
    }
  );

  return data;
};
