import { GetProgrammaticCampaignPerformanceByDeviceRO } from "@intentsify/dto";
import { apiService, Endpoints } from "api";
import { DisplayPerformanceFilters } from "screens/CampaignPacing/screens/DisplayPerformance";

export const getPerformanceByDevice = async (
  id: number,
  filters: DisplayPerformanceFilters
): Promise<GetProgrammaticCampaignPerformanceByDeviceRO> => {
  const { data } = await apiService.get(
    Endpoints.ProgrammaticCampaigns.ImpressionsAndClicks.Get.PerformanceByDevice(
      id
    ),
    {
      params: {
        yearFrom: filters.yearFrom,
        yearTo: filters.yearTo,
        weekFrom: filters.weekFrom,
        weekTo: filters.weekTo,
      },
    }
  );

  return data;
};
