import { FormControl, FormLabel, Stack, Switch, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

type LabeledSwitchProps = {
  rightLabel: ReactNode;
  onChange: (isChecked: boolean) => void;
  label?: string;
  defaultChecked?: boolean;
  leftLabel?: ReactNode;
  isChecked?: boolean;
  size?: "md" | "sm" | "lg";
  disabled?: boolean;
};

const LabeledSwitch = ({
  isChecked,
  defaultChecked,
  leftLabel,
  rightLabel,
  onChange,
  label,
  size = "md",
  disabled,
}: LabeledSwitchProps) => {
  return (
    <Stack>
      {label && (
        <Text fontSize="xs" fontWeight="semibold">
          {label}:
        </Text>
      )}
      <FormControl display="flex" alignItems="center" width="unset">
        <FormLabel
          marginY="0"
          marginX="0"
          marginRight="2"
          onClick={() => onChange(false)}
        >
          {leftLabel}
        </FormLabel>

        <Switch
          disabled={disabled}
          isChecked={isChecked}
          defaultChecked={Boolean(defaultChecked)}
          onChange={(e) => onChange(e.currentTarget.checked)}
          size={size}
        />

        <FormLabel
          marginY="0"
          marginX="0"
          marginLeft="2"
          onClick={() => onChange(true)}
        >
          {rightLabel}
        </FormLabel>
      </FormControl>
    </Stack>
  );
};

export { LabeledSwitch };
