import {
  DateRange,
  defaultDateRangeFilters,
  Option,
  SplitBy,
} from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import {
  DateRangeFilter,
  Filter,
  FiltersContainer,
  FiltersContainerItem,
  SplitByFilter,
} from "components";
import { useParams } from "react-router-dom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import {
  keywordsSplitByAtom,
  selectedTrendingKeywordsAtom,
  trendingKeywordsDateRangeAtom,
  trendingKeywordsOptionsStateAtom,
  trendingKeywordsStateSelector,
} from "../../TrendingKeywords.state";

const Filters = () => {
  const { campaignId } = useParams<"campaignId">();
  const {
    selectedKeywords,
    isLoading,
    keywordsOptions,
    selectedSplitBy,
    selectedDateRange,
  } = useRecoilValue(trendingKeywordsStateSelector);
  const setSelectedKeywords = useSetRecoilState(selectedTrendingKeywordsAtom);
  const resetOptions = useResetRecoilState(trendingKeywordsOptionsStateAtom);
  const setSplitBy = useSetRecoilState(keywordsSplitByAtom);
  const setDateRange = useSetRecoilState(trendingKeywordsDateRangeAtom);

  const onSelectedKeywordsChange = (options: Array<Option<string>>) => {
    setSelectedKeywords({ campaignId, data: options });
  };

  const hasNoOptions = !isPopulatedArray(keywordsOptions);

  const items: FiltersContainerItem[] = [
    {
      component: (
        <Filter
          minW={"400px"}
          label={"Keywords"}
          options={keywordsOptions}
          isLoading={isLoading}
          isDisabled={isLoading || hasNoOptions}
          placeholder="Select keywords"
          onFilterValuesChange={(options: Option[]) => {
            onSelectedKeywordsChange(options as Array<Option<string>>);
          }}
          selected={selectedKeywords.data ?? []}
        />
      ),
      size: "xl",
    },
    {
      component: (
        <DateRangeFilter<DateRange>
          isLoading={isLoading}
          isDisabled={isLoading}
          dateRange={selectedDateRange}
          options={defaultDateRangeFilters.dateRangeOptions}
          onChange={(value) => {
            resetOptions();
            setDateRange(value);
          }}
        />
      ),
    },
    {
      component: (
        <SplitByFilter<SplitBy>
          onChange={(value) => setSplitBy(value)}
          options={defaultDateRangeFilters.splitByOptions}
          value={selectedSplitBy}
          isLoading={isLoading}
          isDisabled={isLoading || hasNoOptions}
        />
      ),
    },
  ];

  return <FiltersContainer items={items} />;
};

export { Filters };
