import { ForecastDTO, ListCountriesRO } from "@intentsify/dto";
import {
  DomainEnhancementStrategy,
  Node,
  SegmentType,
} from "@intentsify/types";
import { Endpoints, apiService } from "api";
import { z } from "zod";

export const getCampaignCountries = async (): Promise<Node[]> => {
  const { data } = await apiService.get<ListCountriesRO>(
    Endpoints.Campaigns.Get.Countries
  );

  const uniqueRegions = [
    ...new Set(data.countries.map((country) => country.region)),
  ];
  return uniqueRegions.map((region) => {
    const countriesInRegion = data.countries
      .filter((country) => country.region === region)
      .map((country) => ({
        label: country.displayName,
        value: country.countryId,
      }));
    return {
      label: region,
      value: region.toLowerCase(),
      children: countriesInRegion,
    };
  });
};
export const requestForecastSchema = z.object({
  name: z.string().min(3, { message: "Must be 3 or more characters long" }),
  includeISP: z.boolean(),
  countries: z
    .array(
      z.object({
        label: z.string(),
        value: z.number(),
      })
    )
    .min(1),
  domains: z.array(z.string()).min(1),
  segments: z.array(z.nativeEnum(SegmentType)).min(1),
  targetPersonas: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
  domainEnhancementStrategy: z.nativeEnum(DomainEnhancementStrategy).optional(),
});

export type RequestForecast = z.infer<typeof requestForecastSchema>;

export const forecast = async (dto: ForecastDTO) => {
  await apiService.post(Endpoints.DigitalForecasting.Post.Forecast(), dto);
};
