import { InfoIcon } from "@chakra-ui/icons";
import { Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { useToken } from "@chakra-ui/system";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import {
  CampaignForList,
  CampaignState,
  SaasRoleName,
} from "@intentsify/types";
import { decimalToPercentage, toNumberDisplayValue } from "@intentsify/utils";
import { ColumnDef, Row, Table } from "@tanstack/react-table";
import {
  IconButton,
  IndeterminateCheckbox,
  Progress,
  Tooltip,
} from "components";
import {
  M_COLUMN_WIDTH,
  S_COLUMN_WIDTH,
  XL_COLUMN_WIDTH,
  XS_COLUMN_WIDTH,
} from "components/Table";
import { useMemo } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { CampaignDetailsScreenDefinition } from "screens";
import { ActionItemsProps } from "shared/components";
import { useUser } from "store/store.hooks";
import { getColorSchemeBasedOnProgress } from "../YourCampaigns.utils";
import { TrendingAccountCoverageCell } from "./components";
import { CampaignLabel } from "./components/CampaignLabel/CampaignLabel";

const useCampaignsTableColumns = (
  ActionItems: (props: ActionItemsProps) => JSX.Element | null,
  onFavoriteButtonClick?: (details: {
    campaignId: number;
    isFavorite: boolean;
  }) => void
) => {
  const user = useUser();
  const starColor = useToken("colors", "yellow.500");
  const hasAccessToCampaignDetails = useHasAccessTo("campaign.details");
  const iconColor = useColorModeValue("brand.500", "gray.25");
  const progressBarBackgroundColor = useColorModeValue("gray.25", "#14202F");
  const isAdmin = user?.saasRole === SaasRoleName.Admin;

  return useMemo<ColumnDef<CampaignForList>[]>(
    () => [
      {
        id: "select",
        header: ({ table }: { table: Table<CampaignForList> }) => {
          const rows = table.getRowModel().rows;

          const onlySelectableRows = rows.filter(
            (row) => row.original.state !== CampaignState.Creating
          );

          const onChange = () => {
            onlySelectableRows.forEach((row) => row.toggleSelected());
          };

          const allChecked = onlySelectableRows.every((row) =>
            row.getIsSelected()
          );

          const someChecked = onlySelectableRows.some((r) => r.getIsSelected());
          const indeterminate = someChecked && !allChecked;

          return (
            <IndeterminateCheckbox
              {...{
                checked: allChecked,
                indeterminate,
                onChange,
              }}
            />
          );
        },
        cell: ({ row }: { row: Row<CampaignForList> }) => {
          const isDraft = row.original.state === CampaignState.Creating;

          return (
            <IndeterminateCheckbox
              {...{
                isDisabled: isDraft,
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          );
        },
      },
      {
        header: "ID",
        accessorKey: "campaignId",
        size: XS_COLUMN_WIDTH,
      },
      ...(onFavoriteButtonClick
        ? [
            {
              header: "",
              accessorKey: "isFavorite",
              cell: ({ row }: { row: Row<CampaignForList> }) => (
                <IconButton
                  tooltip={
                    row.original.isFavorite
                      ? "Remove from favorites"
                      : "Mark as favorite"
                  }
                  openDelay={500}
                  onClick={() =>
                    onFavoriteButtonClick({
                      campaignId: row.original.campaignId,
                      isFavorite: !row.original.isFavorite,
                    })
                  }
                  icon={
                    row.original?.isFavorite ? (
                      <AiFillStar color={starColor} />
                    ) : (
                      <AiOutlineStar />
                    )
                  }
                />
              ),
              size: XS_COLUMN_WIDTH,
            },
          ]
        : []),
      {
        header: "Intent Model Name",
        accessorKey: "displayName",
        cell: ({ row }: { row: Row<CampaignForList> }) => {
          const isDraft = row.original.state === CampaignState.Creating;

          return (
            <Flex alignItems="center">
              <CampaignLabel
                state={row.original.state}
                domainEnhancementStatus={row.original.domainEnhancementStatus}
              />

              {hasAccessToCampaignDetails && !isDraft ? (
                <Text
                  as={Link}
                  textDecoration="underline"
                  cursor="pointer"
                  textAlign="left"
                  to={CampaignDetailsScreenDefinition.navigate({
                    campaignId: row.original.campaignId,
                    view: "weekly-snapshot",
                  })}
                >
                  {row.original.displayName}
                </Text>
              ) : (
                <Text textAlign="left">{row.original.displayName}</Text>
              )}
            </Flex>
          );
        },
        size: XL_COLUMN_WIDTH,
      },
      {
        id: "actions",
        cell: ({ row }: { row: Row<CampaignForList> }) => {
          if (row.original?.campaignId) {
            return (
              <ActionItems
                isDraft={row.original.state === CampaignState.Creating}
                campaignId={row.original?.campaignId}
                campaignCreatedDate={row.original.createdAt ?? undefined}
                companyId={row.original.companyId}
                hasBeenMappedToConvertr={Boolean(row.original.bookedLeads)}
                hasLeads={row.original && row.original?.numberOfLeads > 0}
                hasSpotlight={row.original.hasSpotlight}
                campaignStatusId={row.original.campaignStatusId ?? undefined}
                showCampaignDetailsAction={false}
                hasRelatedProgrammaticCampaigns={
                  row.original.hasRelatedProgrammaticCampaigns
                }
                numberOfVisibleItems={0}
                hasPixel={row.original.hasPixel}
              />
            );
          }
        },
        size: XS_COLUMN_WIDTH,
      },
      {
        header: () => {
          return (
            <Text>
              % of Total Accounts Trending{" "}
              <Tooltip
                placement={"top-start"}
                aria-label="% of Total Accounts Trending"
                label={
                  "Trending accounts are monitored accounts from your TAL showing intent activity and conducting research relevant to your campaign."
                }
              >
                <InfoIcon color={iconColor} w={3} h={3} verticalAlign="top" />
              </Tooltip>
            </Text>
          );
        },
        accessorKey: "trendingAccountCoverage",
        cell: ({ row }: { row: Row<CampaignForList> }) => {
          const {
            trendingAccountCoverage,
            trendingAccountsWeek1,
            trendingAccountsWeek2,
            trendingAccountsWeek3,
            trendingAccountsWeek4,
          } = row.original;

          return (
            <Box margin="0 auto">
              <TrendingAccountCoverageCell
                trendingAccountCoverageResults={[
                  trendingAccountsWeek1,
                  trendingAccountsWeek2,
                  trendingAccountsWeek3,
                  trendingAccountsWeek4,
                ]}
                trendingAccountCoverage={trendingAccountCoverage}
              />
            </Box>
          );
        },
        size: M_COLUMN_WIDTH,
      },
      {
        header: "Accounts moved to Decision Stage",
        accessorKey: "newBuyerResearchStageDecisionAccounts",
        cell: ({ row }: { row: Row<CampaignForList> }) => (
          <Text textAlign="center">
            {toNumberDisplayValue(
              row.original.newBuyerResearchStageDecisionAccounts
            )}
          </Text>
        ),
        size: S_COLUMN_WIDTH,
      },
      {
        header: "Unique Trending Topics",
        accessorKey: "uniqueTopicsTrending",
        cell: ({ row }: { row: Row<CampaignForList> }) => (
          <Text textAlign="center">
            {toNumberDisplayValue(row.original.uniqueTopicsTrending)}
          </Text>
        ),
        size: S_COLUMN_WIDTH,
      },
      {
        header: "Lead Pacing Campaign-to-Date",
        accessorKey: "progress",
        cell: ({ row }: { row: Row<CampaignForList> }) => {
          const colorScheme = getColorSchemeBasedOnProgress(
            row.original.progress
          );

          return (
            <Box margin="0 auto" textAlign="center">
              {!row.original.progress ? (
                <Text as="span">N/A</Text>
              ) : (
                <Progress
                  value={Number(toNumberDisplayValue(row.original.progress))}
                  height={23}
                  backgroundColor={progressBarBackgroundColor}
                  customProgressBarColor={colorScheme}
                  showPercentageValue
                />
              )}
            </Box>
          );
        },
        size: M_COLUMN_WIDTH,
      },
      {
        header: "Display Pacing Campaign-to-Date",
        accessorKey: "dynamicDigitalPacing",
        cell: ({ row }: { row: Row<CampaignForList> }) => {
          const colorScheme = getColorSchemeBasedOnProgress(
            row.original.dynamicDigitalPacing * 100
          );

          return (
            <Box margin="0 auto" textAlign="center">
              {!row.original.dynamicDigitalPacing ? (
                <Text as="span">N/A</Text>
              ) : (
                <Progress
                  value={decimalToPercentage(
                    row.original.dynamicDigitalPacing,
                    0
                  )}
                  height={23}
                  backgroundColor={progressBarBackgroundColor}
                  showPercentageValue
                  customProgressBarColor={colorScheme}
                />
              )}
            </Box>
          );
        },
        size: M_COLUMN_WIDTH,
      },
      {
        header: "Total Monthly Website Visits",
        accessorKey: "totalWebsiteVisits",
        cell: ({ row }: { row: Row<CampaignForList> }) => (
          <Text textAlign="center">
            {toNumberDisplayValue(row.original.totalWebsiteVisits)}
          </Text>
        ),
        size: S_COLUMN_WIDTH,
      },
      {
        header: "Intent Model end date",
        accessorKey: "endAt",
        cell: ({ row }: { row: Row<CampaignForList> }) => (
          <Text textAlign="center">{row.original.endAt}</Text>
        ),
        size: S_COLUMN_WIDTH,
      },
      ...(isAdmin
        ? [
            {
              header: "Visibility",
              accessorKey: "isHidden",
              cell: ({ row }: { row: Row<CampaignForList> }) => {
                const content = row.original?.isHidden ? (
                  <Text fontStyle="italic" color="gray.500" fontSize={12}>
                    Hidden
                  </Text>
                ) : (
                  <Icon
                    as={RiCheckboxCircleFill}
                    w={6}
                    h={6}
                    color={iconColor}
                  />
                );
                return <Box textAlign="center">{content}</Box>;
              },
              size: XS_COLUMN_WIDTH,
            },
          ]
        : []),
    ],
    [
      isAdmin,
      onFavoriteButtonClick,
      starColor,
      hasAccessToCampaignDetails,
      ActionItems,
      iconColor,
      progressBarBackgroundColor,
    ]
  );
};

export { useCampaignsTableColumns };
