import { CampaignKeywordsAndTopics } from "@intentsify/types";
import { useRef } from "react";
import { useParams } from "react-router";
import { MutateCampaignKeywordsAndTopicsParams } from "../../CampaignsWizard.requests";
import { Controls } from "../Controls";
import { SubmitTrigger } from "../SettingsStep/SettingsForm";
import { KeywordsAndTopicsForm } from "./KeywordsAndTopicsForm";

type KeywordsAndTopicsStepProps = {
  editing: boolean;
  id: number;
  campaignKeywordsAndTopics: CampaignKeywordsAndTopics;
  onSubmit: (params: MutateCampaignKeywordsAndTopicsParams) => void;
  nextItemLabel?: string;
};

const KeywordsAndTopicsStep = ({
  editing,
  id,
  campaignKeywordsAndTopics,
  onSubmit,
  nextItemLabel,
}: KeywordsAndTopicsStepProps) => {
  const formRef = useRef<SubmitTrigger | undefined>();
  const { step } = useParams<"step">();
  const { campaignId } = useParams<"campaignId">();

  if (!step || !campaignId) {
    throw Error("404");
  }

  return (
    <>
      <KeywordsAndTopicsForm
        id={id}
        ref={formRef}
        editing={editing}
        campaignKeywordsAndTopics={campaignKeywordsAndTopics}
        onSubmit={onSubmit}
      />

      <Controls
        onNextLabel={`Next${nextItemLabel ? `: ${nextItemLabel}` : ""}`}
        editing={editing}
        step={Number(step)}
        campaignId={Number(campaignId)}
        isDisabled={false}
        formRef={formRef}
      />
    </>
  );
};

export { KeywordsAndTopicsStep };
