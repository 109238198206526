import { ProcessAutodiscoveryRO } from "@intentsify/dto";
import {
  AutodiscoveryFileUpload,
  AutodiscoveryStatusResponse,
} from "@intentsify/types";
import { Endpoints, apiService } from "api";

const processCampaignAutodiscovery = async ({
  campaignId,
  description,
  urls,
}: {
  campaignId: string;
  description?: string | null;
  urls?: string[] | null;
}) => {
  const result = await apiService.post<ProcessAutodiscoveryRO>(
    Endpoints.Campaigns.Post.Autodiscovery.Process(campaignId),
    {
      description,
      urls,
    }
  );

  return result.data;
};

const getAutodiscoveryStatus = async (
  campaignId: string
): Promise<AutodiscoveryStatusResponse> => {
  const result = await apiService.get<AutodiscoveryStatusResponse>(
    Endpoints.Campaigns.Get.Autodiscovery.Status(campaignId)
  );

  return result.data;
};

const deleteAutodiscoveryUrl =
  (campaignId: string) => async (urlId: string) => {
    const result = await apiService.delete(
      Endpoints.Campaigns.Delete.Autodiscovery.Url(campaignId, urlId)
    );
    return result.data;
  };

const uploadAutodiscoveryFiles =
  (campaignId: string) =>
  async (files: File[]): Promise<AutodiscoveryFileUpload[]> => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    const result = await apiService.post<AutodiscoveryFileUpload[]>(
      Endpoints.Campaigns.Post.Autodiscovery.UploadFiles(campaignId),
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return result.data;
  };

const disableDynamic1 = async ({ campaignId }: { campaignId: string }) => {
  await apiService.put(
    Endpoints.Campaigns.Put.Autodiscovery.DisableDynamic1(campaignId)
  );
};

export {
  deleteAutodiscoveryUrl,
  disableDynamic1,
  getAutodiscoveryStatus,
  processCampaignAutodiscovery,
  uploadAutodiscoveryFiles,
};
