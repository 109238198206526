import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Option } from "@intentsify/types";
import { ParentSize } from "@visx/responsive";
import orderBy from "lodash/orderBy";
import {
  BuyerResearchStageEngagementItem,
  BuyerResearchStageEngagementTable,
} from "./BuyerResearchStageEngagementTable";
import { getBuyerResearchStageEngagement } from "./BuyerResearchStageEngagement.requests";
import { Card, Select, MIN_MODAL_CONTENT_HEIGHT } from "components";
import { DisplayPerformanceFilters } from "screens/CampaignPacing/screens/DisplayPerformance/DisplayPerformance";
import { colors } from "theme";

type Mode = "total" | "normalized";

const modeOptions: Option<Mode>[] = [
  {
    value: "normalized",
    label: "Normalized per 100 accounts in Buyer Research Stage",
  },

  { value: "total", label: "Total" },
];

type BuyerResearchStageEngagementProps = {
  campaignId: number;
  filters: DisplayPerformanceFilters;
};

const BuyerResearchStageEngagement = ({
  campaignId,
  filters,
}: BuyerResearchStageEngagementProps) => {
  const [modeValue, setModeValue] = useState<Option<Mode>>(modeOptions[0]);

  const { data, isFetching } = useQuery(
    ["getBuyerResearchStageEngagement", campaignId, filters],
    () => getBuyerResearchStageEngagement(campaignId, filters)
  );

  const formatted = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const sorted = orderBy(
      data.buyerResearchStageEngagement,
      ["totalStandard"],
      "desc"
    );

    const accountsInBuyerResearchStage = {
      accountsInDecision: sorted[0]?.decisionAccounts ?? 0,
      accountsInConsideration: sorted[0]?.considerationAccounts ?? 0,
      accountsInInterest: sorted[0]?.interestAccounts ?? 0,
      accountsInAwareness: sorted[0]?.awarenessAccounts ?? 0,
    };

    const items: BuyerResearchStageEngagementItem[] = sorted.map((i) => {
      if (modeValue.value === "total") {
        return {
          name: i.creativeName,
          decision: i.decisionStandard ?? 0,
          consideration: i.considerationStandard ?? 0,
          interest: i.interestStandard ?? 0,
          awareness: i.awarenessStandard ?? 0,
        };
      }

      return {
        name: i.creativeName,
        decision: i.decisionNormalized ?? 0,
        consideration: i.considerationNormalized ?? 0,
        interest: i.interestNormalized ?? 0,
        awareness: i.awarenessNormalized ?? 0,
      };
    });

    return { accountsInBuyerResearchStage, items };
  }, [data, modeValue]);

  const textColor = useColorModeValue("gray", colors.gray[300]);

  return (
    <Card
      isLoading={isFetching}
      title={"Creative Engagement by Buyer Research Stage"}
      isExpandable
      titleTooltip="Normalized data illustrates collective engagement on each creative ad performed by target accounts in respective buyer research stages. Based on this heat map, one can view which creative ad is most relevant and/or engaging to a given buyer research stage. "
    >
      {(isExpanded) => (
        <Flex
          flexDir="column"
          h={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : "100%"}
        >
          <Flex mb={2}>
            <Flex alignItems="center">
              <Flex mr={2} color={textColor} fontSize="sm">
                Clicks
              </Flex>
              <Box minWidth={300}>
                <Select
                  isClearable={false}
                  isMulti={false}
                  value={modeValue}
                  options={modeOptions}
                  onChange={(val) => {
                    if (val) {
                      setModeValue(val);
                    }
                  }}
                />
              </Box>
            </Flex>
          </Flex>

          {formatted && (
            <ParentSize>
              {(parent) => {
                const { width, height } = parent;

                return (
                  <Flex w={width} height={height} fontSize="xs">
                    <BuyerResearchStageEngagementTable
                      accountsInBuyerResearchStage={
                        formatted.accountsInBuyerResearchStage
                      }
                      data={formatted.items}
                    />
                  </Flex>
                );
              }}
            </ParentSize>
          )}
        </Flex>
      )}
    </Card>
  );
};

export { BuyerResearchStageEngagement };
