import { GetCampaignSummaryRO, ListSignalsRO } from "@intentsify/dto";
import {
  AggregatedTopLocations,
  AudienceSegmentDefinition,
  CampaignDetails,
} from "@intentsify/types";
import { Endpoints, apiService } from "api";
import { getPaginatedQuery, paramsToQueryUrl } from "utils";
import { CampaignDetailsFiltersOptions, Signal } from "./CampaignDetails.types";
import {
  CampaignDetailsAggregatedSignal,
  CampaignDetailsAvailableSignals,
  CampaignDetailsTopLocations,
  PaginatedCampaignDetailsParams,
} from "./CampaignDetailsTable/CampaignDetailsTable.types";

export const listPaginatedCampaignDetails =
  (campaignId: string | number) =>
  async (params: PaginatedCampaignDetailsParams) => {
    const query = getPaginatedQuery<CampaignDetails, AudienceSegmentDefinition>(
      Endpoints.CampaignDetails.Get.List(campaignId),
      params
    );

    const { data } = await apiService.get(query);
    return data;
  };

export const getCampaignDetailsFilters = async (
  campaignId: string,
  weekNumber: number,
  yearNumber: number
): Promise<CampaignDetailsFiltersOptions> => {
  const { data } = await apiService.get(
    `${Endpoints.CampaignDetails.Get.Filters(campaignId)}?${paramsToQueryUrl({
      params: { filters: { weekNumber, yearNumber } },
      includeFirstAmpersand: false,
    })}`
  );
  return data;
};

export const getCampaignDetailsAvailableSignals = async ({
  campaignId,
  domainId,
  weekNumber,
  yearNumber,
}: CampaignDetailsAvailableSignals) => {
  const { data } = await apiService.get(
    `${Endpoints.CampaignDetails.Get.AvailableSignals(
      campaignId,
      domainId
    )}?${paramsToQueryUrl({
      params: { filters: { weekNumber, yearNumber } },
      includeFirstAmpersand: false,
    })}`
  );
  return data;
};

export const getCampaignDetailsAggregatedSignals = async ({
  campaignId,
  domainId,
  signalId,
  weekNumber,
  yearNumber,
}: CampaignDetailsAggregatedSignal) => {
  const { data } = await apiService.get(
    `${Endpoints.CampaignDetails.Get.AggregatedSignal(
      campaignId,
      domainId,
      signalId
    )}?${paramsToQueryUrl({
      params: { filters: { weekNumber, yearNumber } },
      includeFirstAmpersand: false,
    })}`
  );
  return data;
};

export const getCampaignSignals = async (): Promise<Signal[]> => {
  const { data } = await apiService.get<ListSignalsRO>(
    Endpoints.Signals.Get.All
  );
  return data.signals;
};

export const getCampaignDetailsTopLocations = async ({
  campaignId,
  domainId,
  weekNumber,
  yearNumber,
}: CampaignDetailsTopLocations) => {
  const { data } = await apiService.get<AggregatedTopLocations>(
    `${Endpoints.CampaignDetails.Get.TopLocations(
      campaignId,
      domainId
    )}?${paramsToQueryUrl({
      params: { filters: { weekNumber, yearNumber } },
      includeFirstAmpersand: false,
    })}`
  );
  return data;
};

export const getCampaignSummary = async (
  campaignId: number,
  params: { yearNumber: number; weekNumber: number }
): Promise<GetCampaignSummaryRO> => {
  const { data } = await apiService.get<GetCampaignSummaryRO>(
    `${Endpoints.CampaignDetails.Get.Summary(campaignId)}`,
    {
      params,
    }
  );

  return data;
};
