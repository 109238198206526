import { Box, Divider, SimpleGrid, Text } from "@chakra-ui/react";
import {
  BlacklistedFirmographic,
  FirmographicsFilters,
} from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import {
  DataToCsvDownload,
  Firmographics,
  SelectedTags,
  SelectedTagsHeader,
  ViewContainer,
} from "components";
import { defaultFirmoFilters } from "components/Firmographics/Firmographics.const";
import { useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { DomainsUploadLegacy } from "shared/components";
import { DomainsUploadScreenType } from "shared/components/DomainsUploadLegacy/DomainsUpload.types";
import { useScreen } from "utils";
import { DigitalForecastingScreenDefinition } from "./DigitalForecasting.definition";
import {
  digitalForecastsDomainsSelector,
  digitalForecastsFirmographicsAtom,
  digitalForecastsUploadedDomainsAtom,
} from "./DigitalForecasting.state";
import { Forecast } from "./components";

const DigitalForecasting = () => {
  useScreen(DigitalForecastingScreenDefinition);

  const [uploadedDomains, setUploadedDomains] = useRecoilState(
    digitalForecastsUploadedDomainsAtom
  );
  const [firmographicsFilters, setFirmographicsFilters] =
    useState<FirmographicsFilters>(defaultFirmoFilters);
  const [selectedFirmographics, setSelectedFirmographics] = useRecoilState(
    digitalForecastsFirmographicsAtom
  );
  const [blacklistedFirmographics, setBlacklistedFirmographics] = useState<
    BlacklistedFirmographic[]
  >([]);

  const domainsData = useRecoilValue(digitalForecastsDomainsSelector);

  const csvData = useMemo(() => {
    return domainsData.map((item) => ({
      domain: item.label,
    }));
  }, [domainsData]);

  return (
    <>
      <SimpleGrid columns={1} spacing="2px">
        <ViewContainer compact>
          <Text fontWeight="semibold">Step 1: Build an audience</Text>
          <DomainsUploadLegacy
            domainsData={uploadedDomains}
            domainsUploadScreenType={
              DomainsUploadScreenType.DIGITAL_PERFORMANCE_VIEW
            }
            onUploadedDomainsChange={(data) => {
              setUploadedDomains(data);
            }}
          />
          <Box mt={4} />
          <Firmographics
            subject={`Impression Forecasting`}
            firmographicsFilters={firmographicsFilters ?? defaultFirmoFilters}
            setFirmographicsFilters={setFirmographicsFilters}
            setSelectedFirmographics={(data) => setSelectedFirmographics(data)}
            selectedFirmographics={selectedFirmographics}
            blacklisted={blacklistedFirmographics}
            onChangeBlacklisted={setBlacklistedFirmographics}
          />
        </ViewContainer>
        <ViewContainer compact>
          <Divider my={4} />
          <SelectedTagsHeader
            title="Combined selected domains"
            items={domainsData}
            onReset={() => {
              setUploadedDomains(undefined);
            }}
            actions={
              <DataToCsvDownload
                fileName={"Impression Forecasting - domains.csv"}
                downloadLabel="Impression Forecasting - domains.csv"
                data={csvData}
                iconSize={"xs"}
                withLabel
                subject="domain"
                subjectPlural="domains"
                tooltipPlacement="bottom-end"
              />
            }
          />

          {isPopulatedArray(domainsData) && (
            <Box maxH="400px" overflowX="hidden">
              <SelectedTags items={domainsData} />
            </Box>
          )}
          <Divider mt={4} mb={4} />
          <Forecast
            items={domainsData}
            onReset={() => {
              setUploadedDomains(undefined);
            }}
          />
        </ViewContainer>
      </SimpleGrid>
    </>
  );
};

export { DigitalForecasting };
