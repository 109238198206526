import { DateRange } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { getCampaignMeta } from "api";
import { DateWeekAndYear } from "types";

type UseCampaignMetaOptions = {
  campaignId?: string;
  date?: DateWeekAndYear;
};

export const useCampaignMeta = ({
  campaignId,
  date,
}: UseCampaignMetaOptions) => {
  const queryKey = date
    ? `getCampaignMeta_weekNumber_${date.weekNumber}_yearNumber_${date.yearNumber}`
    : `getCampaignMeta${"_" + (campaignId ?? "")}`;

  const { data: campaignMeta, isFetching: campaignMetaIsLoading } = useQuery(
    [queryKey, campaignId],
    () => {
      if (campaignId) {
        return getCampaignMeta(campaignId, date ?? DateRange.Full);
      }
    },
    {
      enabled: Boolean(campaignId),
    }
  );

  return {
    campaignMeta,
    campaignMetaIsLoading,
  };
};
