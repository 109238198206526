import {
  RoomEventType,
  RoomEvents,
  SocketEvent,
  SocketRoom,
} from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { useContext, useEffect, useRef, useState } from "react";
import { useDeepEffect } from "utils";
import { v4 as uuidv4 } from "uuid";
import { SocketContext } from "./socketContext";

export const useRoomEvent = <
  E extends RoomEventType,
  M = Extract<RoomEvents, { type: E }>
>(
  room:
    | SocketRoom
    | `${SocketRoom.EDIT_CAMPAIGN}_${string}`
    | `${SocketRoom.USER_NOTIFICATIONS}_${string}`,
  event: E,
  onMessage: (message: M) => void
) => {
  const uuidRef = useRef(uuidv4());
  const { emitQueuedEvent, setEventSubscriptions, connected } =
    useContext(SocketContext);
  const [events, setEvents] = useState<M[]>([]);

  useDeepEffect(() => {
    if (isPopulatedArray(events)) {
      const event = events[0];
      onMessage(event);
      setEvents((events) => {
        return events.slice(1, Infinity);
      });
    }
  }, [events, onMessage]);

  useEffect(() => {
    emitQueuedEvent?.({
      data: room,
      type: SocketEvent.JOIN_ROOM,
    });

    return () => {
      emitQueuedEvent?.({
        data: room,
        type: SocketEvent.LEAVE_ROOM,
      });
    };
  }, [room, connected, emitQueuedEvent]);

  useEffect(() => {
    const uuid = uuidRef.current;

    setEventSubscriptions?.((prev) => {
      if (!prev.find((i) => i.uuid === uuid)) {
        return [
          ...prev,
          {
            uuid,
            event: SocketEvent.ROOM_EVENT,
            onMessage: (message) => {
              if (message.type === event) {
                setEvents((prev) => [...prev, message]);
              }
            },
          },
        ];
      }

      return prev;
    });
  }, [onMessage, setEventSubscriptions, event]);

  useEffect(() => {
    const uuid = uuidRef.current;

    return () => {
      setEventSubscriptions?.((prev) => {
        return prev.filter((i) => i.uuid !== uuid);
      });
    };
  }, [setEventSubscriptions]);

  return { connected };
};
