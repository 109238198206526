import { InfoIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";
import { Tooltip, Upload } from "components";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { UploadTopics } from "shared/components";
import { useComponentColors } from "theme";

type TopicsUploadProps = {
  onChange: (data: string[]) => void;
};

const TopicsUpload = ({ onChange }: TopicsUploadProps) => {
  const componentColors = useComponentColors();
  const [topicsFile, setTopicsFile] = useState<File | undefined>();

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDropAccepted: (files: File[]) => {
      const [firstFile] = files;

      const reader = new FileReader();

      reader.onload = (event) => {
        if (event?.target?.result) {
          setTopicsFile(firstFile);
        }
      };

      reader.readAsText(firstFile);
    },
  });

  return (
    <Flex flexDir="column">
      <Flex alignItems="center" mb={4}>
        <Text color={componentColors.form.formLabelColor} fontWeight="semibold">
          Upload topics
        </Text>

        <Tooltip
          aria-label={"Upload topics"}
          label={`Upload topics as a CSV file with a single column: "Topic". Each row should contain a single topic (e.g., "marketing").`}
        >
          <InfoIcon
            color={componentColors.form.formLabelColor}
            w={4}
            h={4}
            ml={2}
          />
        </Tooltip>
      </Flex>

      <Upload
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        subHeader={"CSV up to 100MB"}
      />

      {topicsFile && (
        <UploadTopics
          topicsFile={topicsFile}
          clearTopicsFile={() => setTopicsFile(undefined)}
          selectedTopics={[]}
          setSelectedTopics={onChange}
        />
      )}
    </Flex>
  );
};

export { TopicsUpload };
