import { Box, BoxProps, Portal } from "@chakra-ui/react";
import noop from "lodash/noop";
import { HTMLInputTypeAttribute, useRef } from "react";
import {
  DynamicInputForm,
  DynamicInputFormProps,
  DynamicInputItem,
} from "./components";
import { useDeepEffect } from "utils";

type DynamicInputProps<T extends number | string> = {
  items: T[];
  addItem: (item: T) => void;
  removeItem: (item: T) => void;
  inputType?: HTMLInputTypeAttribute;
  formProps?: Partial<DynamicInputFormProps<T>>;
  containerProps?: BoxProps;
  onChange?: (items: T[]) => void;
  placeholder?: string;
};

export const DynamicInput = <T extends string | number>({
  items,
  addItem,
  removeItem,
  inputType = "number",
  formProps = {},
  containerProps = {},
  onChange = noop,
  placeholder,
}: DynamicInputProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);

  useDeepEffect(() => {
    onChange(items);
  }, [items]);

  return (
    <>
      <Portal containerRef={ref}>
        <Box pb={2} {...containerProps}>
          {items.map((item, index) => {
            return (
              <DynamicInputItem
                key={index}
                item={item}
                removeItem={removeItem}
              />
            );
          })}
        </Box>
        <DynamicInputForm
          {...formProps}
          addItem={addItem}
          inputType={inputType}
          placeholder={placeholder}
        />
      </Portal>
      <div ref={ref} />
    </>
  );
};
