import { Box, Flex, Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";
import { Virtuoso } from "react-virtuoso";
import { Node } from "@intentsify/types";

type SelectedListProps = {
  selected: Node[];
  onRemoveNode: (valu: string | number) => void;
};

const SelectedList = ({ selected, onRemoveNode }: SelectedListProps) => {
  return (
    <Virtuoso
      totalCount={selected.length}
      style={{ height: "calc(400px - 1rem)" }}
      itemContent={(index) => (
        <Box w="100%" pr={2} mb="0.125rem">
          <Tag size="sm" key={selected[index].label} h="24px" w="100%">
            <TagLabel>{selected[index].label}</TagLabel>
            <Flex flexGrow={1} />
            <TagCloseButton
              onClick={() => onRemoveNode(selected[index].value)}
            />
          </Tag>
        </Box>
      )}
    />
  );
};

export { SelectedList };
