import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { HasAccess } from "@intentsify/authorization/dist/react";
import {
  DomainEnhancementStrategy,
  Item,
  SegmentType,
  TargetPersona,
} from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  ErrorBoundary,
  FilterAsyncTokenBased,
  LabeledSwitch,
  Tooltip,
  TreeFilter,
} from "components";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useComponentColors } from "theme";
import { ampli } from "tracking/amplitude";
import { FetchDataParamsWithToken } from "types";
import { useUserAgencyCompany } from "../../../../../../queries/companies/useUserAgencyCompany";
import { getTargetPersonas } from "../../../../../TargetPersonas/useTargetPersonas";
import {
  forecast,
  getCampaignCountries,
  RequestForecast,
  requestForecastSchema,
} from "../../DigitalForecasting.requests";

const personaDataRequest = async (
  params: FetchDataParamsWithToken<keyof TargetPersona> & { companyId?: number }
) => {
  const { targetPersonas } = await getTargetPersonas(params);
  return {
    ...targetPersonas,
    results: targetPersonas.map((persona) => ({
      value: persona.id,
      label: persona.name,
      meta: persona,
    })),
  };
};

type ForecastProps = {
  items: Item[];
  onReset: () => void;
};

const formDefaultValues = {
  name: "",
  includeISP: false,
  domains: [],
  countries: [],
  segments: [SegmentType.IP],
  targetPersonas: [],
  domainEnhancementStrategy: undefined,
};

const US_COUNTRY_ID = 234;

const Forecast = ({ items }: ForecastProps) => {
  const componentColors = useComponentColors();

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userAgencyCompany = useUserAgencyCompany();
  const selectedCompanyId = userAgencyCompany.data?.companyId;

  const { mutate: runForecast, isLoading } = useMutation(forecast, {
    onSuccess: () => {
      toast({
        title:
          "Your Impression Forecasting request has been sent. You should receive an email with the results shortly.",
        status: "success",
      });
    },
  });

  const { data: regionsTree } = useQuery(
    ["digitalForecastingRegionsTree"],
    getCampaignCountries
  );
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm<RequestForecast>({
    mode: "onChange",
    resolver: zodResolver(requestForecastSchema),
    defaultValues: formDefaultValues,
  });

  const domains = watch("domains");
  const countries = watch("countries");
  const shouldIncludeISPs = watch("includeISP");
  const segments = watch("segments");
  const targetPersonas = watch("targetPersonas");
  const domainEnhancementStrategy = watch("domainEnhancementStrategy");

  useEffect(() => {
    setValue(
      "domains",
      items.map((domain) => domain.label)
    );
  }, [setValue, items]);

  useEffect(() => {
    setValue("targetPersonas", []);
  }, [selectedCompanyId, setValue]);

  const countryError = !isPopulatedArray(countries)
    ? "At least one country is required."
    : undefined;

  return (
    <>
      <Text fontWeight="semibold">Step 2: Complete Forecasting Details</Text>
      <Text fontWeight="semibold">
        Impressions Forecasting for Account and Device Targeting
      </Text>
      <Stack spacing={[1, 3, 5]} marginY={6}>
        <FormControl width={400} isRequired>
          <FormLabel
            fontSize="sm"
            fontWeight="md"
            color={componentColors.form.formLabelColor}
            mb={4}
          >
            Countries
          </FormLabel>

          <TreeFilter
            subject="countries"
            nodes={regionsTree ?? []}
            included={countries}
            onIncludedChange={(selected) => {
              setValue(
                "countries",
                selected.map((country) => ({
                  label: country.label,
                  value: Number(country.value),
                }))
              );

              /*
                if USA was selected and removed we need to remove MAID segment and USER_ID segment type from segments and remove target personas
              * */
              const isUSASelected =
                !!selected.find((country) => country.value === US_COUNTRY_ID) ||
                false;
              if (!isUSASelected) {
                const filteredSegments =
                  segments.filter(
                    (segment) =>
                      ![SegmentType.MAID, SegmentType.USER_ID].includes(segment)
                  ) || [];
                setValue("segments", filteredSegments);
                setValue("targetPersonas", []);
              }
            }}
          />
          <FormErrorMessage>{countryError}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired>
          <FormLabel
            fontSize="sm"
            fontWeight="md"
            color={componentColors.form.formLabelColor}
            mb={4}
          >
            Include ISPs
          </FormLabel>

          <LabeledSwitch
            leftLabel="No"
            rightLabel="Yes"
            isChecked={shouldIncludeISPs}
            defaultChecked={Boolean(shouldIncludeISPs)}
            onChange={(value) => {
              setValue("includeISP", value);
            }}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel
            fontSize="sm"
            fontWeight="md"
            color={componentColors.form.formLabelColor}
            mb={4}
          >
            Type
          </FormLabel>
          <CheckboxGroup colorScheme="teal" defaultValue={["ip"]}>
            <Stack spacing={[1, 5]} direction={["column", "row"]}>
              <Checkbox
                isChecked={segments.includes(SegmentType.IP)}
                onChange={(e) => {
                  setValue(
                    "segments",
                    e.target.checked
                      ? [...(segments ?? []), SegmentType.IP]
                      : segments?.filter(
                          (segment) => segment !== SegmentType.IP
                        ) ?? []
                  );
                }}
              >
                Account Targeting (IP)
              </Checkbox>
              <Checkbox
                isChecked={segments.includes(SegmentType.MAID)}
                onChange={(e) => {
                  setValue(
                    "segments",
                    e.target.checked
                      ? [...(segments ?? []), SegmentType.MAID]
                      : segments?.filter(
                          (segment) => segment !== SegmentType.MAID
                        ) ?? []
                  );
                }}
                isDisabled={
                  !countries.find((country) => country.value === US_COUNTRY_ID)
                }
              >
                Device Targeting (MAIDs)
              </Checkbox>
              <Checkbox
                isChecked={segments.includes(SegmentType.USER_ID)}
                onChange={(e) => {
                  setValue(
                    "segments",
                    e.target.checked
                      ? [...(segments ?? []), SegmentType.USER_ID]
                      : segments?.filter(
                          (segment) => segment !== SegmentType.USER_ID
                        ) ?? []
                  );
                }}
                isDisabled={
                  !countries.find((country) => country.value === US_COUNTRY_ID)
                }
              >
                Cookie Targeting (User IDs/Bitos)
              </Checkbox>
            </Stack>
          </CheckboxGroup>
        </FormControl>
        <FormControl>
          <FormLabel
            fontSize="sm"
            fontWeight="md"
            color={componentColors.form.formLabelColor}
            mb={4}
          >
            Target personas
            <Tooltip
              aria-label="Target personas"
              label={`Select United States as one of the countries to target and the Device Targeting (MAIDs) type if you'd like to receive results around your target personas. Please note that your forecast will be significantly lower due to targeting only the personas within your accounts.`}
            >
              <InfoIcon
                color={componentColors.form.formLabelColor}
                w={4}
                h={4}
                ml={2}
              />
            </Tooltip>
          </FormLabel>
          <Box width="sm">
            <FilterAsyncTokenBased
              isMulti={true}
              isDisabled={
                !selectedCompanyId ||
                (!segments.includes(SegmentType.MAID) &&
                  !segments.includes(SegmentType.USER_ID))
              }
              defaultOptions={[]}
              currentValue={targetPersonas.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              label="Personas"
              showLabel={false}
              dataRequest={(
                arg: FetchDataParamsWithToken<keyof TargetPersona>
              ) => {
                return personaDataRequest({
                  ...arg,
                });
              }}
              resetFilterRequestDependencies={[selectedCompanyId]}
              onFilterValuesChange={(options) => {
                setValue(
                  "targetPersonas",
                  (options || []).map((item) => ({
                    id: item.value,
                    name: item.label,
                  }))
                );
              }}
            />
          </Box>
        </FormControl>
      </Stack>

      <Flex alignItems="center" justifyContent="flex-end">
        <HasAccess to="digitalForecasting.domainExpansion">
          <Flex color="black" gap="2" _dark={{ color: "white" }}>
            <Text fontSize="sm">
              Domain Expansion: Expand my list using related domains
            </Text>

            <Flex
              color="black"
              gap="1"
              alignItems="center"
              flexDir="row"
              _dark={{ color: "white" }}
            >
              <Checkbox
                id="expand"
                isChecked={
                  domainEnhancementStrategy === DomainEnhancementStrategy.expand
                }
                onChange={(e) => {
                  const domainEnhancementStrategy = e.target.checked
                    ? DomainEnhancementStrategy.expand
                    : undefined;

                  setValue(
                    "domainEnhancementStrategy",
                    domainEnhancementStrategy
                  );
                }}
              />
              <Tooltip
                aria-label={"expand"}
                label={
                  <>
                    Using Domain Expansion, our system will expand the TAL using
                    related parent and child domains.
                  </>
                }
              >
                <InfoIcon w={4} h={4} verticalAlign={"center"} pl={1} />
              </Tooltip>
            </Flex>
          </Flex>
        </HasAccess>
      </Flex>

      <Divider mt={4} />

      <Flex mt={4} justifyContent="flex-end">
        <Button
          size="md"
          variant="primary-teal"
          isLoading={isLoading}
          isDisabled={
            !isPopulatedArray(countries) ||
            !isPopulatedArray(domains) ||
            !isPopulatedArray(segments)
          }
          onClick={() => {
            onOpen();
          }}
        >
          Process Forecasting
        </Button>
      </Flex>

      <Modal
        isCentered
        size="2xl"
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setValue("name", "");
        }}
      >
        <ModalOverlay />

        <ModalContent
          px="6"
          py="5"
          bg="white"
          _dark={{ bg: "brand.450" }}
          borderRadius="xl"
          border="2px solid"
          borderColor="whiteAlpha.100"
        >
          <VStack spacing="12" alignItems="flex-start">
            <ModalHeader p="0" w="100%">
              <VStack spacing="1" alignItems="flex-start">
                <Heading fontSize="lg" color="black" _dark={{ color: "white" }}>
                  Save As
                </Heading>
                <Text
                  fontSize="sm"
                  mt="2"
                  color="blackAlpha.700"
                  _dark={{ color: "whiteAlpha.700" }}
                >
                  Enter forecasting name
                </Text>
              </VStack>

              <ModalCloseButton
                color="blackAlpha.700"
                _hover={{ color: "black", bg: "blackAlpha.100" }}
                _focus={{ color: "black", bg: "blackAlpha.100" }}
                _dark={{
                  color: "whiteAlpha.600",
                  _hover: { color: "whiteAlpha.800", bg: "whiteAlpha.100" },
                  _focus: { color: "whiteAlpha.800", bg: "whiteAlpha.100" },
                }}
              />
            </ModalHeader>

            <ModalBody p="0" w="100%">
              <ErrorBoundary>
                <Stack>
                  <FormControl isRequired>
                    <FormLabel
                      fontSize="sm"
                      fontWeight="md"
                      color={componentColors.form.formLabelColor}
                    >
                      Forecasting name
                    </FormLabel>
                    <Input
                      {...register("name")}
                      type="text"
                      placeholder="Name"
                      shadow="sm"
                      size="sm"
                      rounded="md"
                      autoComplete="off"
                      isRequired
                    />
                  </FormControl>
                  <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                </Stack>
                <Flex marginY={6} justifyContent="flex-end">
                  <Button
                    variant="primary-teal"
                    isLoading={isLoading}
                    isDisabled={!!Object.keys(errors).length}
                    onClick={() => {
                      ampli.digitalForecastingRequest();
                      runForecast(getValues());
                      onClose();
                      setValue("name", "");
                    }}
                  >
                    Process
                  </Button>
                </Flex>
              </ErrorBoundary>
            </ModalBody>
          </VStack>
        </ModalContent>
      </Modal>
    </>
  );
};

export { Forecast };
