import { Icon as CharkaIcon } from "@chakra-ui/icon";
import { Radio, Spinner, useToast } from "@chakra-ui/react";
import { TalToolFileRO } from "@intentsify/dto";
import { TalToolFileState, TalToolsTypes } from "@intentsify/types";
import { formatBytes } from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";
import { Badge, IconButton, SimpleTable, Tooltip } from "components";
import { ReactElement, cloneElement, useMemo, useState } from "react";
import { FiDownload, FiTrash } from "react-icons/fi";
import { useComponentColors } from "theme";
import { useTrackFileDownloaded } from "../../../../../../tracking/useTrackFileDownloaded";
import { useDeleteTalToolFile } from "../../hooks/useDeleteTalToolFile";

type TalToolsFilesProps = {
  talToolsType: TalToolsTypes;
  files: TalToolFileRO[];
  onFileDelete: (fileName: string) => void;
  tooltipLabelRender?: (
    file: TalToolFileRO
  ) => { label: string; content: ReactElement } | null;
};

const useTalToolsFilesColumns = (
  handleRowDelete: (fileName: string) => Promise<void>,
  tooltipLabelRender: NonNullable<TalToolsFilesProps["tooltipLabelRender"]>
) => {
  const componentColors = useComponentColors();
  const [deletingFiles, setDeletingFiles] = useState<string[]>([]);
  const trackFileDownloaded = useTrackFileDownloaded();
  return useMemo(() => {
    return [
      {
        id: "select",
        header: () => null,
        cell: ({ row }) => {
          return (
            <Radio key={row.original.fileName} value={row.original.fileName} />
          );
        },
      },
      {
        header: "File name",
        accessorKey: "fileName",
        cell: ({ row }) => {
          let badge = null;

          if (row.original.state === TalToolFileState.PROCESSING) {
            badge = (
              <Badge ml={2} colorScheme="blue">
                PROCESSING
              </Badge>
            );
          } else if (row.original.state === TalToolFileState.FAILED) {
            badge = (
              <Badge ml={2} colorScheme="red">
                FAILED
              </Badge>
            );
          } else if (row.original.state === TalToolFileState.COMPLETED) {
            badge = (
              <Badge ml={2} colorScheme="green">
                COMPLETED
              </Badge>
            );
          } else if (row.original.state === TalToolFileState.NO_RESULTS) {
            badge = (
              <Badge ml={2} colorScheme="gray">
                NO RESULTS
              </Badge>
            );
          }

          const tooltip = tooltipLabelRender(row.original);
          if (tooltip && badge) {
            badge = (
              <Tooltip aria-label={tooltip.label} label={tooltip.content}>
                {cloneElement(badge, { cursor: "pointer" })}
              </Tooltip>
            );
          }

          return (
            <>
              {row.original.fileName} {badge}
            </>
          );
        },
      },
      {
        header: "Original File Size",
        accessorKey: "size",
        cell: ({ row }) => {
          const size: number = row.original.size;
          return formatBytes(size);
        },
      },
      {
        header: "Original File Last Modified",
        accessorKey: "lastModified",
        cell: ({ row }) => {
          const date: Date = row.original.lastModified;
          return new Intl.DateTimeFormat("en-US", {
            dateStyle: "short",
            timeStyle: "short",
          }).format(date);
        },
      },
      {
        id: "action-delete",
        size: 40,
        header: () => null,
        cell: ({ row }) => {
          const fileName: string = row.original.fileName;
          if (deletingFiles.includes(fileName)) {
            return (
              <>
                <Spinner
                  thickness="4px"
                  speed="0.75s"
                  size="md"
                  {...componentColors.spinner}
                />{" "}
                Deleting...
              </>
            );
          }

          return (
            <IconButton
              tooltip={`Delete file ${fileName}`}
              tooltipPlacement="bottom-end"
              icon={<CharkaIcon as={FiTrash} fontSize="md" />}
              onClick={() => {
                setDeletingFiles((files) => [...files, fileName]);
                handleRowDelete(fileName).finally(() => {
                  setDeletingFiles((files) =>
                    files.filter((file) => file !== fileName)
                  );
                });
              }}
            />
          );
        },
      },
      {
        id: "action-download",
        size: 40,
        header: () => null,
        cell: ({ row }) => {
          if (
            !row.original.downloadLink ||
            row.original.state === TalToolFileState.NO_RESULTS
          ) {
            return null;
          }

          return (
            <IconButton
              as="a"
              icon={<CharkaIcon as={FiDownload} fontSize="md" />}
              href={row.original.downloadLink}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onClick={() =>
                trackFileDownloaded({
                  label: "Firmo&Techno Append",
                  fileName: row.original.fileName,
                })
              }
              tooltip="Download file"
              target="_blank"
            />
          );
        },
      },
    ] satisfies ColumnDef<TalToolFileRO>[];
  }, [
    tooltipLabelRender,
    deletingFiles,
    componentColors.spinner,
    handleRowDelete,
    trackFileDownloaded,
  ]);
};

const defaultTooltipLabelRender = () => null;

export const TalToolsFiles = ({
  talToolsType,
  files,
  onFileDelete,
  tooltipLabelRender = defaultTooltipLabelRender,
}: TalToolsFilesProps) => {
  const toast = useToast();
  const { mutateAsync: deleteFile } = useDeleteTalToolFile(talToolsType);
  const columns = useTalToolsFilesColumns(async (fileName) => {
    try {
      await deleteFile(fileName);
      onFileDelete(fileName);
    } catch (error) {
      toast({
        status: "error",
        description: `Failed to delete file "${fileName}". Please try again.`,
        duration: null,
        isClosable: true,
      });
    }
  }, tooltipLabelRender);
  return (
    <SimpleTable<TalToolFileRO>
      columns={columns}
      nonIdealState={{
        variant: "noUploadedFiles",
        onCtaClick: () => void 0,
      }}
      data={files}
    />
  );
};
