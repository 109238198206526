/* eslint-disable @typescript-eslint/no-misused-promises */
import { InfoIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Portal,
  Text,
  Tooltip,
  chakra,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "components/Button";
import { DynamicInputNext } from "components/DynamicInputNext/DynamicInput";
import { LabeledSwitch } from "components/LabeledSwitch";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useComponentColors } from "theme";
import { z } from "zod";

const validationSchema = z.object({
  email: z.string().email(),
});

type AutomatedIntentDeliveryData = z.infer<typeof validationSchema>;

const AutomatedIntentDelivery = (props: {
  automatedIntentDelivery: boolean;
  emails: string[];
  onSwitchChange: (isChecked: boolean) => void;
  onEmailsChange: (emails: string[]) => void;
}) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<AutomatedIntentDeliveryData>({
    mode: "all",
    resolver: zodResolver(validationSchema),
  });

  const ref = useRef<HTMLDivElement>(null);

  const componentColors = useComponentColors();

  const [_, setValue] = useState<string>("");
  const [emails, setEmails] = useState(props.emails);
  const [hasAutomatedIntentDelivery, setHasAutomatedIntentDelivery] = useState(
    props.automatedIntentDelivery
  );

  useEffect(() => {
    props.onEmailsChange(emails);
  }, [emails, props]);

  useEffect(() => {
    props.onSwitchChange(hasAutomatedIntentDelivery);
  }, [hasAutomatedIntentDelivery, props]);

  const removeEmail = useCallback((item: string) => {
    setEmails((prevEmails) => prevEmails.filter((email) => email !== item));
  }, []);

  const onSubmitForm = useCallback(
    (data: AutomatedIntentDeliveryData) => {
      if (!emails.includes(data.email)) {
        setEmails([...emails, data.email]);
      }
    },
    [emails]
  );

  const handleFormSubmit = (
    e: React.KeyboardEvent<HTMLFormElement> | React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget).get("email") as string;

    if (!data) {
      return;
    }

    handleSubmit(onSubmitForm)(e);
    e.currentTarget.reset();
    setValue("");
  };

  return (
    <>
      <Portal containerRef={ref}>
        <DynamicInputNext.Root alignItems={"flex-start"}>
          <DynamicInputNext.Body>
            <HStack spacing={2}>
              <Text
                p="0"
                m="0"
                fontSize="md"
                fontWeight="bold"
                color={componentColors.form.formLabelColor}
              >
                Automated Intent Delivery
                <Tooltip
                  aria-label={"automated-intent-delivery-tooltip"}
                  placement={"top-end"}
                  label={
                    <>
                      This automated email delivery system is for any
                      customer/user that is interested in receiving our intent
                      intelligence for accounts that have garnered leads during
                      their campaign. Please toggle “on” and input an email
                      address below to begin the weekly cadence email service.
                    </>
                  }
                >
                  <InfoIcon w={4} h={4} verticalAlign={"center"} pl={1} />
                </Tooltip>
              </Text>

              <LabeledSwitch
                size="sm"
                isChecked={hasAutomatedIntentDelivery}
                leftLabel=""
                rightLabel=""
                onChange={() => {
                  setHasAutomatedIntentDelivery(!hasAutomatedIntentDelivery);
                }}
              />
            </HStack>

            <Text
              p="0"
              m="0"
              fontSize="sm"
              fontWeight="md"
              color={componentColors.form.formLabelColor}
            >
              Intent Delivery Recipients
            </Text>
            <chakra.form w={"100%"} onSubmit={handleFormSubmit}>
              <InputGroup size="md">
                <FormControl
                  isInvalid={!!errors?.email?.message}
                  isDisabled={!hasAutomatedIntentDelivery}
                >
                  <Input
                    {...register("email")}
                    placeholder="Email address"
                    shadow="sm"
                    size="sm"
                    rounded="md"
                    autoComplete="off"
                    mt={1}
                  />
                  <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
                </FormControl>
                <InputRightElement>
                  <Button
                    isDisabled={!hasAutomatedIntentDelivery}
                    type="submit"
                  >
                    Add
                  </Button>
                </InputRightElement>
              </InputGroup>
            </chakra.form>
          </DynamicInputNext.Body>
          {hasAutomatedIntentDelivery && (
            <DynamicInputNext.Tag tags={emails} onRemoveClick={removeEmail} />
          )}
        </DynamicInputNext.Root>
      </Portal>
      <div ref={ref} />
    </>
  );
};

export { AutomatedIntentDelivery };
