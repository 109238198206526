import { DomainsData, Firmographic } from "@intentsify/types";
import { atom, selector } from "recoil";
import { Item } from "types";
import { getUniqueItems } from "../../../../utils/item";

export const digitalForecastsUploadedDomainsAtom = atom<DomainsData>({
  key: "digitalForecastsUploadedDomainsAtom",
  default: undefined,
});

export const digitalForecastsFirmographicsAtom = atom<Firmographic[]>({
  key: "digitalForecastsFirmographicsAtom",
  default: [],
});

export const digitalForecastsDomainsSelector = selector({
  key: `digitalForecastsDomainsSelector`,
  get: ({ get }) => {
    const uploaded: Item[] =
      get(digitalForecastsUploadedDomainsAtom)?.domains.map((d) => ({
        value: d.displayName,
        label: d.displayName,
      })) ?? [];
    const firmographics: Item[] = get(digitalForecastsFirmographicsAtom).map(
      (f) => ({
        label: f.domain,
        value: f.domain,
      })
    );

    return getUniqueItems([...uploaded, ...firmographics], "value");
  },
});
