import { Stack, Text } from "@chakra-ui/react";
import { Option } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import {
  Filter,
  FiltersContainer,
  FiltersContainerItem,
  RangePicker,
} from "components";
import { DateTime } from "luxon";
import { useParams } from "react-router";
import { PerformanceFilters } from "./CampaignLeadPerformance";
import { getLeadPerformanceFilters } from "./CampaignLeadPerformance.requests";

type FiltersProps = {
  selectedFilters: PerformanceFilters;
  onChange: (filters: PerformanceFilters) => void;
};

const Filters = ({ selectedFilters, onChange }: FiltersProps) => {
  const { campaignId } = useParams<"campaignId">();

  const { startWeekNumber, startYearNumber, endWeekNumber, endYearNumber } =
    selectedFilters;

  const { data, isLoading } = useQuery(
    [
      "leadPerformanceFilters",
      campaignId,
      startWeekNumber,
      startYearNumber,
      endWeekNumber,
      endYearNumber,
    ],
    () => {
      if (campaignId) {
        return getLeadPerformanceFilters({
          campaignId: Number(campaignId),
          filters: {
            startWeekNumber,
            startYearNumber,
            endWeekNumber,
            endYearNumber,
          },
        });
      }
    }
  );

  const items: FiltersContainerItem[] = [
    {
      component: (
        <Filter
          label={"Job Level"}
          options={data?.jobLevels ?? []}
          isLoading={isLoading}
          isDisabled={isLoading}
          placeholder="All"
          onFilterValuesChange={(options: Option<string>[]) => {
            onChange({
              ...selectedFilters,
              jobLevels: options,
            });
          }}
          selected={selectedFilters.jobLevels}
        />
      ),
    },
    {
      component: (
        <Filter
          label={"Industry"}
          options={data?.industries ?? []}
          isLoading={isLoading}
          isDisabled={isLoading}
          placeholder="All"
          onFilterValuesChange={(options: Option<string>[]) => {
            onChange({
              ...selectedFilters,
              industries: options,
            });
          }}
          selected={selectedFilters.industries}
        />
      ),
    },
    {
      component: (
        <Filter
          label={"States (US only)"}
          options={data?.usStates ?? []}
          isLoading={isLoading}
          isDisabled={isLoading}
          placeholder="All"
          onFilterValuesChange={(options: Option<number>[]) => {
            onChange({
              ...selectedFilters,
              usStates: options,
            });
          }}
          selected={selectedFilters.usStates}
        />
      ),
    },

    {
      component: (
        <Stack>
          <Text fontSize="xs" fontWeight="semibold">
            Week Range:
          </Text>

          <RangePicker
            mode="week"
            selected={{
              start: DateTime.fromObject(
                {
                  weekNumber: selectedFilters.startWeekNumber,
                  weekYear: selectedFilters.startYearNumber,
                },
                { zone: "utc" }
              ).toISO(),
              end: DateTime.fromObject(
                {
                  weekNumber: selectedFilters.endWeekNumber,
                  weekYear: selectedFilters.endYearNumber,
                },
                { zone: "utc" }
              ).toISO(),
            }}
            onChange={(val) => {
              const start = DateTime.fromISO(val.start, { zone: "utc" });
              const end = DateTime.fromISO(val.end, { zone: "utc" });

              onChange({
                ...selectedFilters,
                jobLevels: [],
                usStates: [],
                startWeekNumber: start.weekNumber,
                startYearNumber: start.year,
                endWeekNumber: end.weekNumber,
                endYearNumber: end.year,
              });
            }}
            disabledDays={
              data?.campaignCreateDate
                ? [
                    {
                      lt: DateTime.fromISO(data.campaignCreateDate, {
                        zone: "utc",
                      }).minus({
                        week: 1,
                      }),
                    },
                    {
                      gt: DateTime.utc().minus({ weeks: 1 }).endOf("week"),
                    },
                  ]
                : []
            }
          />
        </Stack>
      ),
    },
  ];

  return <FiltersContainer items={items} />;
};

export { Filters };
