import { useToast } from "@chakra-ui/react";
import { TalToolsTypes } from "@intentsify/types";
import {
  ProgressEventDetails,
  ValueValidationError,
  ZodCsvValidator,
  validateCsvStream,
} from "@intentsify/utils/csvStream/browser";
import { useEffect, useState } from "react";
import { ErrorCode, useDropzone } from "react-dropzone";
import { useUser } from "store/store.hooks";
import { Simplify } from "type-fest";
import { PublicConfirmDialogState } from "../../../../../components/ConfirmDialog/ConfirmDialog.state";
import { useConfirm } from "../../../../../components/ConfirmDialog/useConfirm";
import { talToolsUpload } from "../../../../../upload/talToolsUpload";
import { useTalPreparationToolsFiles } from "./useTalPreparationToolsFiles";

type UseTalToolDropzoneOptions = {
  resumeUploadConfirm: Simplify<PublicConfirmDialogState>;
  csvErrorConfirmBuilder(options: {
    hasMoreErrorsThanTheLimit: boolean;
    totalErrorsCount: number;
    errorSamples: ValueValidationError[];
  }): Simplify<PublicConfirmDialogState>;
  csvValidator(): ZodCsvValidator;
};

// There is a problem with uploading via TUS for bigger files like 20MB.
// On other hand on small files it works fine (less than 1MB)
// going with bigger file size like 20MB and have infinite file chunk (default)
// it fails after uploading ~1.2MB in some middle state.
// However going with too small chunk size (1MB) it fails for 20MB file
// after uploading after 7 or 8 chunk and stops.
// BUT!!! using 20MB chunk size and 80MB file size it works fine.
//
// All this has been discovered by testing on local machine, and this
// function is represent HACK for this unclear behaviour.
const getTusChunkFileSize = (fileSize: number) => {
  const MB = 1024 * 1024;

  // small files not a problem, but overall no need for TUS
  if (fileSize < MB) {
    return MB;
  }

  // too small files lead to stop after few upload
  // but we overall don't want to have too big chunk size
  return Math.min(Math.floor(fileSize / 2), 20 * MB);
};

export const useTalToolDropzone = (
  talToolsType: TalToolsTypes,
  {
    resumeUploadConfirm,
    csvErrorConfirmBuilder,
    csvValidator,
  }: UseTalToolDropzoneOptions
) => {
  const user = useUser();
  const [lastUploadedFile, setLastUploadedFile] = useState("");
  const { data: talFiles, refetch: refetchFiles } =
    useTalPreparationToolsFiles(talToolsType);

  const toast = useToast();
  const uploadFile = talToolsUpload.useUploadFile();
  const resumeUploads = talToolsUpload.useResumeUploads();
  const removeAllFiles = talToolsUpload.useRemoveAllFiles();
  const hasResumableFiles = talToolsUpload.useHasResumableFiles();

  const [validationProgress, setValidationProgress] = useState<
    number | undefined
  >(undefined);
  const confirm = useConfirm();
  useEffect(() => {
    const resume = async () => {
      if (await confirm(resumeUploadConfirm)) {
        resumeUploads();
      } else {
        removeAllFiles();
      }
    };

    if (hasResumableFiles) {
      void resume();
    }
  }, [
    hasResumableFiles,
    resumeUploads,
    removeAllFiles,
    confirm,
    resumeUploadConfirm,
  ]);
  const isTooManyFiles = (talFiles ?? []).length >= 25;

  const dropzone = useDropzone({
    multiple: false,
    accept: { "text/csv": [".csv"] },
    maxFiles: 1,
    minSize: 0,
    disabled: validationProgress !== undefined || isTooManyFiles,
    maxSize: 100 * 1024 * 1024,
    onDropRejected: (issues) => {
      const isFileTooLarge = issues.find((rejection) =>
        rejection.errors.find((error) => error.code === ErrorCode.FileTooLarge)
      );

      if (isFileTooLarge) {
        toast({
          status: "error",
          description: "File too large. Maximum size allowed: 100MB.",
          duration: null,
          isClosable: true,
        });

        return;
      }

      toast({
        status: "error",
        description: issues[0].errors[0].message,
        duration: null,
        isClosable: true,
      });
    },
    onDrop: (files) => {
      const [file] = files;

      if (!file) {
        return;
      }

      if ((talFiles ?? []).some(({ fileName }) => fileName === file.name)) {
        toast({
          status: "error",
          description: "File with such name was already uploaded",
          duration: null,
          isClosable: true,
        });

        return;
      }

      const upload = () =>
        uploadFile(file, {
          chunkSize: getTusChunkFileSize(file.size),
          onSuccess: () => {
            toast({
              status: "success",
              description: "File uploaded successfully",
            });
            refetchFiles().then((files) => {
              const remoteFile = files.data?.find(
                (remoteFileName) => remoteFileName.fileName === file.name
              );
              if (remoteFile) {
                setLastUploadedFile(remoteFile.fileName);
              }
            });
          },
          onError: () => {
            toast({
              status: "error",
              description: "Failed to upload file",
              duration: null,
              isClosable: true,
            });
          },
          metadata: {
            filename: file.name,
            filetype: file.type,
            type: talToolsType,
            userId: String(user?.userId),
          },
        });

      const fileSize = file.size;
      const { events, whenComplete } = validateCsvStream(
        file.stream().pipeThrough(new TextDecoderStream()),
        csvValidator()
      );
      events.addEventListener("progress", ((
        e: CustomEvent<ProgressEventDetails>
      ) => {
        setValidationProgress(e.detail.proceed / fileSize);
      }) as EventListener);
      whenComplete
        .finally(() => {
          setValidationProgress(undefined);
        })
        .then(
          async ({
            isValid,
            totalErrorsCount,
            hasMoreErrorsThanTheLimit,
            errorSamples,
          }) => {
            if (!isValid) {
              if (
                await confirm(
                  csvErrorConfirmBuilder({
                    hasMoreErrorsThanTheLimit,
                    totalErrorsCount,
                    errorSamples,
                  })
                )
              ) {
                upload();
              }
            } else {
              upload();
            }
          }
        )
        .catch((err: Error) => {
          toast({
            status: "error",
            description: "CSV is invalid: " + err.message,
            duration: null,
            isClosable: true,
          });
        });
    },
  });

  return {
    ...dropzone,
    isTooManyFiles,
    validationProgress,
    talFiles,
    lastUploadedFile,
  };
};
