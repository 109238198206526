import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { CampaignImpressionsByLocationRO } from "@intentsify/dto";
import { CampaignImpressionsByLocation } from "@intentsify/types";

interface UseCampaignImpressionsByLocationOptions<T> {
  id: number;
  filters: T;
  fetchData: (
    id: number,
    filters: T
  ) => Promise<CampaignImpressionsByLocationRO>;
}

export type UseCampaignImpressionsByLocationReturnType = {
  isFetching: boolean;
  data: CampaignImpressionsByLocationRO;
  maxCountImpressions: number;
  geoJson: {
    type: "FeatureCollection";
    features: {
      type: "Feature";
      properties: { count: number };
      geometry: { type: "Point"; coordinates: number[] };
    }[];
  };
};

const campaignImpressionsByLocationEntryToGeoJsonFeature = ({
  longitude,
  latitude,
  count,
}: CampaignImpressionsByLocation) => ({
  type: "Feature" as const,
  properties: { count },
  geometry: {
    type: "Point" as const,
    coordinates: [longitude, latitude],
  },
});

export const useCampaignImpressionsByLocation = <T>({
  id,
  filters,
  fetchData,
}: UseCampaignImpressionsByLocationOptions<T>): UseCampaignImpressionsByLocationReturnType => {
  const { data, isFetching } = useQuery(
    ["getImpressionsByLocation", id, filters],
    () => fetchData(id, filters),
    { suspense: true }
  );

  const geoJson = useMemo(() => {
    const features = (data?.impressionsByLocation || []).map(
      campaignImpressionsByLocationEntryToGeoJsonFeature
    );

    return {
      type: "FeatureCollection" as const,
      features,
    };
  }, [data]);

  const maxCountImpressions = data?.highestSignalsCount || 100;

  return {
    isFetching,
    data: (data || []) as CampaignImpressionsByLocationRO,
    geoJson,
    maxCountImpressions,
  };
};
