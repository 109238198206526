/* eslint-disable react-hooks/rules-of-hooks */
import { ReportType } from "@intentsify/types";
import { Endpoints } from "api";
import { useEffect } from "react";
import { useParams } from "react-router";
import {
  Download,
  useMostRecentDownloadStatus,
} from "screens/Download/Download";
import { CampaignFileDownloadedProperties } from "tracking/amplitude";
import { useTrackCampaignFileDownloaded } from "tracking/useTrackCampaignFileDownloaded";
import { useDownloadFile, useScreen } from "utils";
import { DownloadReportDefinition } from "./DownloadReport.definition";
import { DownloadStatus } from "../Download/DownloadStatus";

const getEventFromReportType = (
  reportType: ReportType
): CampaignFileDownloadedProperties["type"] => {
  switch (reportType) {
    case ReportType.Spotlight:
      return "campaign spotlight";
    case ReportType.WeeklySnapshot:
      return "campaign weekly snapshot";
    case ReportType.ProgramReview:
      return "campaign program review";
    case ReportType.CumulativeAdSolutions:
      return "campaign cumulative ad solutions";
    case ReportType.CumulativeIntentFeedBRS:
      return "campaign cumulative intent feed brs";
    case ReportType.CumulativeIntentFeedTopics:
      return "campaign cumulative intent feed topics";
    case ReportType.CumulativeSitePixelData:
      return "campaign cumulative site pixel data";
    default:
      throw Error("unsupported deck kind");
  }
};

const DownloadReport = () => {
  useScreen(DownloadReportDefinition);

  const { reportType } = useParams<"reportType">();

  if (reportType === "spotlight-reports") {
    return <Download />;
  }

  const { campaignId } = useParams<"campaignId">();
  const { correlationId } = useParams<"correlationId">();

  if (
    !reportType ||
    !Object.values(ReportType).includes(reportType as ReportType)
  ) {
    throw new Error("404");
  }

  const reportTypeTyped = reportType as ReportType;

  const { download } = useDownloadFile();
  const fileName = `${String(campaignId)}_${reportType}`;
  const downloadingStatus = useMostRecentDownloadStatus(fileName);
  const trackCampaignFileDownloaded = useTrackCampaignFileDownloaded();

  useEffect(() => {
    if (campaignId && correlationId) {
      download({
        url: Endpoints.Reports.DownloadReport.Get({
          campaignId,
          correlationId,
          reportType: reportTypeTyped,
        }),
        fileName,
        fileExtension: [
          ReportType.CumulativeAdSolutions,
          ReportType.CumulativeIntentFeedBRS,
          ReportType.CumulativeIntentFeedTopics,
          ReportType.CumulativeSitePixelData,
        ].includes(reportTypeTyped)
          ? "csv"
          : "pdf",
      });

      trackCampaignFileDownloaded({
        type: getEventFromReportType(reportTypeTyped),
        campaignIds: [Number(campaignId)],
      });
    }
    // We want it to trigger once only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <DownloadStatus status={downloadingStatus} />;
};

export { DownloadReport };
