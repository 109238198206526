import { Box, Divider, Flex } from "@chakra-ui/layout";
import { Heading, RadioGroup, Spinner, Text } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import type { TusUpload } from "@intentsify/tus/dist/react";
import { TalToolsTypes } from "@intentsify/types";
import { Button } from "components";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useComponentColors } from "theme";
import { z } from "zod";
import { TusUploadProgress } from "../../../../../../../components/Upload/TusUploadProgress";
import { talToolsUpload } from "../../../../../../../upload/talToolsUpload";
import { useTalPreparationToolsFiles } from "../../../hooks/useTalPreparationToolsFiles";
import { TalToolsFiles } from "../../TalToolsFiles/TalToolsFiles";
import { useCompanyNameToDomain } from "../hooks/useCompanyNameToDomain";

const companyNameToDomainSchema = z.object({
  fileName: z.string().nonempty(),
});

type CompanyNameToDomainProps = {
  lastUploadedFile: string;
};

export const CompanyNameToDomainForm = ({
  lastUploadedFile,
}: CompanyNameToDomainProps) => {
  const componentColors = useComponentColors();
  const tusFiles = talToolsUpload.useFiles();
  const { mutateAsync: requestCompanyNameToDomain, isLoading: isSubmitting } =
    useCompanyNameToDomain();
  const { data: files, isFetching: isLoadingFiles } =
    useTalPreparationToolsFiles(TalToolsTypes.COMPANY_NAME_TO_DOMAIN);

  const { control, handleSubmit, reset, formState, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      fileName: "",
    },
    resolver: zodResolver(companyNameToDomainSchema),
  });
  const uploadingFiles = tusFiles.filter(
    ({ status }) => status === "uploading"
  );

  const onSubmitHandler = handleSubmit(async (data) => {
    await requestCompanyNameToDomain({
      fileName: data.fileName,
    });
    reset();
  }) as () => void;

  useEffect(() => {
    if (lastUploadedFile) {
      setValue("fileName", lastUploadedFile, { shouldValidate: true });
    }
  }, [lastUploadedFile, setValue]);

  return (
    <Box as="form" onSubmit={onSubmitHandler}>
      <Box>
        <Heading as="h3" size="sm" my="4">
          Uploaded Files
        </Heading>
        {uploadingFiles.length > 0 && (
          <TusUploadProgress
            tusUpload={talToolsUpload as unknown as TusUpload}
          />
        )}
        {isLoadingFiles && (
          <Spinner
            thickness="4px"
            speed="0.75s"
            size="md"
            {...componentColors.spinner}
          />
        )}
        {!isLoadingFiles && !files?.length && (
          <Text fontSize="sm">Uploaded files will appear here.</Text>
        )}
        {Boolean(files?.length) && (
          <Controller
            name="fileName"
            control={control}
            render={({ field }) => (
              <RadioGroup
                onChange={(e) => {
                  field.onBlur();
                  field.onChange(e);
                }}
                value={field.value}
              >
                <TalToolsFiles
                  talToolsType={TalToolsTypes.COMPANY_NAME_TO_DOMAIN}
                  files={files ?? []}
                  onFileDelete={(deletedFileName) => {
                    if (deletedFileName === field.value) {
                      field.onChange("");
                    }
                  }}
                />
              </RadioGroup>
            )}
          />
        )}
      </Box>

      <Divider mt={4} />

      <Flex mt={4} justifyContent="flex-end">
        <Button
          size="md"
          variant="primary-teal"
          isLoading={isSubmitting}
          isDisabled={!formState.isValid}
          type="submit"
        >
          Process Company Names
        </Button>
      </Flex>
    </Box>
  );
};
