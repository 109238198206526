import { Flex, Image, List, ListItem, Text } from "@chakra-ui/react";
import {
  AuthenticatedUser,
  BuyerResearchStage,
  CampaignDetails,
  CampaignMeta,
  Dynamic1Status,
  SaasRoleName,
} from "@intentsify/types";
import { isDefined, isPreviousWeek } from "@intentsify/utils";
import { ColumnDef, Row } from "@tanstack/react-table";
import { Badge, CollapsingCell, EMPTY_CELL, EmptyCell } from "components";
import { useMemo } from "react";
import { SpotlightButtonComponent } from "./components/SpotlightButton";

type UseColumnsOptions = {
  user: AuthenticatedUser | null;
  campaignId: number;
  campaignMeta: CampaignMeta | undefined;
  weekNumber: number;
  yearNumber: number;
};

const useColumns = ({
  user,
  campaignMeta,
  campaignId,
  weekNumber,
  yearNumber,
}: UseColumnsOptions): ColumnDef<CampaignDetails>[] => {
  return useMemo(() => {
    const dynamic1Status = campaignMeta?.dynamic1Status ?? null;

    if (user?.saasRole === SaasRoleName.Publisher) {
      return [
        {
          header: "",
          accessorKey: "logoUrl",
          enableSorting: false,
          cell: ({ row }) => (
            <Flex display="flex">
              {row.original?.logoUrl && (
                <Image
                  maxHeight={"50px"}
                  maxWidth={"50px"}
                  src={row.original?.logoUrl}
                  mr={2}
                />
              )}
            </Flex>
          ),
        },
        {
          header: "Account",
          accessorKey: "domainName",
        },
        {
          header: "Buyer Research Stage",
          accessorKey: "buyerResearchStage",
          cell: ({ row }) => {
            return (
              <Badge fontWeight="bold">
                {row.original?.buyerResearchStage ||
                  BuyerResearchStage.Unspecified}
              </Badge>
            );
          },
        },
        {
          header: "Active Locations",
          accessorKey: "locations",
          enableSorting: false,
          cell: ({ row }) => {
            if (!row.original?.locations) {
              return <EmptyCell />;
            }
            return (
              <List>
                {row.original?.locations
                  .split("|")
                  .slice(0, 5)
                  .map((location, index) => (
                    <ListItem key={index}>{location}</ListItem>
                  ))}
              </List>
            );
          },
        },
      ];
    }

    return [
      {
        header: "",
        accessorKey: "logoUrl",
        enableSorting: false,
        cell: ({ row }: { row: Row<CampaignDetails> }) => (
          <Flex display="flex">
            {row.original?.logoUrl && (
              <Image
                maxHeight={"50px"}
                maxWidth={"50px"}
                src={row.original?.logoUrl}
                mr={2}
              />
            )}
          </Flex>
        ),
      },
      {
        header: "Account",
        accessorKey: "domainName",
      },
      {
        header: "Buyer Research Stage",
        accessorKey: "buyerResearchStage",
        cell: ({ row }: { row: Row<CampaignDetails> }) => {
          return (
            <Badge fontWeight="bold">
              {row.original?.buyerResearchStage ||
                BuyerResearchStage.Unspecified}
            </Badge>
          );
        },
      },
      dynamic1Status === Dynamic1Status.Completed
        ? {
            header: "Dynamic1 Rank",
            accessorKey: "d1Rank",
            cell: ({ row }: { row: Row<CampaignDetails> }) => {
              return row.original?.d1Rank ? (
                <Text>{row.original?.d1Rank}</Text>
              ) : (
                <EmptyCell />
              );
            },
          }
        : undefined,
      {
        header: "Signal Strength",
        accessorKey: "signalStrength",
        cell: ({ row }: { row: Row<CampaignDetails> }) => {
          const signalStrength = row.original.signalStrength;
          const aggregateScore = row.original.aggregateScore;

          if (!signalStrength) {
            return <>{aggregateScore > 10 ? 10 : aggregateScore}</>;
          }

          if (signalStrength === 0) {
            return EMPTY_CELL;
          }

          return <>{signalStrength}</>;
        },
      },
      {
        header: "Website Activity",
        accessorKey: "websiteActivity",
        enableSorting: false,
        cell: ({ row }: { row: Row<CampaignDetails> }) => {
          const websiteActivity = row.original.websiteActivity;

          if (!websiteActivity) {
            return EMPTY_CELL;
          }

          return <>{websiteActivity}</>;
        },
      },
      {
        header: "Active Topics",
        accessorKey: "activeTopics",
        enableSorting: false,
        cell: ({ row }: { row: Row<CampaignDetails> }) => {
          if (!row.original?.activeTopics) {
            return <EmptyCell />;
          }

          const activeTopics = row.original?.activeTopics
            .split("|")
            .slice(0, 10);

          return activeTopics.length > 3 ? (
            <CollapsingCell>
              <List>
                {activeTopics.map((topic, index) => (
                  <ListItem key={index}>{topic}</ListItem>
                ))}
              </List>
            </CollapsingCell>
          ) : (
            <List>
              {activeTopics.map((topic, index) => (
                <ListItem key={index}>{topic}</ListItem>
              ))}
            </List>
          );
        },
      },

      {
        header: "Active Locations",
        accessorKey: "locations",
        enableSorting: false,
        cell: ({ row }: { row: Row<CampaignDetails> }) => {
          if (!row.original?.locations) {
            return <EmptyCell />;
          }
          return (
            <List>
              {row.original?.locations
                .split("|")
                .slice(0, 5)
                .map((location, index) => (
                  <ListItem key={index}>{location}</ListItem>
                ))}
            </List>
          );
        },
      },
      isPreviousWeek(weekNumber, yearNumber)
        ? {
            header: "",
            accessorKey: "spotlight",
            enableSorting: false,
            cell: ({ row }: { row: Row<CampaignDetails> }) => {
              if (!row.original.isSpotlightAvailable) {
                return null;
              }
              return (
                <SpotlightButtonComponent
                  campaignId={campaignId}
                  domainId={row.original.domainId}
                  campaignDetails={row.original}
                  campaignMeta={campaignMeta}
                  buyerResearchStage={row.original?.buyerResearchStage}
                />
              );
            },
          }
        : undefined,
    ].filter(isDefined);
  }, [campaignId, campaignMeta, user?.saasRole, weekNumber, yearNumber]);
};

export { useColumns };
