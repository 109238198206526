import { useQuery } from "@tanstack/react-query";
import { apiService, Endpoints } from "api";
import { TechnographicProductTreeRO } from "@intentsify/dto";

export const useTechnographicProducts = () => {
  return useQuery(
    [Endpoints.TalTools.FirmoTechno.TechnoProductFilters],
    async ({ signal }) => {
      const response = await apiService.get<TechnographicProductTreeRO[]>(
        Endpoints.TalTools.FirmoTechno.TechnoProductFilters,
        { signal }
      );
      return response.data;
    }
  );
};
