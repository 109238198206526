import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Duration } from "luxon";
import { PropsWithChildren } from "react";
import { useSetQueryClientDefaultsEffect } from "./useSetQueryClientDefaultsEffect";

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retryDelay: 300,
      useErrorBoundary: false,
    },
    queries: {
      retryDelay: 300,
      keepPreviousData: true,
      staleTime: Duration.fromObject({ minutes: 10 }).as("millisecond"),
    },
  },
});

export const PortalQueryClientProvider = (props: PropsWithChildren) => {
  useSetQueryClientDefaultsEffect(queryClient);

  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
    </QueryClientProvider>
  );
};
