import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Center,
  DarkMode,
  Divider,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { Button } from "components";
import { MdAccountCircle } from "react-icons/md";
import { RiSpyFill } from "react-icons/ri";
import { useAppStore } from "store/useAppStore";
import { useUserProfile } from "../../../../../../utils/useUserProfile";
import { Actions } from "./Actions";
import { Header } from "./Header/Header";

export const ProfileMenu = () => {
  const { displayName } = useUserProfile();

  return (
    <Menu isLazy autoSelect={false} offset={[0, 20]} closeOnSelect={false}>
      <DarkMode>
        <MenuButton
          data-testid="profile-button"
          as={Button}
          rightIcon={<ChevronDownIcon />}
          variant="ghost"
          size="md"
          height="auto"
          pr="2"
          pl="1"
        >
          <Center gap="1">
            <UserAccountIcon />
            <Text fontWeight="medium">{displayName}</Text>
          </Center>
        </MenuButton>
      </DarkMode>

      <MenuList
        display="flex"
        minW="340px"
        p="3"
        gap="2"
        bg="brand.400"
        flexDirection="column"
        boxShadow="dark-lg"
        _light={{
          bg: "white",
          borderColor: "blackAlpha.300",
          boxShadow: "lg",
        }}
      >
        <Header />
        <Divider mt="2" mb="0.5" />
        <Actions />
      </MenuList>
    </Menu>
  );
};

const UserAccountIcon = () => {
  const impersonation = useAppStore.use.impersonation();

  if (impersonation) {
    return (
      <Icon as={RiSpyFill} height="24px" width="24px" color="yellow.300" />
    );
  }

  return (
    <Icon as={MdAccountCircle} width="24px" height="24px" color="gray.300" />
  );
};
