import { useDisclosure, useToast } from "@chakra-ui/react";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import { UpdateCampaignAccountsDTO } from "@intentsify/dto";
import { Dynamic1Status, RoomEventType, SocketRoom } from "@intentsify/types";
import { useQueryClient } from "@tanstack/react-query";
import {
  Loader,
  MenuWithContent,
  StatusModal,
  ViewContainer,
} from "components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  haveAdvancedSettingsChanged,
  haveDomainsSettingsChanged,
  haveKeywordsAndTopicsChanged,
  haveSettingsChanged,
} from "screens/Campaigns/screens/YourCampaigns/YourCampaigns.utils";
import { MenuWithContentItem } from "types";
import { useScreen } from "utils";
import { useRoomEvent } from "webSocket";
import { getLocalRelativeUrl } from "../../../../utils/url";
import {
  useCampaign,
  useNavigateToCampaigns,
  useNothingToSaveToast,
} from "./CampaignsWizard.hooks";
import { isMutateCampaignSettingsParams } from "./CampaignsWizard.requests";
import {
  EditCampaignScreenDefinition,
  useEditCampaignNavigation,
} from "./EditCampaign.definition";
import {
  ActivationSettingsStep,
  DomainsStep,
  KeywordsAndTopicsStep,
  SettingsStep,
  isSavingWizardAtom,
} from "./components";
import { BuyingGroupStep } from "./components/BuyingGroupStep";
import { haveCampaignsPersonasChanges } from "./components/BuyingGroupStep/BuyingGroupForm/BuyingGroupForm.hooks";
import { Dynamic1Modal } from "./components/KeywordsAndTopicsStep/KeywordsAndTopicsForm";

const EditCampaign = () => {
  const nothingToSaveToast = useNothingToSaveToast();
  const { campaignId } = useParams<"campaignId">();
  const step = Number(useParams<"step">().step);
  const navigateToCampaigns = useNavigateToCampaigns();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const isSaving = useRecoilValue(isSavingWizardAtom);
  const editCampaignNavigation = useEditCampaignNavigation();
  const queryClient = useQueryClient();

  useEffect(() => {
    toast.closeAll();
  }, [step, toast]);

  if (!campaignId) {
    throw new Error("404");
  }

  useRoomEvent(
    `${SocketRoom.EDIT_CAMPAIGN}_${campaignId}`,
    RoomEventType.ACCOUNTS_SUMMARY_UPDATE,
    (event) => {
      if (step === 2) {
        queryClient.invalidateQueries(["campaign", event.data.campaignId]);
      }
    }
  );

  const onEditSuccess = (shouldExitOnSuccess: boolean) => {
    toast.closeAll();
    toast({
      title: "Campaign has been updated.",
      status: "success",
    });
    if (shouldExitOnSuccess) {
      // for security reason we need to ensure that there no external URLs present
      const from = getLocalRelativeUrl(
        new URLSearchParams(location.search).get("from") ?? ""
      );
      if (from) {
        navigate(from);
      } else {
        navigateToCampaigns();
      }
    }
  };

  const {
    isLoadingCampaign,
    campaignSettings,
    campaignsDomains,
    campaignKeywordsAndTopics,
    campaignAdvancedSettings,
    updateCampaignSettings,
    updateDomainsSettings,
    updateCampaignKeywordsAndTopics,
    updateCampaignAdvancedSettings,
    updateCampaignBuyingGroup,
    campaignBuyingGroup,
  } = useCampaign({
    campaignId: Number(campaignId),
  });
  const hasAccessToBuyingGroup = useHasAccessTo("targetPersona");

  useScreen(EditCampaignScreenDefinition, {
    subtitle: campaignSettings.displayName
      ? `${campaignSettings.displayName} (${campaignId})`
      : undefined,
  });

  // Needed for displaying D1 Modal, for D1 enabled campaigns in TAL edit.
  // This modal must be shown before regular "saving" modal.
  const [domainSettings, setDomainSettings] = useState<
    | {
        haveFilesChanged: boolean;
        dto: UpdateCampaignAccountsDTO;
      }
    | undefined
  >(undefined);

  const [
    shouldExitOnSuccessInDomainsStep,
    setShouldExitOnSuccessInDomainsStep,
  ] = useState(false);

  const {
    isOpen: isD1ModalModalOpen,
    onOpen: onD1ModalOpen,
    onClose: onD1ModalClose,
  } = useDisclosure();

  const items: MenuWithContentItem[] = [
    {
      label: "Program Settings",
      onClick: (index: number) =>
        editCampaignNavigation({
          campaignId: Number(campaignId),
          step: index + 1,
        }),
      content: (
        <SettingsStep
          editing
          onSubmit={(params) => {
            if (isMutateCampaignSettingsParams(params)) {
              if (!haveSettingsChanged(campaignSettings, params.settings)) {
                nothingToSaveToast();
                return;
              }

              updateCampaignSettings({
                settings: params.settings,
                shouldExitOnSuccess: params.shouldExitOnSuccess,
                onSuccessCallback: () => {
                  onEditSuccess(params.shouldExitOnSuccess);
                },
              });
            }
          }}
        />
      ),
    },
    {
      label: "Accounts",
      onClick: (index: number) =>
        editCampaignNavigation({
          campaignId: Number(campaignId),
          step: index + 1,
        }),
      content: (
        <DomainsStep
          editing
          onSubmit={({ haveFilesChanged, dto, shouldExitOnSuccess }) => {
            if (
              !haveDomainsSettingsChanged(
                campaignsDomains.firmographicsFilters,
                dto.firmographicsFilters,
                haveFilesChanged,
                campaignsDomains.domainEnhancementStrategy,
                dto.domainEnhancementStrategy
              )
            ) {
              nothingToSaveToast();
              return;
            }

            if (
              ![Dynamic1Status.Unspecified, Dynamic1Status.Disabled].includes(
                campaignKeywordsAndTopics.dynamicOneStatus
              )
            ) {
              setDomainSettings({ haveFilesChanged, dto });
              setShouldExitOnSuccessInDomainsStep(shouldExitOnSuccess);
              onD1ModalOpen();
            } else {
              updateDomainsSettings({
                haveFilesChanged,
                dto,
                shouldExitOnSuccess,
                onSuccessCallback: () => {
                  onEditSuccess(shouldExitOnSuccess);
                },
              });
            }
          }}
        >
          <Dynamic1Modal
            isOpen={isD1ModalModalOpen}
            onOk={() => {
              if (!domainSettings || !domainSettings?.dto) {
                return;
              }

              const { haveFilesChanged, dto } = domainSettings;

              updateDomainsSettings({
                haveFilesChanged,
                dto,
                shouldExitOnSuccess: shouldExitOnSuccessInDomainsStep,
                onSuccessCallback: () => {
                  onEditSuccess(shouldExitOnSuccessInDomainsStep);
                },
              });

              onD1ModalClose();
            }}
          />
        </DomainsStep>
      ),
    },
    ...(hasAccessToBuyingGroup
      ? [
          {
            label: "Buying Group",
            onClick: (index: number) =>
              editCampaignNavigation({
                campaignId: Number(campaignId),
                step: index + 1,
              }),
            content: (
              <BuyingGroupStep
                editing
                companyId={campaignSettings.companyId}
                onSubmit={({ newSettings, shouldExitOnSuccess }) => {
                  if (
                    !haveCampaignsPersonasChanges(
                      campaignBuyingGroup,
                      newSettings.selectedPersonas,
                      newSettings.maxBuyerGroupSizePerAccount,
                      newSettings.buyingGroupContactsAccounts
                    )
                  ) {
                    nothingToSaveToast();
                    return;
                  }

                  updateCampaignBuyingGroup({
                    newSettings,
                    shouldExitOnSuccess,
                    onSuccessCallback: () => {
                      onEditSuccess(shouldExitOnSuccess);
                    },
                  });
                }}
              />
            ),
          },
        ]
      : []),

    {
      label: "Keywords & Topics",
      onClick: (index: number) =>
        editCampaignNavigation({
          campaignId: Number(campaignId),
          step: index + 1,
        }),
      content: (
        <KeywordsAndTopicsStep
          editing
          id={Number(campaignId)}
          campaignKeywordsAndTopics={campaignKeywordsAndTopics}
          onSubmit={({ newSettings, shouldExitOnSuccess }) => {
            if (
              !haveKeywordsAndTopicsChanged(
                campaignKeywordsAndTopics,
                newSettings
              )
            ) {
              nothingToSaveToast();
              return;
            }

            updateCampaignKeywordsAndTopics({
              newSettings,
              shouldExitOnSuccess,
              onSuccessCallback: () => {
                onEditSuccess(shouldExitOnSuccess);
              },
            });
          }}
        />
      ),
    },
    {
      label: "Activation Settings",
      onClick: (index: number) =>
        editCampaignNavigation({
          campaignId: Number(campaignId),
          step: index + 1,
        }),
      content: (
        <ActivationSettingsStep
          editing
          id={Number(campaignId)}
          campaignAdvancedSettings={campaignAdvancedSettings}
          onSubmit={({ newSettings, shouldExitOnSuccess }) => {
            if (
              !haveAdvancedSettingsChanged(
                campaignAdvancedSettings,
                newSettings
              )
            ) {
              nothingToSaveToast();
              return;
            }

            updateCampaignAdvancedSettings({
              newSettings,
              shouldExitOnSuccess,
              onSuccessCallback: () => {
                onEditSuccess(shouldExitOnSuccess);
              },
            });
          }}
        />
      ),
    },
  ];

  return (
    <>
      <ViewContainer compact stretch noPadding>
        <MenuWithContent
          stretch
          currentItemIndex={step - 1}
          loaderComponent={<Loader />}
          isLoading={isLoadingCampaign}
          items={items}
        />
      </ViewContainer>

      <StatusModal status="Saving changes..." isOpen={isSaving} />
    </>
  );
};

export { EditCampaign };
