import { Box, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { Option } from "@intentsify/types";
import { Search } from "components";
import { FilterAsync } from "components/FilterAsync";
import { useParams } from "react-router";
import { CampaignsLeadsFilters } from "screens/Campaigns/screens/CampaignLeads/CampaignsLeads";
import { useTrackSearched } from "tracking/useTrackSearched";
import { listPaginatedAccountsLeads } from "./Filters.requests";

type FiltersProps = {
  setFilters: (val: Record<string, Option[] | string>) => void;
  filters: CampaignsLeadsFilters;
};

const Filters = ({ setFilters, filters }: FiltersProps) => {
  const { campaignId } = useParams<"campaignId">();
  const trackSearched = useTrackSearched();
  const { accounts, search } = filters;

  if (!campaignId) {
    return null;
  }

  return (
    <SimpleGrid
      gridTemplateColumns="repeat(auto-fit, minmax(400px, 400px))0px"
      spacing={4}
      mb={4}
    >
      <Box w={400}>
        <FilterAsync
          defaultOptions={[]}
          dataRequest={listPaginatedAccountsLeads(Number(campaignId))}
          label="Accounts"
          placeholder="Select accounts"
          onFilterValuesChange={(option) => {
            setFilters({ accounts: option });
          }}
          resetFilterRequestDependencies={[campaignId]}
          currentValue={accounts}
        />
      </Box>

      <Stack w={400}>
        <Text fontSize="xs" fontWeight="semibold">
          Search:
        </Text>
        <Search
          size="sm"
          w="100%"
          currentValue={search}
          onSearch={(val) => {
            setFilters({ search: val });
            trackSearched({ term: val, collocation: "Campaign leads search" });
          }}
        />
      </Stack>
    </SimpleGrid>
  );
};

export { Filters };
