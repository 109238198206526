import {
  TusStorage,
  createTusUpload,
  FileUploadMetadata,
} from "@intentsify/tus/dist/react";
import { Endpoints } from "api";

export const talToolsUpload = createTusUpload({
  uploadUrl: new URL(
    `${process.env.VITE_REACT_APP_API_URL ?? `http://localhost:3001`}${
      Endpoints.TalTools.Upload
    }`
  ),
  autoResumeUploads: false,
  storage: new TusStorage<
    "talToolsUpload",
    FileUploadMetadata & {
      type: string;
      userId: string;
    }
  >("talToolsUpload"),
});
