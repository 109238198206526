import { DateRange, Option, SignalType, SplitBy } from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { Endpoints, apiService } from "api";
import { TrendingKeywordsOptions } from "screens/IntentAnalysis/screens/CampaignsTrendingSignals/CampaignsTrendingSignals.types";
import { valueToQueryURL } from "utils";
import { TrendingKeywordsWithTimeseriesResponse } from "./TrendingKeywords.types";

export const getMostActiveKeywords = async (
  id: string,
  dateRange: DateRange,
  splitBy: SplitBy
): Promise<Omit<TrendingKeywordsOptions, "isLoading">> => {
  const { data } = await apiService.get(
    Endpoints.Campaigns.Get.Signals.MostActiveSignals(Number(id)),
    {
      params: {
        dateRange,
        signalType: SignalType.Keywords,
        splitBy,
      },
    }
  );
  return data;
};

type MatchTermsActivityParams = {
  signals: Option<string>[] | undefined;
  campaignId: string | undefined;
  dateRange: DateRange;
  splitBy: SplitBy;
};

export const getMatchTermsActivity = async ({
  campaignId,
  dateRange,
  signals,
  splitBy,
}: MatchTermsActivityParams): Promise<TrendingKeywordsWithTimeseriesResponse> => {
  if (!campaignId || !isPopulatedArray(signals)) {
    return {
      data: [],
      timeseries: [],
    };
  }

  const { data } = await apiService.get<TrendingKeywordsWithTimeseriesResponse>(
    Endpoints.Campaigns.Get.Signals.SignalActivityWithTimeseries(
      Number(campaignId)
    ),
    {
      params: {
        dateRange,
        signals: valueToQueryURL(signals),
        splitBy,
        signalType: SignalType.Keywords,
      },
    }
  );
  return data;
};
