import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TalToolsTypes } from "@intentsify/types";
import { apiService, Endpoints } from "api";
import { talPreparationToolsFilesKey } from "./useTalPreparationToolsFiles";
import { TalToolFileRO } from "@intentsify/dto";

export const useDeleteTalToolFile = (type: TalToolsTypes) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (fileName: string) => {
      await apiService.delete(Endpoints.TalTools.Delete(type, fileName));

      return fileName;
    },
    onSuccess: (deletedFileName) => {
      const data = queryClient.getQueryData<TalToolFileRO[]>(
        talPreparationToolsFilesKey(type)
      );

      if (!data) {
        return;
      }

      queryClient.setQueryData(
        talPreparationToolsFilesKey(type),
        data.filter((file) => file.fileName !== deletedFileName)
      );
    },
  });
};
