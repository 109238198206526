import { DisplayPerformanceFilters } from "screens";
import { useQuery } from "@tanstack/react-query";
import { getPerformanceByDevice } from "./PerformanceByDevice.requests";
import { BarChart, Card, MIN_MODAL_CONTENT_HEIGHT, Select } from "components";
import { Box, Flex, useToken, Text as ChakraText } from "@chakra-ui/react";
import { Option } from "@intentsify/types";
import { useMemo, useState } from "react";
import { decimalToPercentage, toNumberDisplayValue } from "@intentsify/utils";

type PerformanceByDeviceProps = {
  campaignId: number;
  filters: DisplayPerformanceFilters;
};

type Mode = "impressions" | "clicks" | "conversions";

const modeOptions: Option<Mode>[] = [
  {
    value: "impressions",
    label: "Impressions",
  },
  {
    value: "clicks",
    label: "Clicks",
  },
];

export const PerformanceByDevice = ({
  campaignId,
  filters,
}: PerformanceByDeviceProps) => {
  const [modeValue, setModeValue] = useState<Option<Mode>>(modeOptions[0]);

  const yellow = useToken("colors", "yellow.500");
  const green = useToken("colors", "green.500");
  const teal = useToken("colors", "teal.500");
  const blue = useToken("colors", "blue.500");

  const { data: rawData, isFetching } = useQuery({
    queryKey: ["getPerformanceByDevice", campaignId, filters],
    queryFn: async () => await getPerformanceByDevice(campaignId, filters),
  });

  const data = useMemo(() => {
    if (isFetching || !rawData?.performanceByDevice) {
      return [];
    }

    return rawData.performanceByDevice.map((deviceType) => {
      return {
        label: deviceType.deviceName,
        value: deviceType[modeValue.value],
      };
    });
  }, [isFetching, modeValue, rawData]);

  return (
    <Card isLoading={isFetching} title={"Performance by device"} isExpandable>
      {(isExpanded) => (
        <Flex
          flexDir="column"
          h={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : "100%"}
        >
          <Flex mb={2}>
            <Flex alignItems="center">
              <Box minWidth={300} padding={2}>
                <Select
                  isClearable={false}
                  isMulti={false}
                  value={modeValue}
                  options={modeOptions}
                  onChange={(val) => {
                    if (val) {
                      setModeValue(val);
                    }
                  }}
                />
              </Box>
            </Flex>
          </Flex>
          <Flex>
            <BarChart
              minHeight={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : undefined}
              stretch
              valueAxisLabel={modeValue.label}
              orientation="horizontal"
              data={data}
              colorRange={[teal, yellow, green, teal, blue]}
              tooltipRenderer={
                modeValue.value === "clicks"
                  ? (textColor, tooltipData) => {
                      const d = rawData?.performanceByDevice.find(
                        (el) => el.deviceName === tooltipData.label
                      );
                      return (
                        <>
                          <ChakraText fontSize="medium" color={textColor}>
                            <strong>{modeValue.label}:</strong>{" "}
                            {toNumberDisplayValue(tooltipData.value)}
                          </ChakraText>
                          {d && d.impressions !== 0 && (
                            <ChakraText fontSize="medium" color={textColor}>
                              <strong>CTR: </strong>{" "}
                              {decimalToPercentage(d.clicks / d.impressions)}%
                            </ChakraText>
                          )}
                        </>
                      );
                    }
                  : undefined
              }
              valueNumTicks={data.every((item) => item.value === 0) ? 1 : 6}
            />
          </Flex>
        </Flex>
      )}
    </Card>
  );
};
