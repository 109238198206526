import {
  AudienceSegmentDefinition,
  CampaignDetails,
  Option,
  Order,
  Range,
} from "@intentsify/types";
import { FetchDataParams } from "types";

export type FetchDataParamsCampaignsDetails<T> = Order<T> & {
  page: number;
  per_page: number;
  filter?: string;
  weekNumber: number;
  yearNumber: number;
};

export type CampaignDetailsAvailableSignals = {
  campaignId: number;
  domainId: number;
  weekNumber: number;
  yearNumber: number;
};

export type CampaignDetailsAggregatedSignal = {
  campaignId: number;
  domainId: number;
  signalId: number;
  weekNumber: number;
  yearNumber: number;
};

export enum TopicStage {
  Unspecified = "",
  Late = "Late",
  Early = "Early",
}

export type CampaignDetailsFilters = {
  keywords: Option[];
  topics: Option[];
  topicStage: TopicStage;
  domains: Option[];
  buyerResearchStage: Option[];
  locations: Option[];
  customSources: Option[];
  signalStrength: Range;
  activeSources: Range;
  hasWebsiteActivity: boolean;
  hasLeads: boolean;
  hasClicks: boolean;
  hasImpressions: boolean;
  includeContactsCount: boolean;
  weekNumber: number;
  yearNumber: number;
};

export type AudienceSegmentDefinitionWithDate = AudienceSegmentDefinition & {
  weekNumber: number;
  yearNumber: number;
};

export type CampaignDetailsFiltersWithoutDate = Omit<
  AudienceSegmentDefinition,
  "weekNumber" | "yearNumber"
>;

export type PaginatedCampaignDetailsParams = FetchDataParams<
  keyof CampaignDetails,
  AudienceSegmentDefinition
>;

export type CampaignDetailsTopLocations = CampaignDetailsAvailableSignals;
