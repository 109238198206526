import { Box, ColorMode, useColorMode } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

type UploadedFilesWrapperProps = PropsWithChildren & {
  variant: "plain" | "blue";
};

const getVariantProps = (
  variant: UploadedFilesWrapperProps["variant"],
  colorMode: ColorMode
) => {
  switch (variant) {
    case "blue":
      return {
        backgroundColor: colorMode === "light" ? "blue.50" : "gray.600",
      };

    case "plain":
      return {
        border: "1px solid",
        borderColor:
          colorMode === "light" ? "blackAlpha.100" : "whiteAlpha.100",
        borderTop: "none",
      };
  }
};

const UploadedFilesWrapper = ({
  children,
  variant = "plain",
}: UploadedFilesWrapperProps) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      w="100%"
      borderBottomLeftRadius={5}
      borderBottomRightRadius={5}
      px={4}
      pt={4}
      pb={2}
      {...getVariantProps(variant, colorMode)}
    >
      {children}
    </Box>
  );
};

export { UploadedFilesWrapper };
