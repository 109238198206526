import {
  CampaignKeywordsAndTopicsDTO,
  CreateCampaignRO,
  UpdateCampaignAccountsDTO,
} from "@intentsify/dto";
import {
  Campaign,
  CampaignAdvancedSettings,
  CampaignBuyingGroup,
  CampaignDefinition,
  CampaignSettings,
} from "@intentsify/types";
import { Endpoints, apiService } from "api";
import { WithCampaignId } from "types";

export type CreateCampaignParams = {
  settings: CampaignSettings;
  onSuccessCallback?: (campaignId: number) => void;
};

export const isCreateCampaignParams = (
  params: CreateCampaignParams | MutateCampaignSettingsParams
): params is CreateCampaignParams => {
  return typeof (params.settings as any).id === "undefined";
};

export const createCampaign = async ({
  settings,
}: CreateCampaignParams): Promise<CreateCampaignRO> => {
  const result = await apiService.post<CreateCampaignRO>(
    Endpoints.Campaigns.Post.Create,
    settings
  );
  return result.data;
};

export type MutateCampaignSettingsParams = {
  settings: WithCampaignId<CampaignSettings>;
  shouldExitOnSuccess: boolean;
  onSuccessCallback?: () => void;
};

export const isMutateCampaignSettingsParams = (
  params: CreateCampaignParams | MutateCampaignSettingsParams
): params is MutateCampaignSettingsParams => {
  return typeof (params.settings as any).id === "number";
};

export const mutateCampaignSettings = async ({
  settings,
}: MutateCampaignSettingsParams): Promise<Campaign> => {
  const { data } = await apiService.put(
    Endpoints.Campaigns.Put.Settings(settings.id),
    settings
  );

  return data;
};

export type MutateCampaignDomainsParams = {
  haveFilesChanged: boolean;
  dto: UpdateCampaignAccountsDTO;
  shouldExitOnSuccess: boolean;
  onSuccessCallback?: () => void;
};

export type MutateCampaignBuyingGroupParams = {
  newSettings: WithCampaignId<CampaignBuyingGroup>;
  shouldExitOnSuccess: boolean;
  onSuccessCallback?: () => void;
};

export const mutateCampaignDomains =
  (campaignId: number) =>
  async ({ dto }: MutateCampaignDomainsParams) => {
    const { data } = await apiService.put<CampaignDefinition>(
      Endpoints.Campaigns.Put.SaveAccounts(campaignId),
      dto
    );

    return data;
  };

export type MutateCampaignKeywordsAndTopicsParams = {
  newSettings: WithCampaignId<CampaignKeywordsAndTopicsDTO>;
  shouldExitOnSuccess: boolean;
  onSuccessCallback?: () => void;
};

export const mutateCampaignKeywordsAndTopics = async ({
  newSettings,
}: MutateCampaignKeywordsAndTopicsParams): Promise<Campaign> => {
  const { id, topics, keywords, includeBombora } = newSettings;

  const { data } = await apiService.put(
    Endpoints.Campaigns.Put.KeywordsAndTopics(id),
    { topics, keywords, includeBombora }
  );

  return data;
};

export type MutateCampaignAdvancedSettingsParams = {
  newSettings: WithCampaignId<
    Omit<CampaignAdvancedSettings, "convertrCampaigns">
  >;
  shouldExitOnSuccess: boolean;
  onSuccessCallback?: () => void;
};

export const mutateCampaignAdvancedSettings = async ({
  newSettings,
}: MutateCampaignAdvancedSettingsParams): Promise<Campaign> => {
  const { data } = await apiService.put(
    Endpoints.Campaigns.Put.AdvancedSettings(newSettings.id),
    newSettings
  );

  return data;
};

export const mutateCampaignBuyingGroup = async ({
  newSettings,
}: MutateCampaignBuyingGroupParams): Promise<Campaign> => {
  const { data } = await apiService.put(
    Endpoints.Campaigns.Put.BuyingGroup(newSettings.id),
    newSettings
  );

  return data;
};
