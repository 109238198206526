import { isPopulatedArray } from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";
import { Table } from "components";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { useComponentColors } from "theme";
import { trendingKeywordsStateSelector } from "../../TrendingKeywords.state";
import { TrendingKeywordsWithTimeseriesResponse } from "../../TrendingKeywords.types";
import { getSignalChangeColumns } from "./TrendingKeywordsTable.columns";

type KeywordsTableProps = {
  data: TrendingKeywordsWithTimeseriesResponse | undefined;
  isLoading: boolean;
};

const TrendingKeywordsTable = ({ data, isLoading }: KeywordsTableProps) => {
  const { selectedKeywords } = useRecoilValue(trendingKeywordsStateSelector);
  const {
    form: { stackBg },
  } = useComponentColors();

  const columns = useMemo<ColumnDef<any>[]>(
    () => (data ? getSignalChangeColumns(data?.timeseries) : []),
    [data]
  );

  const tableData = data ? data.data : [];

  return (
    <Table
      variant="intentsifyNew"
      noDataMessage={
        !isPopulatedArray(selectedKeywords.data) ? "No data found." : undefined
      }
      stretch={true}
      bg={stackBg}
      columns={columns}
      isFetching={isLoading}
      data={tableData}
      onFetchDataChange={() => undefined}
    />
  );
};

export { TrendingKeywordsTable };
