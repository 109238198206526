import { FirmographicsFilters, SortDirection } from "@intentsify/types";
import { PaginatedFirmographicsParams } from "./Firmographics.types";

const FIRMOGRAPHICS_PAGE_SIZE = 1000;

export const defaultFirmoFilters: FirmographicsFilters = {
  countries: [],
  employeeRanges: [],
  revenueRanges: [],
  usStates: [],
  naics2Codes: [],
  naics3Codes: [],
  naics4Codes: [],
  naics5Codes: [],
  naics6Codes: [],
  topics: [],
  keywords: [],
  installBaseProducts: [],
  includeOffice: false,
};

export const defaultFirmoParams: PaginatedFirmographicsParams = {
  search: undefined,
  page: 1,
  per_page: FIRMOGRAPHICS_PAGE_SIZE,
  order_by: "domain",
  order: SortDirection.DESC,
};
