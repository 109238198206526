import { Box, Flex, Image, Stack, Toast } from "@chakra-ui/react";
import {
  HasAccess,
  useHasAccessTo,
} from "@intentsify/authorization/dist/react";
import {
  BuyerResearchStage,
  BuyingGroupContactsAccounts,
  CampaignDetails,
  CampaignMeta,
  SortDirection,
} from "@intentsify/types";
import { isPreviousWeek } from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";
import { Endpoints } from "api";
import { Badge, Button, InfiniteScrollTable, SomethingWrong } from "components";
import { PaginationType } from "components/Table/Table/Table.types";
import { useMemo, useState } from "react";
import { RiDownloadLine } from "react-icons/ri";
import { useCampaignBuyingGroup } from "shared/components/campaign/Campaign.state";
import { useTrackCampaignFileDownloaded } from "tracking/useTrackCampaignFileDownloaded";
import { FetchDataParams } from "types";
import {
  combineParamsAndFilters,
  paramsToQueryUrl,
  useDeepEffect,
  useDownloadFile,
} from "utils";
import { listPaginatedCampaignDetails } from "../CampaignDetails.requests";
import { useCampaignSignalsAndFilters } from "../CampaignDetailsTable/CampaignDetailsTable.hooks";
import {
  CampaignDetailsFilters,
  PaginatedCampaignDetailsParams,
  TopicStage,
} from "../CampaignDetailsTable/CampaignDetailsTable.types";
import { Filters } from "../CampaignDetailsTable/components/Filters";
import { AccountsTableExpandedRow } from "./AccountsTableExpandedRow";

type AccountsTableProps = {
  campaignId: number;
  weekNumber: number;
  yearNumber: number;
  campaignMeta?: CampaignMeta;
};

export function AccountsTable(props: AccountsTableProps) {
  /**
   * Based on the buying group contacts accounts type,
   * we will show only accounts with leads or all accounts.
   */
  const isCompanyPackageBgWithLeads =
    props.campaignMeta?.buyingGroupContactsAccountsType ===
    BuyingGroupContactsAccounts.WITH_LEADS;

  const defaultFilters: CampaignDetailsFilters = useMemo(() => {
    return {
      keywords: [],
      topics: [],
      domains: [],
      buyerResearchStage: [],
      topicStage: TopicStage.Unspecified,
      locations: [],
      customSources: [],
      activeSources: { from: 0, to: 10 },
      signalStrength: { from: 0, to: 10 },
      hasWebsiteActivity: false,
      hasLeads: isCompanyPackageBgWithLeads,
      hasClicks: false,
      hasImpressions: false,
      hasSpotlight: false,
      includeContactsCount: true,
      weekNumber: props.weekNumber,
      yearNumber: props.yearNumber,
    };
  }, [isCompanyPackageBgWithLeads, props.weekNumber, props.yearNumber]);

  const trackCampaignFileDownloaded = useTrackCampaignFileDownloaded();
  const hasAccessToBuyingGroup = useHasAccessTo("buyingGroup");
  const { data: buyingGroupSettings } = useCampaignBuyingGroup(
    props.campaignId,
    hasAccessToBuyingGroup
  );
  const isBuyingGroupDownloadEnabled =
    props.campaignMeta?.hasBuyingGroup &&
    buyingGroupSettings &&
    buyingGroupSettings.buyingGroupContactsAccounts !==
      BuyingGroupContactsAccounts.NONE;

  const [campaignDetailsFilters, setCampaignDetailsFilters] =
    useState<CampaignDetailsFilters>(defaultFilters);

  const didUserUseFilters =
    JSON.stringify(defaultFilters) !== JSON.stringify(campaignDetailsFilters);

  const [campaignDetailsParams, setCampaignDetailsParams] =
    useState<PaginatedCampaignDetailsParams>(defaultParams);

  const [persistedCampaignDetailsFilters, setPersistedCampaignDetailsFilters] =
    useState<CampaignDetailsFilters>(campaignDetailsFilters);

  const { error, filters } = useCampaignSignalsAndFilters(
    String(props.campaignId),
    props.weekNumber,
    props.yearNumber
  );

  useDeepEffect(() => {
    if (
      campaignDetailsFilters.weekNumber !== props.weekNumber ||
      campaignDetailsFilters.yearNumber !== props.yearNumber
    ) {
      setCampaignDetailsFilters({
        ...campaignDetailsFilters,
        weekNumber: props.weekNumber,
        yearNumber: props.yearNumber,
      });
      setCampaignDetailsParams({
        ...campaignDetailsParams,
        page: 1,
      });
      setPersistedCampaignDetailsFilters({
        ...campaignDetailsFilters,
        weekNumber: props.weekNumber,
        yearNumber: props.yearNumber,
      });

      filters.remove();
    }
  }, [
    campaignDetailsFilters.weekNumber,
    campaignDetailsFilters.yearNumber,
    props.weekNumber,
    props.yearNumber,
  ]);

  const [resultsCount, setResultsCount] = useState<number | undefined>(
    undefined
  );
  const [isLoadingResults, setIsLoadingResults] = useState(true);

  const fetchData = (params?: FetchDataParams<keyof CampaignDetails>) => {
    return listPaginatedCampaignDetails(props.campaignId)(
      combineParamsAndFilters(
        {
          ...campaignDetailsParams,
          order: params?.order || defaultParams.order,
          order_by: params?.order_by || defaultParams.order_by,
          page: params?.page || defaultParams.page,
          per_page: params?.per_page || defaultParams.per_page,
        },
        campaignDetailsFilters
      )
    );
  };

  const { download } = useDownloadFile();
  const handleDownloadBuyingGroup = async () => {
    const contactsType = String(
      buyingGroupSettings?.buyingGroupContactsAccounts ??
        BuyingGroupContactsAccounts.NONE
    );
    await download({
      url: `${Endpoints.CampaignDetails.Get.DownloadBuyingGroup(
        props.campaignId
      )}?${paramsToQueryUrl({
        params: combineParamsAndFilters(
          campaignDetailsParams,
          campaignDetailsFilters
        ),
        includeFirstAmpersand: false,
      })}&contactAccountsType=${contactsType}`,
      fileName: `${props.campaignId}_buying_group`,
      fileExtension: "zip",
    });
    trackCampaignFileDownloaded({
      type: "campaign details with buying group",
      campaignIds: [props.campaignId],
    });
  };

  if (error) {
    Toast({
      title: "Cannot get campaign details",
      status: "error",
    });

    return <SomethingWrong />;
  }

  return (
    <Stack gap={2}>
      <Filters
        resultsCount={resultsCount}
        isLoadingFilters={filters.isLoading}
        isLoadingResults={isLoadingResults}
        filtersData={filters.data}
        setCampaignDetailsFilters={(val) => {
          setCampaignDetailsFilters({
            ...campaignDetailsFilters,
            ...val,
          });
        }}
        onApplyFilters={() => {
          setCampaignDetailsParams({
            ...campaignDetailsParams,
            page: 1,
          });
          setPersistedCampaignDetailsFilters(campaignDetailsFilters);
        }}
        isPreviousWeek={isPreviousWeek(props.weekNumber, props.yearNumber)}
        hasLeadsDisabled={isCompanyPackageBgWithLeads}
        hasLeadsDefaultValue={isCompanyPackageBgWithLeads}
        selectedFilters={campaignDetailsFilters}
      />

      <HasAccess to="campaign.downloadDetails">
        <Box>
          {isBuyingGroupDownloadEnabled && (
            <Button
              variant="primary-teal"
              rightIcon={<RiDownloadLine />}
              size="sm"
              onClick={() => {
                handleDownloadBuyingGroup();
              }}
              isDisabled={isLoadingResults}
            >
              Request to Download Buying Group CSV
            </Button>
          )}
        </Box>
      </HasAccess>

      <InfiniteScrollTable<CampaignDetails, PaginationType.LIMIT_OFFSET>
        columns={columns}
        fetchData={fetchData}
        height={900}
        name={`accountsWithPersonaDetails${props.campaignId}`}
        estimatedRowHeight={100}
        renderExpandableRowComponent={
          buyingGroupSettings?.buyingGroupContactsAccounts !==
          BuyingGroupContactsAccounts.NONE
            ? (row) => (
                <AccountsTableExpandedRow
                  accountId={row.original.domainId}
                  campaignId={props.campaignId}
                />
              )
            : undefined
        }
        defaultSort={[{ id: "buyerResearchStage", desc: false }]}
        paginationType={PaginationType.LIMIT_OFFSET}
        filters={persistedCampaignDetailsFilters}
        disableVirtualization={true}
        itemsPerPage={defaultParams.per_page}
        noDataMessage={
          !didUserUseFilters
            ? "Data is processing."
            : "No results. Please adjust filters."
        }
        onLoadingStateChange={(isLoading, data) => {
          setIsLoadingResults(isLoading);
          setResultsCount(data?.pages[0]?.meta?.total_items);
        }}
      />
    </Stack>
  );
}

const defaultParams = {
  page: 1,
  per_page: 25,
  order_by: "buyerResearchStage",
  order: SortDirection.ASC,
} satisfies FetchDataParams<keyof CampaignDetails>;

const columns = [
  {
    header: "",
    accessorKey: "logoUrl",
    enableSorting: false,
    cell: ({ row }) => (
      <Flex display="flex">
        {row.original?.logoUrl && (
          <Image
            maxHeight={"50px"}
            maxWidth={"50px"}
            src={row.original?.logoUrl}
            mr={2}
          />
        )}
      </Flex>
    ),
  },
  {
    header: "Account",
    accessorKey: "domainName",
  },
  {
    header: "Buyer Research Stage",
    accessorKey: "buyerResearchStage",
    cell: ({ row }) => {
      return (
        <Badge fontWeight="bold">
          {row.original?.buyerResearchStage ?? BuyerResearchStage.Unspecified}
        </Badge>
      );
    },
  },
  {
    header: "Contacts",
    accessorKey: "contacts",
  },
] satisfies ColumnDef<CampaignDetails>[];
