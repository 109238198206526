import { useToast } from "@chakra-ui/react";
import {
  AutodiscoveryProcessingProgress,
  AutodiscoveryProcessStatus,
  Campaign,
  CampaignAutodiscoveryStatus,
  Dynamic1Status,
} from "@intentsify/types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router";
import { handleApiError } from "utils";
import {
  disableDynamic1,
  getAutodiscoveryStatus,
  processCampaignAutodiscovery,
} from "./Autodiscovery.requests";

const useAutodiscovery = (campaignId: string) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isProcessing, setIsProcessing] = useState(false);
  const [autodiscoveryProgress, setAutodiscoveryProgress] =
    useState<AutodiscoveryProcessingProgress>({
      status: AutodiscoveryProcessStatus.UNSPECIFIED,
      progress: 0,
    });
  const { mutate: processAutodiscovery, isLoading: isProcessingStarting } =
    useMutation(processCampaignAutodiscovery, {
      onSuccess: (data) => {
        setIsProcessing(true);
        setAutodiscoveryProgress({
          progress: 0,
          status: AutodiscoveryProcessStatus.UNSPECIFIED,
        });
        const previousCampaignState = queryClient.getQueryData<Campaign>([
          "campaign",
          Number(campaignId),
        ]);

        if (previousCampaignState) {
          queryClient.setQueryData(["campaign", Number(campaignId)], {
            ...previousCampaignState,
            autodiscoveryDescription: data.description,
            autodiscoveryUrls: data.urls,
            autodiscoveryStatus: CampaignAutodiscoveryStatus.PROCESSING,
            isAutodiscoveryEnabled: true,
            dynamicOneStatus: data.dynamic1Status,
          });
        }
      },
      onError: () => {
        toast({
          title:
            "This request is taking longer than expected. Please refresh the page in a few minutes",
          status: "info",
        });
        setIsProcessing(false);
      },
    });

  useQuery(
    ["autodiscovery", campaignId],
    () => getAutodiscoveryStatus(campaignId),
    {
      enabled: isProcessing,
      refetchInterval: 5000,
      useErrorBoundary: false,
      onError: () => {
        setIsProcessing(false);
        toast({
          title:
            "This request is taking longer than expected. Please refresh the page in a few minutes",
          status: "info",
        });
      },
      onSuccess: (data) => {
        if (data.status === AutodiscoveryProcessStatus.SUCCESS) {
          setIsProcessing(false);

          const previousCampaignState = queryClient.getQueryData<Campaign>([
            "campaign",
            Number(campaignId),
          ]);

          if (previousCampaignState) {
            queryClient.setQueryData(["campaign", Number(campaignId)], {
              ...previousCampaignState,
              autodiscoveryKeywords: data.results.keywords,
              autodiscoveryTopics: data.results.topics,
              autodiscoveryProducts: data.results.products,
              autodiscoveryUrls: data.results.urls,
              autodiscoveryStatus: data.results.status,
              autodiscoveryFiles: data.results.files,
            });
          }
        }
        if (data.status === AutodiscoveryProcessStatus.PROCESS) {
          if (data.progress) {
            setAutodiscoveryProgress({
              progress: data.progress,
              status: AutodiscoveryProcessStatus.PROCESS,
            });
          }
        }
      },
    }
  );

  const {
    mutate: disableCampaignDynamic1,
    isLoading: isDynamic1DisablingLoading,
  } = useMutation(disableDynamic1, {
    onError: (err) => {
      handleApiError(err, navigate, toast);
    },
    onSuccess: () => {
      toast({
        title: "Disabling Dynamic1 was successful",
        status: "success",
      });
      const previousCampaignState = queryClient.getQueryData<Campaign>([
        "campaign",
        Number(campaignId),
      ]);

      if (previousCampaignState) {
        queryClient.setQueryData(["campaign", Number(campaignId)], {
          ...previousCampaignState,
          dynamicOneStatus: Dynamic1Status.Disabled,
        });
      }
    },
  });

  return {
    processAutodiscovery,
    isProcessingStarting,
    isProcessing,
    autodiscoveryProgress,
    disableCampaignDynamic1,
    isDynamic1DisablingLoading,
  };
};

export { useAutodiscovery };
